import React from "react";
import UserDetails from "../components/UserDetails";
import {useAuth} from "../security/AuthProvider";

export default function HomePage() {
    const {userProfile} = useAuth();

    return (
        <div className="container flex flex-row flex-wrap gap-3">
            <UserDetails profile={userProfile}/>
        </div>
    );
}