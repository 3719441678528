import React from "react";

export default function Maintenance() {
    return (
        <div className="maintenance">
            <h1>Сервер недоступен</h1>
            <p>Проводится техническое обслуживание.<br/>
                Обратитесь в службу технической поддержки.<br/>
                Извините за доставленные неудобства.
            </p>
        </div>
    );
}