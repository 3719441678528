import {notificationListService} from "../../../index";
import {errorMessage} from "../../../helpers/axiosError";
import NotificationList from "../../../interfaces/notification/NotificationList";
import React from "react";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";

interface NotificationListOutputProps {
    listId: number | undefined | null;
}

export type Props = NotificationListOutputProps;


export default function NotificationListOutput(props: NotificationListOutputProps) {

    const [notificationList, setNotificationList] =
        React.useState<NotificationList | undefined>(undefined);


    const reload = () => {
        if (props.listId) {
            notificationListService.find(props.listId)
                .then(response => {
                    setNotificationList(response);
                })
                .catch(reason => {
                    console.error(errorMessage(reason));
                });
        }
    };

    useMountEffect(() => {
        reload();
    });
    useUpdateEffect(() => reload(), [props.listId]);

    if (notificationList) {
        return <>{notificationList.name}</>;
    }
    return <></>;
}