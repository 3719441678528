import {Button, ButtonProps} from "primereact/button";
import React from "react";

interface Props extends ButtonProps {
}

export default function CloseButton(props: Props) {
    return <Button label="Закрыть"
                   icon="pi pi-times"
                   type="button"
                   className="p-button-text"
                   {...props}
    />;
}