import {Message} from "primereact/message";
import React from "react";
import {useAppSettings} from "../providers/AppSettingsProvider";

export default function UserTicketConditions() {
    const {appSettings} = useAppSettings();

    if (appSettings.ticketServiceDescription && appSettings.ticketServiceDescription.length > 0)
        return <Message security="info"
                        text={appSettings.ticketServiceDescription}
        />
    else
        return null;
}