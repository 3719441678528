import {DataTablePageEvent, DataTableSortEvent} from "primereact/datatable";

export const serviceProgramPaginationInitialState: DataTablePageEvent = {
    first: 0,
    rows: 10,
    page: 0,
};

export const serviceProgramSortingInitialState: DataTableSortEvent = {
    sortField: 'id',
    sortOrder: 1,
    multiSortMeta: []
};

export const serviceProgramRowsPerPage: number[] = [10, 20, 50, 100];