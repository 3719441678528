import {FilterMatchMode, FilterOperator} from "primereact/api";

/*export default function pfsToSpring(filters: DataTableFilterMeta,
                                    sorting: DataTableSortEvent,
                                    pagination: DataTablePageEvent): undefined | string {
    let items: (string | undefined)[] = [
        pfsFilterToSpring(filters),
        pfsSortingToSpring(sorting),
        pfsPaginationToSpring(pagination)
    ];
    const result = items.filter(item => item).join('&');
    //console.log('PFStoSpring filters: ' + JSON.stringify(filters));
    //console.log('PFStoSpring: ' + result);
    return result;
}*/

export function pfsFilterToSpring(filters: any): undefined | string {
    if (!filters) return undefined;
    //console.log('PFS processing: ' + JSON.stringify(filters));

    let allConstraints = [];

    for (let field in filters) {
        type fieldKey = keyof typeof filters;
        const filter = filters[field as fieldKey]
        const constraints = filter.constraints;
        const atoms = [];

        if (field === "global") {
            //console.log(constraints)
            continue;
        }

        for (const constraint of constraints) {
            let value = constraint.value;
            if (value === null || value === undefined || isEmptyObject(value)) continue;

            let comparator = undefined;

            /*console.log(
                `field: ${field}`,
                `type: "${typeof value}"`,
                `value: "${value}"`,
                `matchMode: "${constraint.matchMode}"`
            );*/

            switch (constraint.matchMode) {
                case FilterMatchMode.EQUALS:
                case FilterMatchMode.DATE_IS:
                    comparator = ':';
                    if (value instanceof Date) value = `'${localDateTime(value)}'`;
                    else value = `'${value}'`;
                    break;
                case FilterMatchMode.NOT_EQUALS:
                    comparator = '!';
                    value = `'${value}'`;
                    break;
                case FilterMatchMode.DATE_IS_NOT:
                    //if (value instanceof Date) value = `'${localDateTime(value)}'`;
                    comparator = '!';
                    value = `'${localDateTime(value)}'`;
                    break;
                case FilterMatchMode.GREATER_THAN:
                    comparator = '>';
                    value = `'${value}'`;
                    break
                case FilterMatchMode.DATE_AFTER:
                    //if (value instanceof Date) value = `'${localDateTime(value)}'`;
                    value = `'${localDateTime(value)}'`;
                    comparator = '>:';
                    break;
                case FilterMatchMode.LESS_THAN:
                    comparator = '<';
                    value = `'${value}'`;
                    break;
                case FilterMatchMode.DATE_BEFORE:
                    //if (value instanceof Date) value = `'${localDateTime(value)}'`;
                    value = `'${localDateTime(value)}'`;
                    comparator = '<';
                    break;
                case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
                    if (value instanceof Date) value = `'${localDateTime(value)}'`;
                    //else if (!(typeof value == 'number')) continue;
                    comparator = '>:';
                    break;
                case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
                    if (value instanceof Date) value = `'${localDateTime(value)}'`;
                    //else if (!(typeof value == 'number')) continue;
                    comparator = '<:';
                    break;
                case FilterMatchMode.IN:
                    value = "[" + value.map((v:any) => "'" + v + "'").join(",") + "]";
                    comparator = ' in ';
                    break;
                case FilterMatchMode.STARTS_WITH:
                    if (!(typeof value == 'string')) continue;
                    comparator = '~~';
                    value = `'${value}*'`     // value.toLowerCase()
                    break;
                case FilterMatchMode.ENDS_WITH:
                    if (!(typeof value == 'string')) continue;
                    comparator = '~~';
                    value = `'*${value}'`     // value.toLowerCase()
                    break;
                case FilterMatchMode.CONTAINS:
                case FilterMatchMode.NOT_CONTAINS:  // Дополнительная обработка ниже
                    if (!(typeof value == 'string')) continue;
                    comparator = '~~';
                    value = `'*${value}*'`    // value.toLowerCase()
                    break;
            }
            if (field && comparator) {

                let i = `${field}${comparator}${value}`;
                if (constraint.matchMode === FilterMatchMode.NOT_CONTAINS) {
                    i = `not(${i})`;
                }
                atoms.push(i);
            }
        }

        let oneFieldConstraint = undefined;

        switch (filter.operator) {
            case FilterOperator.OR:
                oneFieldConstraint = atoms.join(' or ')
                break;
            case FilterOperator.AND:
                oneFieldConstraint = atoms.join(' and ')
                break;
        }

        if (atoms.length >= 2) {
            oneFieldConstraint = `(${oneFieldConstraint})`;   // Группировка условий одного поля
        }
        if (oneFieldConstraint) {
            allConstraints.push(oneFieldConstraint);
        }
    }

    let result = allConstraints.join(' and ');
    return result.length === 0 ? undefined : `filter=${result}`;
}

export function pfsPaginationToSpring(pagination: any): undefined | string {
    let result: undefined | string = undefined;

    if (pagination.page >= 0 && pagination.rows >= 0)
        result = `page=${pagination.page}&size=${pagination.rows}`;

    return result;
}

export function pfsSortingToSpring(sorting: any): undefined | string {
    let result = '';

    if (sorting.sortField) {
        result += `sort=${sorting.sortField}`;
        if (sorting.sortOrder === -1) result += `,desc`;
        else if (sorting.sortOrder === 1) result += `,asc`;
    }

    return result.length === 0 ? undefined : result;
}

/**
 * Возвращает дату в формате: dd-MM-yyyy'T'HH:mm:ss
 * @param date дата подлежащая форматированию
 */
export function localDateTime(date: Date) {
    let year = date.getFullYear();
    let month = paddingZeros(date.getMonth() + 1);
    let day = paddingZeros(date.getDate());
    let hours = paddingZeros(date.getHours());
    let minutes = paddingZeros(date.getMinutes());
    let seconds = paddingZeros(date.getSeconds());
    return `${day}-${month}-${year}T${hours}:${minutes}:${seconds}`;
}

/**
 * Дополняет числовые значения нулями до двух знуков
 * @param value число подлежащее форматированию
 */
export function paddingZeros(value: number) {
    let s = value.toString();
    if (s.length === 1) s = '0' + s;
    return s;
}

export function isEmptyObject(val: any): boolean {
    return JSON.stringify(val) === "[]";
    /*return val instanceof Object &&
        (Object.keys(val).length === 0 || JSON.stringify(val) === "[]") /*&& obj.constructor === Object;*/
}