import React from "react";
import {DateTime} from "luxon";

export default function Footer() {

    const year: number = DateTime.now().year; /*new Date().getUTCFullYear()*/
    const copyright: string = "Независимая Страховая Группа";

    return (
        <footer className="App-footer card text-500 p-5">
            <div className="flex flex-row flex-wrap align-items-center gap-3">
                <div className="flex-grow-1">
                    <div>&copy; {year} {copyright}</div>
                    {/*<div><small>Версия приложения: {APP_VERSION}</small></div>*/}
                    <div>
                        Помощь и поддержка <a
                        href="mailto:support@nsg-ins.ru?subject=Помощь и поддержка ЛК">support@nsg-ins.ru</a>

                    </div>
                </div>
                <div>
                    <img src="/logo.png" alt={copyright}/>
                </div>
            </div>
        </footer>
    );
}