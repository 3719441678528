import React, {useState} from "react";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import {Dialog} from "primereact/dialog";
import {BlockUI} from "primereact/blockui";
import {Mode} from "node:fs";
import {EntityDialogState} from "../../../../components/common/dialog/EntityDialogState";
import useUserNotification from "../../../../hooks/useUserNotification";
import SaveButton from "../../../../components/common/buttons/SaveButton";
import CancelButton from "../../../../components/common/buttons/CancelButton";
import {InputText} from "primereact/inputtext";
import NotificationRecipientStatusDropdown
    from "../../../../components/notification/recipient/NotificationRecipientStatusDropdown";
import NotificationRecipientCommunicationTypeDropdown
    from "../../../../components/notification/recipient/NotificationRecipientCommunicationTypeDropdown";
import NotificationRecipientSpecial from "../../../../interfaces/notification/NotificationRecipientSpecial";
import {notificationRecipientSpecialService} from "../../../../index";
import {errorMessage} from "../../../../helpers/axiosError";

export interface NotificationRecipientDialogState extends EntityDialogState<NotificationRecipientSpecial> {
}

export interface NotificationRecipientDialogProps {
    recipient: NotificationRecipientSpecial;
    mode: Mode;
    visible: boolean;

    onHide(): void;

    onCancel?(): void;

    onSave?(): void
}

export default function NotificationRecipientPropertiesDialog(props: NotificationRecipientDialogProps) {

    const title = props.mode === "create" ?
        "Регистрация нового адресата" :
        "Редактирование карточки адресата";

    const [busy, setBusy] = useState<boolean>(false);

    const [recipient, setRecipient] =
        useState<NotificationRecipientSpecial>(props.recipient);

    const {showError, showSuccess} = useUserNotification();


    const reload = () => {
        setRecipient(props.recipient);
    }

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [props.recipient]);


    const save = () => {
        if (props.mode === "create") {
            setBusy(true);
            notificationRecipientSpecialService.create(recipient)
                .then(() => {
                    showSuccess({
                        summary: "Регистрация адресата",
                        detail: `Адресат "${recipient.address}" успешно зарегистрирован в системе.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Регистрация адресата",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));

        } else if (props.mode === "edit" && recipient.id) {
            setBusy(true);
            notificationRecipientSpecialService.update(recipient.id, recipient)
                .then(() => {
                    showSuccess({
                        summary: "Обновление адресата",
                        detail: `Регистрационные данные адресата "${recipient.address}" успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Обновление адресата",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    };


    const footer = (
        <div>
            <SaveButton onClick={() => save()} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );

    return (
        <BlockUI blocked={busy}>
            <Dialog header={title}
                    visible={props.visible}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-dialog">

                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="communicationType" className="font-bold">Коммуникационный канал</label>
                        <NotificationRecipientCommunicationTypeDropdown
                            id="communicationType"
                            value={recipient.communicationType}
                            onChange={s =>
                                setRecipient(prev => ({
                                    ...prev,
                                    communicationType: s
                                }))}/>
                    </div>
                    {'address' in recipient &&
                        <div className="flex flex-column gap-2 m-0">
                            <label htmlFor="address" className="font-bold">Адрес</label>
                            <InputText id="address"
                                       value={recipient.address as string || ""}
                                       autoFocus
                                       onChange={(e) =>
                                           setRecipient(prev => ({
                                               ...prev,
                                               address: e.target.value
                                           }))}/>
                        </div>
                    }
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="status" className="font-bold">Статус</label>
                        <NotificationRecipientStatusDropdown id="status"
                                                             value={recipient.status}
                                                             onChange={s =>
                                                                 setRecipient(prev => ({
                                                                     ...prev,
                                                                     status: s
                                                                 }))}/>
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    );
};