/**
 * Сокращает текст, преобразует тэги <br>, <li> в перевод строки и удаляет оставшиеся тэги.
 * @param text исходная строка.
 * @param length количество возвращаемых символов, либо 300 по умолчанию
 */
export function htmlToTextTemplate(text: string | undefined | null, length?: number | undefined): string {
    return text ? text
        .slice(0, length ? length : 300)
        .replace(/(<br>|<br\/>|<li>|<\/li>)/gi, '\r\n')
        .replace(/(<([^>]+)>)/gi, "") + "..."
        : "";
}

export function formatBytes(bytes: number | null | undefined, decimals: number = 2): string {
    if (!bytes) return "";
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    //const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    const sizes = ['Байт', 'Килобайт', 'Мегабайт', 'Гигабайт', 'Терабайт', 'Петабайт', 'Эксабайт', 'Зеттабайт', 'Йоттабайт']
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}