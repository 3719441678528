import {EntityDialogState, Mode} from "../../components/common/dialog/EntityDialogState";
import React, {useState} from "react";
import useUserNotification from "../../hooks/useUserNotification";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import CancelButton from "../../components/common/buttons/CancelButton";
import SaveButton from "../../components/common/buttons/SaveButton";
import {Dialog} from "primereact/dialog";
import InsuranceProgram from "../../interfaces/InsuranceProgram.interface";
import {InputText} from "primereact/inputtext";
import {insuranceProgramService} from "../../index";
import {errorMessage} from "../../helpers/axiosError";
import {BlockUI} from "primereact/blockui";
import TaxonomySelect from "../../components/common/taxonomy/TaxonomySelect";

export interface InsuranceProgramDialogState extends EntityDialogState<InsuranceProgram> {
}

export interface InsuranceProgramDialogProps {
    insuranceProgram: InsuranceProgram;
    mode: Mode;
    visible: boolean;

    onHide(): void;

    onCancel?(): void;

    onSave?(): void
}

export default function InsuranceProgramPropertiesDialog(props: InsuranceProgramDialogProps) {

    const title = props.mode === "create" ?
        "Регистрация новой программы страхования" :
        "Редактирование карточки программы страхования";

    const [busy, setBusy] = useState<boolean>(false);

    const [editedInsuranceProgram, setEditedInsuranceProgram] =
        useState<InsuranceProgram>(props.insuranceProgram);

    /*const [assets, setAssets] =
        useState<MediaAsset[] | undefined>(undefined);*/

    const {showError, showSuccess} = useUserNotification();

    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload();
    }, [props.insuranceProgram]);

    const reload = () => {
        setEditedInsuranceProgram(props.insuranceProgram);
        /*if (props.insuranceProgram.id) {
            setBusy(true);
            insuranceProgramService
                .findAssets(props.insuranceProgram.id)
                .then(assets => {
                    setAssets(assets);
                    setBusy(false);
                })
                .catch(reason => showError({
                    summary: "Загрузка документов",
                    detail: "Ошибка загрузки приложенных документов: " + errorMessage(reason)
                }));
        }*/
    };

    const save = () => {
        if (props.mode === "create") {
            setBusy(true);
            insuranceProgramService.create(editedInsuranceProgram)
                .then(() => {
                    showSuccess({
                        summary: "Регистрация программы страхования",
                        detail: `Программа страхования "${editedInsuranceProgram.name}" успешно зарегистрирована в системе.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Регистрация программы страхования",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));

        } else if (props.mode === "edit" && editedInsuranceProgram.id) {
            setBusy(true);
            insuranceProgramService.update(editedInsuranceProgram.id, editedInsuranceProgram)
                .then(() => {
                    showSuccess({
                        summary: "Обновление программы страхования",
                        detail: `Регистрационные данные программы страхования "${editedInsuranceProgram.name}" успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Регистрация программы страхования",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    };


    const footer = (
        <div>
            <SaveButton onClick={() => save()} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );

    return (
        <BlockUI blocked={busy}>
            <Dialog header={title}
                    visible={props.visible}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-dialog">

                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-4">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="name" className="font-bold">Название</label>
                            <InputText id="name" value={editedInsuranceProgram.name}
                                       onChange={(e) => {
                                           setEditedInsuranceProgram(prev => ({
                                               ...prev,
                                               ...{name: e.target.value}
                                           }))
                                       }}/>
                        </div>
                    </div>
                    <div className="flex flex-column gap-4">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="taxonomy" className="font-bold">Таксономия</label>
                            <TaxonomySelect value={editedInsuranceProgram.taxonomy}
                                            onChange={taxonomy =>
                                                setEditedInsuranceProgram(prev => ({
                                                    ...prev,
                                                    taxonomy: taxonomy
                                                }))
                                            }/>
                        </div>
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    );
};