import {EntityDialogState, Mode} from "../dialog/EntityDialogState";
import {MediaAsset} from "../../../interfaces/MediaAsset";
import React, {useState} from "react";
import useUserNotification from "../../../hooks/useUserNotification";
import {useUpdateEffect} from "primereact/hooks";
import CancelButton from "../buttons/CancelButton";
import SaveButton from "../buttons/SaveButton";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {BlockUI} from "primereact/blockui";
import {mediaAssetService} from "../../../index";
import {errorMessage} from "../../../helpers/axiosError";
import MediaAssetTypeDropdown from "./MediaAssetTypeDropdown";

export interface MediaAssetPropertiesDialogState extends EntityDialogState<MediaAsset> {
}

export interface MediaAssetPropertiesDialogProps {
    asset: MediaAsset;
    mode: Mode;
    visible: boolean;

    onHide(): void;

    onCancel?(): void;

    onSave?(): void
}

export default function MediaAssetPropertiesDialog(props: MediaAssetPropertiesDialogProps) {

    const title = props.mode === "create" ?
        "Регистрация нового файла" :
        "Редактирование карточки файла";

    const [busy, setBusy] = useState<boolean>(false);

    const [editedAsset, setEditedAsset] =
        useState<MediaAsset>(props.asset);

    const {showSuccess} = useUserNotification();

    useUpdateEffect(() => {
        reload();
    }, [props.asset]);

    const reload = () => setEditedAsset(props.asset);

    const save = () => {
        setBusy(true);

        if (props.mode === "create") {
            mediaAssetService.create(editedAsset)
                .then(() => {
                    showSuccess({
                        summary: "Регистрация файла",
                        detail: `Файл "${editedAsset.title || editedAsset.name || editedAsset.uuid}" успешно зарегистрирован в системе.`
                    });
                    if (props.onSave) props.onSave();
                    reload();

                })
                .catch(reason => errorMessage(reason))
                .finally(() => {
                    setBusy(false);
                });
        } else if (editedAsset.id) {
            mediaAssetService.update(editedAsset.id, editedAsset)
                .then(() => {
                    showSuccess({
                        summary: "Обновление файла",
                        detail: `Регистрационные данные файла "${editedAsset.title || editedAsset.name || editedAsset.uuid}" успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();
                    reload();

                })
                .catch(reason => errorMessage(reason))
                .finally(() => {
                    setBusy(false);
                });
        }
    };

    const footer = (
        <div>
            <SaveButton onClick={() => save()} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );


    return (
        <BlockUI blocked={busy}>
            <Dialog visible={props.visible}
                    header={title}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-dialog">

                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="title">Название</label>
                        <InputText id="title" value={editedAsset.title || ''}
                                   onChange={(e) =>
                                       setEditedAsset(prevAsset => ({
                                           ...prevAsset,
                                           ...{title: e.target.value}
                                       }))}
                        />
                    </div>

                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="type">Тип файла</label>
                        <MediaAssetTypeDropdown value={editedAsset.type}
                                                onChoose={type =>
                                                    setEditedAsset(prev => ({
                                                        ...prev,
                                                        type: type
                                                    }))}/>
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    );
};