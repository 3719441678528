import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import React from "react";
import {NotificationListStatus} from "../../../interfaces/notification/NotificationListStatus";
import {notificationListStatusTitles} from "../../../interfaces/dictionaries/NotificationListStatusTitles";

interface NotificationListDropdownProps {
    id?: string | undefined;
    value: NotificationListStatus | undefined;

    onChange(status: NotificationListStatus): void;
}

export default function NotificationListStatusDropdown(props: NotificationListDropdownProps) {
    return <Dropdown id={props.id}
                     value={props.value}
                     options={notificationListStatusTitles}
                     optionValue="code"
                     optionLabel="title"
                     onChange={(e: DropdownChangeEvent) => props.onChange(e.value)}
                     emptyMessage="Нет зарегистрированных статусов списков рассылки уведомлений."
                     placeholder="Выберите статус списка рассылки уведомлений"/>;
}