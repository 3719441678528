import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import React from "react";
import {UserStatus} from "../interfaces/enum/UserStatus";
import {userStatusTitles} from "../interfaces/dictionaries/UserStatusTitles";

interface UserStatusDropdownProps {
    id?: string | undefined;
    value: UserStatus | undefined;

    onChange(status: UserStatus): void;
}

export default function UserStatusDropdown(props: UserStatusDropdownProps) {
    return <Dropdown id={props.id}
                     value={props.value}
                     options={userStatusTitles}
                     optionValue="code"
                     optionLabel="title"
                     onChange={(e: DropdownChangeEvent) => props.onChange(e.value)}
                     emptyMessage="Нет зарегистрированных статусов пользователя"
                     placeholder="Выберите статус пользователя"/>;
}