import MediaAssetPanel from "./MediaAssetPanel";
import {Dialog} from "primereact/dialog";
import {BaseDialogState} from "../dialog/BaseDialogState";
import {MediaAsset} from "../../../interfaces/MediaAsset";

export interface MediaAssetChooserDialogState extends BaseDialogState {

}

interface Props {
    dialogState: MediaAssetChooserDialogState;
    notSelectable: MediaAsset[] | undefined;

    onHide(): void;

    onCancel(): void;

    onSave(): void;

    onSelect(asset: MediaAsset | undefined): void;
}

export default function MediaAssetChooserDialog(props: Props) {
    const title = <>Файлы</>;
    const footer = <></>;

    return (
        <Dialog header={title}
                visible={props.dialogState.visible}
                onHide={props.onHide}
                footer={footer}
                className="app-dialog-full">
            <MediaAssetPanel mode="chooser"
                             notSelectable={props.notSelectable}
                             onSelect={props.onSelect}/>
        </Dialog>
    );
}