import {TreeSelect, TreeSelectChangeEvent} from "primereact/treeselect";
import React, {useState} from "react";
import {TreeNode} from "primereact/treenode";
import {useMountEffect} from "primereact/hooks";
import {Paging} from "../../../service/BaseDataService";
import {MAX_INT} from "../../../config";
import {taxonomyService} from "../../../index";
import ApiResponse from "../../../interfaces/response.interface";
import Taxonomy from "../../../interfaces/Taxonomy";
import toTreeNodes from "../../../helpers/toTreeNodes";
import {errorMessage} from "../../../helpers/axiosError";
import useUserNotification from "../../../hooks/useUserNotification";

export interface TaxonomySelectProps {
    id?: string | undefined;
    value: number | null | undefined;

    onChange(id: number | null): void;
}

export default function TaxonomySelect(props: TaxonomySelectProps) {

    const [busy, setBusy] =
        useState<boolean>(false);

    const [treeNodes, setTreeNodes] =
        useState<TreeNode[]>([]);

    const {showError} = useUserNotification();

    const reload = () => {
        setBusy(true);

        const paging: Paging = {
            page: 0,
            rows: MAX_INT
        };

        taxonomyService.findAll(undefined, paging)
            .then((response: ApiResponse<Taxonomy>) => {
                const treeNodes: TreeNode[] = [{
                    key: "<null>", label: "<Пусто>", data: null
                }];
                toTreeNodes(response.content, treeNodes, null)
                setTreeNodes(treeNodes);

            })
            .catch(reason => {
                setTreeNodes([]);
                showError({
                    summary: "Загрузка таксономии",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
            });
    };

    useMountEffect(() => {
        reload();
    });

    return <TreeSelect id={props.id}
                       options={treeNodes}
                       value={props.value?.toString()}
                       filter
                       placeholder="Выберите термин"
                       disabled={busy}
                       onChange={(e: TreeSelectChangeEvent) => {
                           props.onChange(e.value ? parseInt(e.value.toString()) : null)
                       }}/>;
}