import {Button, ButtonProps} from "primereact/button";
import React from "react";

interface Props extends ButtonProps {

}

export default function FilterResetButton(props: Props) {
    return <Button icon="pi pi-fw pi-filter-slash"
                   type="button"
                   tooltip="Сбросить фильтр" tooltipOptions={{position: 'top'}}
                   className="p-button-outlined"
                   {...props}/>
}