import UserProfile from "../interfaces/UserProfile.interface";
import Therapist from "../interfaces/Therapist";

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function randomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function fullName(userProfile: UserProfile): string {
    const requisites = [
        userProfile.secondName,
        userProfile.firstName,
        userProfile.thirdName
    ];
    return fullNameByRequisites(requisites);
}

export function therapistFullName(therapist: Therapist): string {
    const requisites = [
        therapist.profileSecondName,
        therapist.profileFirstName,
        therapist.profileThirdName
    ];
    return fullNameByRequisites(requisites);
}

export function fullNameByRequisites(requisites: (string | undefined)[]): string {
    return requisites
        .filter(requisite => requisite && requisite.length > 0)
        .join(" ");
}