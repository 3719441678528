import TemporalUpdatableEntity from "../TemporalUpdatableEntity";
import {TicketMessageStatus} from "../enum/TicketMessageStatus";

export interface TicketMessage extends TemporalUpdatableEntity {
    text: string;
    profile: number | null;
    sticky: boolean;
    rating: number;
    status: TicketMessageStatus;
    ticket: number | null;
    // MediaAssets
}

export const initialTicketMessage: TicketMessage = {
    id: null, created: undefined, updated: undefined,
    text: '', profile: -1, rating: 0,
    status: TicketMessageStatus.VISIBLE, sticky: false, ticket: -1
};