import {DataTable} from "primereact/datatable";
import {MediaAsset} from "../../interfaces/MediaAsset";
import React, {useState} from "react";
import {Column} from "primereact/column";
import ReloadButton from "../../components/common/buttons/ReloadButton";
import CreateButton from "../../components/common/buttons/CreateButton";
import DeleteButton from "../../components/common/buttons/DeleteButton";
import {confirmDialog} from "primereact/confirmdialog";
import {serviceProgramService} from "../../index";
import {errorMessage} from "../../helpers/axiosError";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import useUserNotification from "../../hooks/useUserNotification";
import MediaAssetChooserDialog, {
    MediaAssetChooserDialogState
} from "../../components/common/mediaAsset/MediaAssetChooserDialog";
import CloseButton from "../../components/common/buttons/CloseButton";
import {assetTypeTitle} from "../../helpers/assets";
import ServiceProgram from "../../interfaces/ServiceProgram";

interface ServiceProgramAssetsProperties {
    serviceProgram: ServiceProgram;

    onBind(asset: MediaAsset): void;

    onUnbind(assets: MediaAsset[]): void;

    onClose(e: React.MouseEvent<HTMLButtonElement>): void;
}

export default function ServiceProgramAssets(props: ServiceProgramAssetsProperties) {

    const [busy, setBusy] =
        useState<boolean>(false);

    const [assets, setAssets] =
        useState<MediaAsset[] | undefined>(undefined);

    const [selected, setSelected] =
        useState<MediaAsset[]>([]);

    const [dialogState, setDialogState] =
        useState<MediaAssetChooserDialogState>({
            visible: false
        });

    const {showError} = useUserNotification();

    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload()
    }, [props.serviceProgram]);

    const reload = () => {
        if (props.serviceProgram.id) {
            setBusy(true);
            serviceProgramService.findAssets(props.serviceProgram.id)
                .then(response => {
                    setAssets(response);
                })
                .catch(reason => showError({
                    summary: "Загрузка файлов",
                    detail: errorMessage(reason)
                }))
                .finally(() => {
                    setBusy(false);
                });
        }
    };

    const hasSelected = () => {
        return selected && selected.length > 0;
    };

    const onCreateButtonClick = () => {
        setDialogState({
            visible: true
        });
    };

    const bind = (asset: MediaAsset) => {
        if (props.serviceProgram.id && asset.id) {
            setBusy(true);
            serviceProgramService.assetBind(
                props.serviceProgram.id,
                asset.id
            ).then(() => {
                reload();
                if (props.onBind) props.onBind(asset);

            }).catch(reason => showError({
                summary: "Прикрепление файла",
                detail: errorMessage(reason)

            })).finally(() => {
                setBusy(false);
                setSelected([]);
            });
        }
    };

    const unbind = (assets: MediaAsset[]) => {
        if (props.serviceProgram.id && selected) {
            setBusy(true);
            serviceProgramService.assetsUnbind(
                props.serviceProgram.id,
                selected
            ).then(() => {
                reload();
                if (props.onUnbind) props.onUnbind(assets);

            }).catch(reason => showError({
                summary: "Открепление файлов",
                detail: errorMessage(reason)

            })).finally(() => {
                setBusy(false);
                setSelected([]);
            });
        }
    };

    const unbindConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите открепить выбранные файлы?",
            header: "Открепление файлов",
            icon: "pi pi-info-circle",
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                unbind(selected);
            }
        });
    };

    const onMediaAssetChosen = (asset: MediaAsset) => {
        setDialogState(prev => ({
            ...{prev},
            ...{visible: false}
        }));
        bind(asset);
    };

    const tableHeader = () => {
        return <div className="flex flex-row gap-1">
            <ReloadButton onClick={reload} disabled={busy}/>
            <CreateButton tooltip="Добавить файл"
                          onClick={onCreateButtonClick}
                          disabled={busy}
                          className="ml-1"/>
            <DeleteButton onClick={unbindConfirmation}
                          tooltip="Открепить выбранные файлы"
                          disabled={!hasSelected() || busy}
            />
        </div>
    };

    const tableFooter = `Всего ${assets ? assets.length : 0} файлов`;

    return (
        <>
            <DataTable value={assets} dataKey="id"
                       header={tableHeader} footer={tableFooter}
                       selectionMode="multiple" selection={selected!}
                       onSelectionChange={e => setSelected(e.value)}
                       disabled={busy}
                       emptyMessage="Нет файлов."
            >
                <Column selectionMode="multiple"/>
                <Column key="id" field="id" header="УН"
                        dataType="numeric"
                    // sortable
                    // filter filterElement={numberFilterTemplate}
                        hidden={true}
                        style={{width: '3rem'}}
                />
                <Column key="title" field="titile" header="Название"
                        dataType="text" body={(asset) => asset.title}
                    // sortable
                    // filter
                />
                <Column key="name" field="name" header="Имя"
                        dataType="text" body={(asset) => asset.title}
                    // sortable
                    // filter
                />
                <Column key="type" field="type" header="Тип"
                        dataType="text" body={(asset) => assetTypeTitle(asset)}
                    // sortable
                    // filter
                />
            </DataTable>

            <div className="flex flex-row justify-content-end mt-3">
                <CloseButton onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.onClose(e)}/>
            </div>

            <MediaAssetChooserDialog dialogState={dialogState}
                                     notSelectable={assets}
                                     onSelect={onMediaAssetChosen}
                                     onHide={() => setDialogState(prev => ({
                                         ...prev,
                                         ...{visible: false}
                                     }))}
                                     onCancel={() => setDialogState(prev => ({
                                         ...prev,
                                         ...{visible: false}
                                     }))}
                                     onSave={() => {
                                         setDialogState(prev => ({
                                             ...prev,
                                             ...{visible: false}
                                         }));
                                         reload();
                                     }}
            />
        </>
    );
}