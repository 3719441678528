import NotificationRecipient from "./NotificationRecipient";
import {NotificationRecipientCommunicationType} from "./NotificationRecipientCommunicationType";
import {NotificationRecipientStatus} from "./NotificationRecipientStatus";

/**
 * Адресат списка рассылки который не зарегистрирован в портале.
 */
export default interface NotificationRecipientSpecial extends NotificationRecipient {
    address: string;
}

export const initialNotificationRecipient: NotificationRecipientSpecial = {
    id: null,
    communicationType: NotificationRecipientCommunicationType.EMAIL,
    address: "",
    list: [],
    status: NotificationRecipientStatus.ACTIVE,
    type: "special"
}