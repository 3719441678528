import {Card} from "primereact/card";
import React, {useState} from "react";
import {confirmDialog} from "primereact/confirmdialog";
import {useMountEffect} from "primereact/hooks";
import {
    TreeTable,
    TreeTableCheckboxSelectionKeyType,
    TreeTableSelectionEvent,
    TreeTableSelectionKeysType
} from "primereact/treetable";
import {TreeNode} from "primereact/treenode";
import Taxonomy from "../../../interfaces/Taxonomy";
import useUserNotification from "../../../hooks/useUserNotification";
import TaxonomyPropertiesDialog, {TaxonomyDialogState} from "./TaxonomyPropertiesDialog";
import {Paging} from "../../../service/BaseDataService";
import {MAX_INT} from "../../../config";
import {taxonomyService} from "../../../index";
import ApiResponse from "../../../interfaces/response.interface";
import toTreeNodes from "../../../helpers/toTreeNodes";
import {errorMessage} from "../../../helpers/axiosError";
import EditButton from "../../../components/common/buttons/EditButton";
import DeleteButton from "../../../components/common/buttons/DeleteButton";
import CreateButton from "../../../components/common/buttons/CreateButton";
import ReloadButton from "../../../components/common/buttons/ReloadButton";
import {Column} from "primereact/column";


export default function TaxonomyPage() {
    const initTaxonomy: Taxonomy = {
        id: null, name: "", type: "", parent: null
    };

    const [busy, setBusy] =
        useState(false);

    const [treeNodes, setTreeNodes] =
        useState<TreeNode[]>([]);

    const [total, setTotal] =
        useState<number>(0);

    const [selectedNodeKeys, setSelectedNodeKeys] =
        useState<TreeTableSelectionKeysType | null>(null);

    const [dialogState, setDialogState] =
        useState<TaxonomyDialogState>({
            mode: "edit",
            entity: {...initTaxonomy},
            visible: false
        });

    const {showError, showSuccess} = useUserNotification();


    /*useLayoutEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} - Таксономия`;
    }, []);*/

    useMountEffect(() => reload());

    const reload = () => {
        setBusy(true);

        const paging: Paging = {
            page: 0,
            rows: MAX_INT
        };

        taxonomyService.findAll(undefined, paging)
            .then((response: ApiResponse<Taxonomy>) => {
                const treeNodes: TreeNode[] = [];
                toTreeNodes(response.content, treeNodes, null)
                setTreeNodes(treeNodes);
                setTotal(response.totalElements);
            })
            .catch(reason => {
                setTreeNodes([]);
                setTotal(0);
                showError({
                    summary: "Загрузка таксономии",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
            });
    };

    const selectedTaxonomyId: () => number[] = () => {
        return selectedNodeKeys ? Object.entries(selectedNodeKeys)
            .filter(([k, v]) => v && (v as TreeTableCheckboxSelectionKeyType).checked)
            .map(([k, v]) => parseInt(k)) : [];
    }


    const selectionIsEmpty: () => boolean = () => {
        return selectedTaxonomyId().length === 0;
    };

    const deleteOneConfirmation = (rowData: TreeNode) => {
        let taxonomy: Taxonomy = rowData.data;

        confirmDialog({
            message: `Вы действительно хотите удалить термин '${taxonomy.name}' таксономии?`,
            header: "Удаление термина",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                setBusy(true);
                taxonomyService.delete(taxonomy)
                    .then(() => {
                        showSuccess({
                            summary: "Удаление термина",
                            detail: `Термин '${taxonomy.name}' таксономии успешно удалён.`
                        });
                        setSelectedNodeKeys(null);
                        reload();

                    })
                    .catch(reason => showError({
                        summary: "Удаление термина",
                        detail: errorMessage(reason)
                    }))
                    .finally(() => setBusy(false));
            }
        });
    };

    const deleteSelectedConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите удалить выбранные термины таксономии?",
            header: "Удаление терминов",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                if (!selectionIsEmpty()) {
                    setBusy(true);
                    taxonomyService.deleteByIds(selectedTaxonomyId())
                        .then(() => {
                            showSuccess({
                                summary: "Удаление терминов",
                                detail: "Выбранные термины таксономии успешно удалены."
                            });
                            setSelectedNodeKeys(null);
                            reload();
                        })
                        .catch(reason => showError({
                            summary: "Удаление терминов",
                            detail: errorMessage(reason)
                        }))
                        .finally(() => setBusy(false));
                }
            }
        });
    };


    const actionsTemplate = (rowData: TreeNode) => {
        return <div className="flex flex-row gap-1 justify-content-start">
            <EditButton outlined
                        onClick={() => {
                            setDialogState({
                                mode: "edit",
                                entity: {...rowData.data},
                                visible: true
                            });
                        }}/>

            <DeleteButton onClick={() => deleteOneConfirmation(rowData)}
                          tooltip="Удалить выбранный термин"
                          rounded
                          outlined/>

            <CreateButton tooltip="Создать термин под выбранным"
                          onClick={() => {
                              let parent = rowData.data;
                              setDialogState({
                                  mode: "create",
                                  entity: {...initTaxonomy, parent: parent.id, type: parent.type},
                                  visible: true
                              });
                          }}
                          rounded
                          outlined/>
        </div>
    };


    /* Шапка и подвал таблицы */

    const treeTableHeader = (
        <div className="flex flex-row gap-2 justify-content-between">
            <div className="flex flex-row gap-1">
                <ReloadButton onClick={reload} disabled={busy}/>

                <CreateButton tooltip="Зарегистрировать новый термин"
                              disabled={busy}
                              className="ml-1"
                              onClick={() => setDialogState({
                                  mode: "create",
                                  entity: {...initTaxonomy},
                                  visible: true
                              })}/>

                <DeleteButton onClick={deleteSelectedConfirmation}
                              tooltip="Удалить термин"
                              disabled={busy || selectionIsEmpty()}/>
            </div>
        </div>
    );


    const treeTableFooter = `Всего ${total}`;

    return (
        <div className="container">
            <Card title="Термины таксономии">
                <TreeTable value={treeNodes}
                           header={treeTableHeader}
                           footer={treeTableFooter}
                           loading={busy}
                           selectionMode="checkbox"
                           selectionKeys={selectedNodeKeys}
                           onSelectionChange={(e: TreeTableSelectionEvent) => setSelectedNodeKeys(e.value)}
                >
                    <Column body={actionsTemplate} style={{width: "fit-content"}}/>
                    <Column field="name" header="Имя" expander style={{width: "60%"}}/>
                    <Column field="type" header="Тип"/>
                    <Column field="id" header="УН" style={{width: "3rem"}}/>
                </TreeTable>
            </Card>

            <TaxonomyPropertiesDialog dialogState={dialogState}
                                      onHide={() => setDialogState(prev => ({
                                          ...prev,
                                          ...{visible: false}
                                      }))}
                                      onCancel={() => setDialogState(prev => ({
                                          ...prev,
                                          ...{visible: false}
                                      }))}
                                      onSave={() => {
                                          setDialogState(prev => ({
                                              ...prev,
                                              ...{visible: false}
                                          }));
                                          reload();
                                      }}
            />
        </div>
    );
}