import {ClassifierTitle} from "../../interfaces/ClassifierTitle";

export function getClassifierItemTitle(code: string, classifier: ClassifierTitle[]): string | undefined {
    return classifier.find(c => c.code === code)?.title;
}


interface StatusOutputProps {
    code: string;
    classifier: ClassifierTitle[];
}

export default function StatusOutput({code, classifier}: StatusOutputProps) {
    return (
        <>{getClassifierItemTitle(code, classifier)}</>
    );
}