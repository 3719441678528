import {findRoleType, RoleType, roleTypes} from "../../config";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import React from "react";

interface RoleMultiSelectProps {
    id?: string | undefined;
    value: number[];

    onChange(value: number[]): void;
}

export type Props = RoleMultiSelectProps;

export default function RoleMultiSelect(props: Props) {

    /**
     * Шаблон отображения элементов списка выбора
     * @param role роль
     */
    const roleMultiselectTemplate = (role: RoleType) => {
        if (!role.code) throw new Error("Системная ошибка, пользовательская роль не определена.");
        return <>{findRoleType(role.code)?.title}</>;
    }

    /**
     * Шаблон отображения назначенных ролей. Поскольку в настройках компонента выбора optionValue="id",
     * то в этот метод передаётся идентификатор роли.
     * @param roleId идентификатор роли пользователя.
     */
    const selectedRoleTemplate = (roleId: number) => {
        return <span className="p-chip">{findRoleType(roleId)?.title}</span>
    }

    return (
        <MultiSelect id="roles"
                     value={props.value}
                     options={roleTypes}
                     optionValue="code"
            // optionLabel="name" не требуется, поскольку определен шаблон отображения
                     itemTemplate={roleMultiselectTemplate}
                     selectedItemTemplate={selectedRoleTemplate}
                     display="chip"
                     onChange={(e: MultiSelectChangeEvent) => props.onChange && props.onChange(e.value)}
                     placeholder="Выберите роли"/>
    );
}