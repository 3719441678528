import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import React from "react";
import {notificationRecipientStatusTitles} from "../../../interfaces/dictionaries/NotificationRecipientStatusTitles";
import {NotificationRecipientStatus} from "../../../interfaces/notification/NotificationRecipientStatus";

interface NotificationRecipientStatusDropdownProps {
    id?: string | undefined;
    value: NotificationRecipientStatus | undefined;

    onChange(status: NotificationRecipientStatus): void;
}

export default function NotificationRecipientStatusDropdown(props: NotificationRecipientStatusDropdownProps) {
    return <Dropdown id={props.id}
                     value={props.value}
                     options={notificationRecipientStatusTitles}
                     optionValue="code"
                     optionLabel="title"
                     onChange={(e: DropdownChangeEvent) => props.onChange(e.value)}
                     emptyMessage="Нет зарегистрированных статусов адресата."
                     placeholder="Выберите статус адресата"/>;
}