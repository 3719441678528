import {Button, ButtonProps} from "primereact/button";
import React from "react";

interface Props extends ButtonProps {

}

export default function SaveButton(props: Props) {
    return <Button label="Сохранить"
                   icon="pi pi-check"
                   type={"button"}
                   autoFocus
                   {...props}
    />;
}