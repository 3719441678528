import {Link} from "react-router-dom";
import {formatPhone} from "../../helpers/phone";

export default function PhoneOutput(props: { country?: string; number: string | undefined | null; link?: boolean }) {
    if (props.number) {
        if (props.link) {
            let country = props.country ? props.country : "+7";
            return <Link to={`tel:${country}${props.number}`}>{formatPhone(props.number)}</Link>;
        } else {
            return <>{formatPhone(props.number)}</>;
        }
    }
    return null;
}

