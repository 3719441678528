import {Column} from "primereact/column";
import React from "react";
import {TicketMessage} from "../../../interfaces/ticket/TicketMessage";
import ColumnMeta from "../../../interfaces/ColumnMeta";
import {
    dateTimeRangeFilterTemplate,
    numberFilterTemplate,
    textFilterTemplate
} from "../../../components/common/dataTable/filterTemplates";
import {formattedDateTime} from "../../../components/common/dataTable/bodyTemplates";
import TemporalUpdatableEntity from "../../../interfaces/TemporalUpdatableEntity";
import UserProfileFullNameOutput from "../../../components/common/UserProfileFullNameOutput";
import TicketMessageStatusOutput from "../../../components/ticketMessage/TicketMessageStatusOutput";


export function initialTicketMessageColumns() {
    return ticketMessageColumns(ticketMessage => <></>)
        .filter(column => !column.hidden)
        .map(column => column.field)
}

export function ticketMessageColumns(
    assetsTemplate: (ticketMessage: TicketMessage) => React.ReactNode): ColumnMeta[] {

    const columns: ColumnMeta[] = [
        {
            field: 'id',
            header: 'УН',
            hidden: true,
            column: <Column key="id" field="id" header="УН" sortable dataType="numeric"
                            filter filterElement={numberFilterTemplate}
                            style={{width: '3rem'}}
            />
        },
        {
            field: 'created',
            header: 'Создан',
            hidden: true,
            column: <Column key="created" field="created" header="Создан" sortable
                            dataType="text" body={(rowData: TemporalUpdatableEntity) => formattedDateTime(rowData.created)}
                            filter filterElement={dateTimeRangeFilterTemplate}
            />
        },
        {
            field: 'updated',
            header: 'Обновлен',
            hidden: true,
            column: <Column key="updated" field="updated" header="Обновлен" sortable
                            dataType="text" body={(rowData: TemporalUpdatableEntity) => formattedDateTime(rowData.updated)}
                            filter filterElement={dateTimeRangeFilterTemplate}
            />
        },
        {
            field: 'profile',
            header: 'Автор',
            hidden: false,
            column: <Column key="profile" field="profile" header="Автор" sortable
                            dataType="text"
                            body={(rowData: TicketMessage) =>
                                <UserProfileFullNameOutput profileId={rowData.profile}/>
                            }
            />
        },
        {
            field: 'rating',
            header: 'Рейтинг',
            hidden: false,
            column: <Column key="rating" field="rating" header="Рейтинг" sortable
                            dataType="numeric" body={(rowData: TicketMessage) => <>{rowData.rating}</>}
                            filter filterElement={textFilterTemplate}
            />
        },
        {
            field: 'text',
            header: 'Текст',
            hidden: false,
            column: <Column key="text" field="text" header="Текст" sortable
                            dataType="text"
                            body={(rowData: TicketMessage) => <>{rowData.text}</>}
                            filter filterElement={textFilterTemplate}
            />
        },
        {
            field: "",
            header: "Документы",
            hidden: false,
            column: <Column key="documents" field="documents" header="Документы"
                            body={assetsTemplate}
            />
        },
        {
            field: "sticky",
            header: "Статус",
            hidden: false,
            column: <Column key="sticky" field="sticky" header="Прикреплено"
                            body={(rowData: TicketMessage) => <>{rowData.sticky}</>}
            />
        },
        {
            field: "status",
            header: "Статус",
            hidden: false,
            column: <Column key="status" field="status" header="Статус"
                            body={(rowData: TicketMessage) =>
                                <TicketMessageStatusOutput status={rowData.status}/>
                            }
            />
        },
        {
            field: "ticket",
            header: "Обращение",
            hidden: false,
            column: <Column key="ticket" field="ticket" header="Обращение"
                            body={(rowData: TicketMessage) => <>{rowData.ticket}</>}
            />
        }
    ];

    return columns;
}