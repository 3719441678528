import {InputTextarea, InputTextareaProps} from "primereact/inputtextarea";
import React from "react";

interface NotesProps extends InputTextareaProps {

}

export default function Notes(props: NotesProps) {
    return (
        <InputTextarea autoResize rows={5} cols={30} {...props}/>
    );
}