import {Link} from "react-router-dom";
import {mediaAssetService} from "../../../index";
import React from "react";
import {MediaAsset} from "../../../interfaces/MediaAsset";

export default function MediaAssetDownloadLink({asset}: { asset: MediaAsset | undefined | null }) {
    if (asset)
        return <Link to={""} onClick={() => mediaAssetService.downloadAsset(asset)}><i
            className="pi pi-paperclip"></i> {asset.title}</Link>

    return null;
};