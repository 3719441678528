import {BaseDataService} from "./BaseDataService";
import {API_SERVICE_PROGRAM_URL} from "../config";
import ServiceProgram from "../interfaces/ServiceProgram";
import axios, {AxiosResponse} from "axios";
import {MediaAsset} from "../interfaces/MediaAsset";
import idToString from "../helpers/entitiesHelper";

export type ServiceProgramsAssets = {
    serviceProgram: ServiceProgram;
    assets: MediaAsset[];
};

export default class ServiceProgramService extends BaseDataService<ServiceProgram> {

    constructor() {
        super(API_SERVICE_PROGRAM_URL);
    }

    async findAssets(serviceProgramId: number) {
        const response: AxiosResponse<MediaAsset[]> =
            await axios.get(`${this.url}/${serviceProgramId}/assets`);
        return response.data;
    }

    async loadServicePrograms(servicePrograms: ServiceProgram[]): Promise<ServiceProgramsAssets[]> {
        const dictionary: ServiceProgramsAssets[] = [];
        const dictionaryLoader = async (serviceProgram: ServiceProgram) => {
            if (serviceProgram.id) {
                const assets = await this.findAssets(serviceProgram.id);
                dictionary.push({
                    serviceProgram: serviceProgram,
                    assets: assets
                });
            }
        }

        await Promise.all(
            servicePrograms
                .filter(serviceProgram => serviceProgram.id)
                .map(serviceProgram => dictionaryLoader(serviceProgram))
        );

        return dictionary;
    }

    async assetBind(serviceProgramId: number, mediaAssetId: number) {
        const response =
            await axios.put(`${this.url}/${serviceProgramId}/assets/${mediaAssetId}/bind`);
        return response.data;
    }

    async assetUnbind(serviceProgramId: number, mediaAssetId: number) {
        const response =
            await axios.delete(`${this.url}/${serviceProgramId}/assets/${mediaAssetId}/unbind`);
        return response.data;
    }

    async assetsUnbind(serviceProgramId: number, mediaAssets: MediaAsset[]) {
        const mediaAssetsId = idToString(mediaAssets);
        const response =
            await axios.delete(`${this.url}/${serviceProgramId}/assets/${mediaAssetsId}/unbind`);
        return response.data;
    }
}