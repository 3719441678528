import {API_DATA_EXPORT_URL} from "../../../config";
import axios, {AxiosError, AxiosResponse} from "axios";
import {ApiErrorResponse} from "../../../interfaces/response.interface";


export default class UserDataExportService {
    url: string = API_DATA_EXPORT_URL;

    excelTemplate(onSuccess?: () => void, onError?: (error: AxiosError) => void) {
        let request: string = this.url + "/users/excel/template";
        let contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        let filename: string = "Зарегистрированные пользователи (шаблон).xlsx";

        axios.get(request, {
            headers: {'Content-Type': contentType},
            responseType: 'blob'

        })
            .then((response: AxiosResponse<Blob>) => {
                return response.data;

            })
            .then((blob: Blob) => {
                const href: string = window.URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement('a');
                a.href = href;
                a.download = filename;
                document.body.appendChild(a);       // append the element to the dom
                a.click();
                a.remove();                         // afterwards, remove the element

                if (onSuccess) {
                    onSuccess();
                }

            })
            .catch((error: AxiosError<ApiErrorResponse>) => {
                if (onError) {
                    onError(error)
                }
            });
    }

    /*
        Функция исключена по требованию от 16.02.2024.
     */
    /*
    toExcel(onSuccess?: () => void, onError?: (error: AxiosError) => void) {
        let request: string = this.url + "/users/excel";
        let contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        let filename: string = "Зарегистрированные пользователи.xlsx";

        axios.get(request, {
            headers: {'Content-Type': contentType},
            responseType: 'blob'

        })
            .then((response: AxiosResponse<Blob>) => {
                return response.data;

            })
            .then((blob: Blob) => {
                const href: string = window.URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement('a');
                a.href = href;
                a.download = filename;
                document.body.appendChild(a);       // append the element to the dom
                a.click();
                a.remove();                         // afterwards, remove the element

                if (onSuccess) {
                    onSuccess();
                }

            })
            .catch((error: AxiosError<ApiErrorResponse>) => {
                if (onError) onError(error)
            });
    }
     */
}