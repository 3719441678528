export type NameTitlePair = {
    name: string;
    title: string;
};

export const userProperties: NameTitlePair[] = [
    {name: "id", title: "УН"},
    {name: "created", title: "Создан"},
    {name: "updated", title: "Обновлен"},
    {name: "registered", title: "Зарегистрирован"},
    {name: "lastLogin", title: "Последний вход"},
    {name: "username", title: "Пользователь"},
    {name: "ipAddress", title: "IP"},
    {name: "profile.secondName", title: "Фамилия"},
    {name: "profile.firstName", title: "Имя"},
    {name: "profile.thirdName", title: "Отчество"},
    {name: "profile.dateOfBirth", title: "Дата рождения"},
    {name: "email", title: "Электронная почта"},
    {name: "emailStatus", title: "Статус электронной почты"},
    {name: "mobilePhone.country", title: "Код страны мобильного телефона"},
    {name: "mobilePhone.number", title: "Номер мобильного телефона"},
    {name: "mobilePhone.status", title: "Статус мобильного телефона"},
    {name: "status", title: "Статус"},
    {name: "profile.insurer", title: "Страхователь"},
    {name: "profile.insuranceAgreementNumber", title: "Номер договора со страхователем"},
    {name: "profile.insurancePeriodStartDate", title: "Дата прикрепления"},
    {name: "profile.insurancePeriodEndDate", title: "Дата открепления"},
    {name: "profile.insurancePolicyNumber", title: "Номер страхового полиса"},
    {name: "profile.insuranceProgram.id", title: "Программа страхования"},
    {name: "profile.serviceProgram.id", title: "План обслуживания"},
    {name: "profile.therapist.id", title: "Врач"},
    {name: "profile.dispatcherConsole.id", title: "Диспетчерский пульт"},
    {name: "profile.organization.id", title: "Организация"}
];

export function toPropertyTitle(name: string): string | undefined {
    let nameTitlePair: NameTitlePair | undefined = userProperties
        .find(pt => pt.name === name);
    return nameTitlePair ? nameTitlePair.title : undefined;
}

export function toPropertyName(title: string): string | undefined {
    let nameTitlePair: NameTitlePair | undefined = userProperties
        .find(pt => pt.title === title);
    return nameTitlePair ? nameTitlePair.name : undefined;
}

