import React from "react";
import {Card} from "primereact/card";
import MediaAssetPanel from "../components/common/mediaAsset/MediaAssetPanel";

export default function MediaAssetsPage() {

    return (
        <div className="container-fluid">
            <Card>
                <MediaAssetPanel mode="viewer"/>
            </Card>
        </div>
    );
}