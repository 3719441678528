import ColumnMeta from "../../interfaces/ColumnMeta";
import {textFilterTemplate} from "../../components/common/dataTable/filterTemplates";
import {Column} from "primereact/column";
import React from "react";
import InsuranceProgram from "../../interfaces/InsuranceProgram.interface";
import {
    columnCreated,
    columnId,
    columnTaxonomy,
    columnUpdated
} from "../../components/common/dataTable/columns/BaseColumns";


export function initialInsuranceProgramColumns() {
    return insuranceProgramColumns(insuranceProgram => <></>)
        .filter(column => !column.hidden)
        .map(column => column.field)
}

export function insuranceProgramColumns(
    assetsTemplate: (insuranceProgram: InsuranceProgram) => React.ReactNode): ColumnMeta[] {

    const columns: ColumnMeta[] = [
        {
            field: 'id',
            header: 'УН',
            hidden: true,
            column: columnId
            /*column: <Column key="id" field="id" header="УН" sortable dataType="numeric"
                            filter filterElement={numberFilterTemplate}
                            style={{width: '3rem'}}
            />*/
        },
        {
            field: 'created',
            header: 'Создан',
            hidden: true,
            column: columnCreated
        },
        {
            field: 'updated',
            header: 'Обновлен',
            hidden: true,
            column: columnUpdated
        },
        {
            field: 'name',
            header: 'Название',
            hidden: false,
            column: <Column key="name" field="name" header="Название" sortable
                            dataType="text" body={(rowData: InsuranceProgram) => rowData.name}
                            filter filterElement={textFilterTemplate}
            />
        },
        {
            field: "documents",
            header: "Документы",
            hidden: false,
            column: <Column key="documents" field="documents" header="Документы"
                            body={assetsTemplate}
            />
        },
        {
            field: 'taxonomy',
            header: 'Таксономия',
            hidden: false,
            column: columnTaxonomy
        },
    ];

    return columns;
}