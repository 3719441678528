import ColumnMeta from "../../../interfaces/ColumnMeta";
import {Column} from "primereact/column";
import {
    dateTimeRangeFilterTemplate,
    numberFilterTemplate,
    textFilterTemplate
} from "../../../components/common/dataTable/filterTemplates";
import {formattedDateTime} from "../../../components/common/dataTable/bodyTemplates";
import React from "react";
import UserProfileFullNameOutput from "../../../components/common/UserProfileFullNameOutput";
import {Ticket} from "../../../interfaces/ticket/Ticket";
import TicketStatusOutput from "../../../components/ticket/TicketStatusOutput";

export const ticketColumns: ColumnMeta[] = [
    {
        field: 'id',
        header: 'УН',
        hidden: true,
        column: <Column key="id" field="id" header="УН" sortable dataType="numeric"
                        filter filterElement={numberFilterTemplate}
                        style={{width: '3rem'}}
        />
    },
    {
        field: 'created',
        header: 'Создан',
        hidden: true,
        column: <Column key="created" field="created" header="Создан" sortable
                        dataType="text" body={(rowData) => formattedDateTime(rowData.created)}
                        filter filterElement={dateTimeRangeFilterTemplate}
        />
    },
    {
        field: 'updated',
        header: 'Обновлен',
        hidden: true,
        column: <Column key="updated" field="updated" header="Обновлен" sortable
                        dataType="text" body={(rowData) => formattedDateTime(rowData.updated)}
                        filter filterElement={dateTimeRangeFilterTemplate}
        />
    },
    {
        field: 'author',
        header: 'Автор',
        hidden: false,
        column: <Column key="author" field="author" header="Автор" sortable
                        dataType="text"
                        body={(rowData: Ticket) =>
                            <UserProfileFullNameOutput profileId={rowData.author}/>
                        }
        />
    },
    {
        field: 'topic',
        header: 'Тема',
        hidden: false,
        column: <Column key="topic" field="topic" header="Тема" sortable
                        dataType="text" body={(rowData) => <>{rowData.topic}</>}
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "status",
        header: "Статус",
        hidden: false,
        column: <Column key="status" field="status" header="Статус"
                        body={(rowData: Ticket) => <TicketStatusOutput status={rowData.status}/>}
        />
    }
];