import {useAuth} from "./AuthProvider";
import {Navigate, useLocation} from "react-router-dom";

const Authentication = ({children}: any) => {
    const {isAnonymous} = useAuth();
    const location = useLocation();

    if (isAnonymous()) {
        return <Navigate to="/login"
                         state={{path: location.pathname}}/>
    } else {
        return children;
    }
};

export default Authentication;