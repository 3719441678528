import {Dialog} from "primereact/dialog";
import NotificationRecipientTable, {NotificationRecipientTableMode} from "./NotificationRecipientTable";
import NotificationRecipientSpecial from "../../../interfaces/notification/NotificationRecipientSpecial";

interface NotificationRecipientChooserDialogProps {
    nonSelectable: number[] | undefined;
    visible: boolean;

    onHide(): void;

    onSelect(recipients: NotificationRecipientSpecial): void;
}

export default function NotificationRecipientChooserDialog(props: NotificationRecipientChooserDialogProps) {
    return (
        <Dialog header={"Выберите адресата"}
                visible={props.visible}
                onHide={props.onHide}
                className="app-dialog-full">
            <NotificationRecipientTable mode={NotificationRecipientTableMode.CHOOSER}
                                        nonSelectable={props.nonSelectable}
                                        onSelect={props.onSelect}/>
        </Dialog>
    );
};