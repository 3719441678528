import {Button, ButtonProps} from "primereact/button";
import React from "react";

interface Props extends ButtonProps {
}

export default function ReloadButton(props: Props) {
    return <Button icon="pi pi-fw pi-refresh"
                   type="button"
                   tooltip="Обновить" tooltipOptions={{position: 'top'}}
                   outlined
                   className="p-button-sm"
                   {...props}
    />;
}