import React, {useState} from "react";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import SaveButton from "../../../components/common/buttons/SaveButton";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {errorMessage} from "../../../helpers/axiosError";
import {BlockUI} from "primereact/blockui";
import {TicketMessage} from "../../../interfaces/ticket/TicketMessage";
import {ticketMessageService} from "../../../index";
import {Checkbox} from "primereact/checkbox";
import {MultiStateCheckbox, MultiStateCheckboxChangeEvent} from "primereact/multistatecheckbox";
import {EntityDialogState} from "../../../components/common/dialog/EntityDialogState";
import {Mode} from "node:fs";
import useUserNotification from "../../../hooks/useUserNotification";
import CancelButton from "../../../components/common/buttons/CancelButton";
import TicketMessageStatusDropdown from "../../../components/ticketMessage/TicketMessageStatusDropdown";

export interface TicketMessageDialogState extends EntityDialogState<TicketMessage> {
}

export interface TicketMessageDialogProps {
    ticketMessage: TicketMessage;
    mode: Mode;
    visible: boolean;

    onHide(): void;

    onCancel?(): void;

    onSave?(): void
}

interface RatingItem {
    value: number;
    icon: string;
}

export default function TicketMessagePropertiesDialog(props: TicketMessageDialogProps) {

    const title = props.mode === "create" ?
        "Регистрация сообщения" :
        "Редактирование карточки сообщения";

    const [busy, setBusy] = useState<boolean>(false);

    const [editedTicketMessage, setEditedTicketMessage] =
        useState<TicketMessage>(props.ticketMessage);

    const {showError, showSuccess} = useUserNotification();

    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload();
    }, [props.ticketMessage]);

    const reload = () => {
        setEditedTicketMessage(props.ticketMessage);
        /*if (props.ticketMessage.id) {
            setBusy(true);
            ticketMessageService
                .findAssets(props.ticketMessage.id)
                .then(assets => {
                    setAssets(assets);
                    setBusy(false);
                })
                .catch(reason => showError({
                    summary: "Загрузка документов",
                    detail: "Ошибка загрузки приложенных документов: " + errorMessage(reason)
                }));
        }*/
    };

    const save = () => {
        if (props.mode === "create") {
            setBusy(true);
            ticketMessageService.create(editedTicketMessage)
                .then(() => {
                    showSuccess({
                        summary: "Регистрация сообщения",
                        detail: `Сообщение "${editedTicketMessage.text}" успешно зарегистрировано в системе.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Регистрация сообщения",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));

        } else if (props.mode === "edit" && editedTicketMessage.id) {
            setBusy(true);
            ticketMessageService.update(editedTicketMessage.id, editedTicketMessage)
                .then(() => {
                    showSuccess({
                        summary: "Обновление сообщения",
                        detail: `Регистрационные данные сообщения "${editedTicketMessage.text}" успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Регистрация сообщения",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    };


    const footer = (
        <div>
            <SaveButton onClick={() => save()} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );

    const ratingOptions: RatingItem[] = [
        {value: 0, icon: 'pi pi-times'},
        {value: -1, icon: 'pi pi-minus'},
        {value: 1, icon: 'pi pi-plus'}
    ];

    return (
        <BlockUI blocked={busy}>
            <Dialog header={title}
                    visible={props.visible}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-dialog">

                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="ticket" className="font-bold">Обращение</label>
                        <InputText id="ticket" value={""} placeholder="Обращение"/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="name" className="font-bold">Текст</label>
                        <InputText id="name" value={editedTicketMessage.text}
                                   onChange={(e) => {
                                       setEditedTicketMessage(prev => ({
                                           ...prev,
                                           ...{name: e.target.value}
                                       }))
                                   }}/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="profile" className="font-bold">Пользователь</label>
                        <InputText id="profile" value={""} placeholder="Пользователь"/>
                    </div>
                    <div className="flex flex-row gap-2 m-0">
                        <Checkbox id="sticky"
                                  checked={editedTicketMessage.sticky}
                                  onChange={(e) => {
                                      setEditedTicketMessage(prev => ({
                                          ...prev,
                                          ...{sticky: e.checked ? e.checked : false}
                                      }))
                                  }}
                        />
                        <label htmlFor="sticky" className="font-bold">Прикреплено</label>
                    </div>
                    <div className="flex flex-row gap-2 m-0">
                        <MultiStateCheckbox value={editedTicketMessage.rating}
                                            onChange={(e: MultiStateCheckboxChangeEvent) => {
                                                setEditedTicketMessage(prev => ({
                                                    ...prev,
                                                    rating: e.value
                                                }))
                                            }}
                                            options={ratingOptions}
                                            optionValue="value"/>
                        <label htmlFor="sticky" className="font-bold">Рейтинг</label>
                    </div>
                    <div className="flex flex-column gap-2 m-0 flex-auto">
                        <label htmlFor="status" className="font-bold">Статус</label>
                        <TicketMessageStatusDropdown id="status"
                                                     status={editedTicketMessage.status}
                                                     onStatusSelect={status =>
                                                         setEditedTicketMessage(prev => ({
                                                             ...prev,
                                                             status: status
                                                         }))}/>
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    );
};