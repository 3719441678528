import {ClassifierTitle} from "../ClassifierTitle";
import {TicketStatus} from "../enum/TicketStatus";

export const ticketStatusTitles: ClassifierTitle[] = [
    {code: TicketStatus.CREATED, title: "Создано"},
    {code: TicketStatus.OPENED, title: "Открыто"},
    {code: TicketStatus.REOPENED, title: "Возобновлено"},
    {code: TicketStatus.PROGRESS, title: "Обрабатывается"},
    {code: TicketStatus.COMPLETED, title: "Выполнено"},
    {code: TicketStatus.BLOCKED, title: "Заблокировано"},
    {code: TicketStatus.HIDDEN, title: "Скрыто"},
    {code: TicketStatus.DELETED, title: "Удалено"}
];
