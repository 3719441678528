import NotificationList from "../../interfaces/notification/NotificationList";
import {API_NOTIFICATION_LISTS_URL} from "../../config";
import {BaseTaxonomyHolder} from "../BaseTaxonomyHolder";

export default class NotificationListService extends BaseTaxonomyHolder<NotificationList> {

    constructor() {
        super(API_NOTIFICATION_LISTS_URL);
    }

    /*async bindRecipients(id: number, recipients: number[]) {
        const ids = recipients.join(",");
        const response = await axios.patch(`${this.url}/${id}/recipients/${ids}`);
        return response.data;
    }*/
}