import {Button, ButtonProps} from "primereact/button";
import React from "react";

interface Props extends ButtonProps {

}

export default function EditButton(props: Props) {
    return <Button icon="pi pi-fw pi-pencil"
                   type="button"
                   tooltip="Редактировать карточку"
                   rounded
                   {...props}
    />
}