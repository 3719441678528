import {useAuth} from "./AuthProvider";
import {useEffect} from "react";

export default function Logout() {
    const {logout} = useAuth();

    useEffect(() => {
        logout();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};