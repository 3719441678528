import {useState} from "react";
import MessageTemplate from "../../interfaces/notification/MessageTemplate";
import {Dropdown, DropdownChangeEvent, DropdownProps} from "primereact/dropdown";
import {messageTemplateService} from "../../index";
import {errorMessage} from "../../helpers/axiosError";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";

interface MessageTemplateDropdownProps {
    id?: string | undefined;
    messageTemplateId: number | undefined | null;
    onChoose?: (messageTemplateId: number | null) => void;
}

export type Props = MessageTemplateDropdownProps;

export default function MessageTemplateDropdown(props: MessageTemplateDropdownProps) {

    const [busy, setBusy] =
        useState<boolean>(false);

    const [messageTemplates, setMessageTemplates] =
        useState<MessageTemplate[]>([]);

    const reload = () => {
        setBusy(true);
        messageTemplateService.findAll()
            .then(response => setMessageTemplates(response.content))
            .catch(reason => {
                console.error(errorMessage(reason));
                setMessageTemplates([]);
            })
            .finally(() => setBusy(false))
    };

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [props.messageTemplateId]);

    const itemTemplate = (messageTemplate: MessageTemplate) => {
        return messageTemplate.name;
    }

    const valueTemplate = (messageTemplate: MessageTemplate, props: DropdownProps) => {
        if (messageTemplate) {
            return messageTemplate.name;
        }
        return <>{props.placeholder}</>
    }

    return (
        <Dropdown value={props.messageTemplateId}
                  options={messageTemplates}
                  loading={busy}
                  itemTemplate={itemTemplate}
                  valueTemplate={valueTemplate}
                  optionValue="id"
                  optionLabel="id"
                  showClear
                  filter
                  filterBy="name"
                  onChange={(e: DropdownChangeEvent) => props.onChoose && props.onChoose(e.value)}
                  placeholder="Выберите шаблон сообщений"
                  className="w-full"
        />
    );
}