import {API_INSURANCE_PROGRAM_URL} from "../config";
import {BaseDataService} from "./BaseDataService";
import InsuranceProgram from "../interfaces/InsuranceProgram.interface";
import axios, {AxiosResponse} from "axios";
import {MediaAsset} from "../interfaces/MediaAsset";
import idToString from "../helpers/entitiesHelper";

export type InsuranceProgramsAssets = {
    insuranceProgram: InsuranceProgram;
    assets: MediaAsset[];
};

export default class InsuranceProgramService extends BaseDataService<InsuranceProgram> {

    constructor() {
        super(API_INSURANCE_PROGRAM_URL);
    }

    async findAssets(insuranceProgramId: number) {
        const response: AxiosResponse<MediaAsset[]> =
            await axios.get(`${this.url}/${insuranceProgramId}/assets`);
        return response.data;
    }

    async loadInsurancePrograms(insurancePrograms: InsuranceProgram[]): Promise<InsuranceProgramsAssets[]> {
        const dictionary: InsuranceProgramsAssets[] = [];
        const dictionaryLoader = async (insuranceProgram: InsuranceProgram) => {
            if (insuranceProgram.id) {
                const assets = await this.findAssets(insuranceProgram.id);
                dictionary.push({
                    insuranceProgram: insuranceProgram,
                    assets: assets
                });
            }
        }

        await Promise.all(
            insurancePrograms
                .filter(insuranceProgram => insuranceProgram.id)
                .map(insuranceProgram => dictionaryLoader(insuranceProgram))
        );

        return dictionary;
    }

    async assetBind(insuranceProgramId: number, mediaAssetId: number) {
        const response =
            await axios.put(`${this.url}/${insuranceProgramId}/assets/${mediaAssetId}/bind`);
        return response.data;
    }

    async assetUnbind(insuranceProgramId: number, mediaAssetId: number) {
        const response =
            await axios.delete(`${this.url}/${insuranceProgramId}/assets/${mediaAssetId}/unbind`);
        return response.data;
    }

    async assetsUnbind(insuranceProgramId: number, mediaAssets: MediaAsset[]) {
        const mediaAssetsId = idToString(mediaAssets);
        const response =
            await axios.delete(`${this.url}/${insuranceProgramId}/assets/${mediaAssetsId}/unbind`);
        return response.data;
    }


    /*
        async findAllWithAssets(filter?: string, paging?: Paging, sorting?: Sorting): Promise<Awaited<InsuranceProgram>[]> {
            let {content: insurancePrograms} = await this.findAll(filter, paging, sorting);

            async function loader(ip: InsuranceProgram): Promise<InsuranceProgram> {
                // @ts-ignore
                const r = await mediaAssetService.in(ip.documents);
                ip.assets = r.content;
                return ip;
            }

            return await Promise.all(insurancePrograms.filter(ip => ip.documents).map(loader)
            );
        }*/
};