import StatusOutput from "../../common/StatusOutput";
import {
    NotificationRecipientCommunicationType
} from "../../../interfaces/notification/NotificationRecipientCommunicationType";
import {
    notificationRecipientCommunicationTypeTitles
} from "../../../interfaces/dictionaries/NotificationRecipientCommunicationTypeTitles";

interface NotificationRecipientCommunicationTypeOutputProps {
    status: NotificationRecipientCommunicationType;
}

export default function NotificationRecipientCommunicationTypeOutput(props: NotificationRecipientCommunicationTypeOutputProps) {
    return (
        <StatusOutput code={props.status}
                      classifier={notificationRecipientCommunicationTypeTitles}/>
    );
};