import {DataTableFilterMeta} from "primereact/datatable";
import {FilterMatchMode, FilterOperator} from "primereact/api";

export const mediaAssetPanelFilterInitialState: DataTableFilterMeta = {
    'id': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'uuid': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
    },
    'created': {
        operator: FilterOperator.AND,
        constraints: [
            {value: null, matchMode: FilterMatchMode.DATE_AFTER},
            {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
        ]
    },
    'updated': {
        operator: FilterOperator.AND,
        constraints: [
            {value: null, matchMode: FilterMatchMode.DATE_AFTER},
            {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
        ]
    },
    'title': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
    },
    'name': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
    },
    'type': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'contentMimeType': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
    },
    'contentLength': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
}