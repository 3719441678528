import {IMobilePhone} from "../interfaces/IMobilePhone";

export function formatPhone(number: string) {
    return number ? ["+7", "(" + number.substring(0, 3) + ")", number.substring(3)].join(" ") : null;
}

export function cleanPhone(phone: string) {
    return phone.replaceAll(/\D/g, "");
}

export function mobilePhoneTextToObject(text: string): IMobilePhone {
    if (!text || text.length < 10) return {
        country: undefined,
        number: undefined
    }

    let t = cleanPhone(text);

    return {
        country: t.substring(0, t.length - 10),
        number: t.substring(t.length - 10)
    }
}