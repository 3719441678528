import TemporalUpdatableEntity from "./TemporalUpdatableEntity";
import Address from "./Address";
import {OrganizationStatus} from "./enum/OrganizationStatus";

export default interface Organization extends TemporalUpdatableEntity {
    activityStartDate?: string | undefined;
    ceoName?: string | undefined;
    description?: string | undefined;
    fullTitle?: string | undefined;
    legalBasis?: string | undefined;
    locationAddress?: Address | undefined;
    postAddress?: Address | undefined;
    postAddressSameAsLocation?: boolean | undefined;
    shortTitle?: string | undefined;
    status: OrganizationStatus;
    taxonomy: number | null;
    tin?: string | undefined;
    // types: Taxonomy | undefined;
    website?: string | undefined;
}

export const initialOrganization: Organization = {
    id: null,
    taxonomy: null,
    status: OrganizationStatus.ACTIVE
}