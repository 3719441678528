import TemporalUpdatableEntity from "./TemporalUpdatableEntity";

export default interface InsuranceProgram extends TemporalUpdatableEntity {
    name: string;
    taxonomy: number | null;
}

export const initialInsuranceProgram: InsuranceProgram = {
    id: null,
    created: undefined,
    updated: undefined,
    name: '',
    taxonomy: null
};