import React from "react";
import NotificationRecipientTable, {
    NotificationRecipientTableMode
} from "../../../../components/notification/recipient/NotificationRecipientTable";


export default function NotificationRecipientPage() {
    return (
        <div className="container">
            <NotificationRecipientTable mode={NotificationRecipientTableMode.DICTIONARY}/>
        </div>
    );
}