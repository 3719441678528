import ColumnMeta from "../../interfaces/ColumnMeta";
import {
    dateTimeRangeFilterTemplate,
    numberFilterTemplate,
    textFilterTemplate
} from "../../components/common/dataTable/filterTemplates";
import {Column} from "primereact/column";
import React from "react";
import {formattedDateTime} from "../../components/common/dataTable/bodyTemplates";
import ServiceProgram from "../../interfaces/ServiceProgram";
import {columnTaxonomy} from "../../components/common/dataTable/columns/BaseColumns";


export function initialServiceProgramColumns() {
    return serviceProgramColumns(serviceProgram => <></>)
        .filter(column => !column.hidden)
        .map(column => column.field)
}

export function serviceProgramColumns(
    assetsTemplate: (serviceProgram: ServiceProgram) => React.ReactNode): ColumnMeta[] {

    const columns: ColumnMeta[] = [
        {
            field: 'id',
            header: 'УН',
            hidden: true,
            column: <Column key="id" field="id" header="УН" sortable dataType="numeric"
                            filter filterElement={numberFilterTemplate}
                            style={{width: '3rem'}}
            />
        },
        {
            field: 'created',
            header: 'Создан',
            hidden: true,
            column: <Column key="created" field="created" header="Создан" sortable
                            dataType="text" body={(rowData) => formattedDateTime(rowData.created)}
                            filter filterElement={dateTimeRangeFilterTemplate}
            />
        },
        {
            field: 'updated',
            header: 'Обновлен',
            hidden: true,
            column: <Column key="updated" field="updated" header="Обновлен" sortable
                            dataType="text" body={(rowData) => formattedDateTime(rowData.updated)}
                            filter filterElement={dateTimeRangeFilterTemplate}
            />
        },
        {
            field: 'name',
            header: 'Название',
            hidden: false,
            column: <Column key="name" field="name" header="Название" sortable
                            dataType="text" body={(rowData) => <>{rowData.name}</>}
                            filter filterElement={textFilterTemplate}
            />
        },
        {
            field: "",
            header: "Документы",
            hidden: false,
            column: <Column key="documents" field="documents" header="Документы"
                            body={assetsTemplate}
            />
        },
        {
            field: 'taxonomy',
            header: 'Таксономия',
            hidden: false,
            column: columnTaxonomy
        },
    ];

    return columns;
}