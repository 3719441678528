import Taxonomy from "../interfaces/Taxonomy";
import {TreeNode} from "primereact/treenode";

export default function toTreeNodes(taxonomies: Taxonomy[],
                                    treeNodes: TreeNode[],
                                    parent: TreeNode | null) {
    if (parent) {
        parent.children = taxonomies
            .filter(t => t.parent === parent.data.id)
            .map(t => toTreeNode(t));
        parent.children.forEach(c => toTreeNodes(taxonomies, treeNodes, c));

    } else {
        taxonomies
            .filter(t => !t.parent)
            .map(t => toTreeNode(t))
            .forEach(tn => {
                treeNodes.push(tn);
                toTreeNodes(taxonomies, treeNodes, tn);
            });
    }
}

function toTreeNode(taxonomy: Taxonomy): TreeNode {
    return {
        key: taxonomy.id ? taxonomy.id : undefined,
        data: taxonomy,
        label: taxonomy.name,
        children: [],
    };
}