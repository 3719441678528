import {Column} from "primereact/column";
import React from "react";
import ColumnMeta from "../../../../interfaces/ColumnMeta";
import NotificationList from "../../../../interfaces/notification/NotificationList";
import NotificationListStatusOutput from "../../../../components/notification/list/NotificationListStatusOutput";
import {textFilterTemplate} from "../../../../components/common/dataTable/filterTemplates";
import {Tag} from "primereact/tag";
import NotificationRecipientOutput from "../../../../components/notification/recipient/NotificationRecipientOutput";
import {
    columnCreated,
    columnId,
    columnTaxonomy,
    columnUpdated
} from "../../../../components/common/dataTable/columns/BaseColumns";

export function initialNotificationListColumns() {
    return notificationListColumns
        .filter(column => !column.hidden)
        .map(column => column.field)
}

export const notificationListColumns: ColumnMeta[] = [
    {
        field: 'id',
        header: 'УН',
        hidden: true,
        /*column: <Column key="id" field="id" header="УН" sortable dataType="numeric"
                        filter filterElement={numberFilterTemplate}
                        style={{width: '3rem'}}
        />*/
        column: columnId
    },
    {
        field: 'created',
        header: 'Создан',
        hidden: true,
        column: columnCreated
    },
    {
        field: 'updated',
        header: 'Обновлен',
        hidden: true,
        column: columnUpdated
    },
    {
        field: 'name',
        header: 'Наименование',
        hidden: false,
        column: <Column key="name" field="name" header="Наименование" sortable
                        dataType="text" body={(rowData: NotificationList) => <>{rowData.name}</>}
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: 'taxonomy',
        header: 'Таксономия',
        hidden: false,
        column: columnTaxonomy
    },
    {
        field: 'recipients',
        header: 'Адресаты',
        hidden: false,
        column: <Column key="recipients" field="recipients" header="Адресаты" sortable
                        dataType="numeric" body={(rowData: NotificationList) =>
            <div className="flex flex-row flex-wrap gap-1">{
                rowData.recipients.map((r, index) => {
                    return <Tag key={index}><NotificationRecipientOutput recipientId={r}/></Tag>
                })
            }</div>
        }/>
    },
    {
        field: 'notes',
        header: 'Примечания',
        hidden: true,
        column: <Column key="notes" field="notes" header="Примечания" sortable
                        dataType="text" body={(rowData: NotificationList) => <>{rowData.notes}</>}
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "status",
        header: "Статус",
        hidden: false,
        column: <Column key="status" field="status" header="Статус"
                        body={(rowData: NotificationList) =>
                            <NotificationListStatusOutput status={rowData.status}/>
                        }
        />
    }
];