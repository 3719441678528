import {DataTableFilterMeta} from "primereact/datatable";
import {FilterMatchMode, FilterOperator} from "primereact/api";

export const usersFilterInitialState: DataTableFilterMeta = {
    //'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'profile.id': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'profile.created': {
        operator: FilterOperator.AND,
        constraints: [
            {value: null, matchMode: FilterMatchMode.DATE_AFTER},
            {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
        ]
    },
    'profile.updated': {
        operator: FilterOperator.AND,
        constraints: [
            {value: null, matchMode: FilterMatchMode.DATE_AFTER},
            {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
        ]
    },
    'registered': {
        operator: FilterOperator.AND,
        constraints: [
            {value: null, matchMode: FilterMatchMode.DATE_AFTER},
            {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
        ]
    },
    'lastLogin': {
        operator: FilterOperator.AND,
        constraints: [
            {value: null, matchMode: FilterMatchMode.DATE_AFTER},
            {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
        ]
    },
    'username': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
    },
    'ipAddress': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
    },
    'profile.secondName': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
    },
    'profile.firstName': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
    },
    'profile.thirdName': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
    },
    'email': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
    },
    'mobilePhone': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
    },
    'status': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'profile.therapist': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'profile.insurancePeriodStartDate': {
        operator: FilterOperator.AND,
        constraints: [
            {value: null, matchMode: FilterMatchMode.DATE_AFTER},
            {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
        ]
    },
    'profile.insurancePeriodEndDate': {
        operator: FilterOperator.AND,
        constraints: [
            {value: null, matchMode: FilterMatchMode.DATE_AFTER},
            {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
        ]
    },
    'profile.insurancePolicyNumber': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'profile.insuranceProgram.id': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'profile.serviceProgram.id': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'roles': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.IN}]
    },
    'profile.organization.id': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    }
}