import React, {useState} from "react";
import UserDetails from "../components/UserDetails";
import {useAuth} from "../security/AuthProvider";
import {useParams} from "react-router-dom";
import {userProfileService} from "../index";
import useUserNotification from "../hooks/useUserNotification";
import {errorMessage} from "../helpers/axiosError";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import UserProfile from "../interfaces/UserProfile.interface";

export default function ProfilePage() {
    const {profileId} = useParams();    // Идентификатор профиля переданный в параметрах url
    const {userProfile, isAdmin, isUserManagement, isTherapist} = useAuth();    // Авторизованный пользователь
    const {showError} = useUserNotification();

    const [currentProfile, setCurrentProfile] =
        useState<UserProfile | undefined>(undefined);

    const reload = () => {
        /* Если текущий авторизованный пользователь привилегированный */
        if (isAdmin() || isUserManagement() || isTherapist()) {

            if (profileId) {
                /* Загрузка "чужого" пользовательского профиля */
                const id = Number.parseInt(profileId);
                userProfileService
                    .find(id)
                    .then((response: UserProfile) => {
                        setCurrentProfile(response);
                    })
                    .catch(reason => {
                        showError({
                            summary: "Ошибка загрузки профиля пользователя",
                            detail: errorMessage(reason)
                        });
                    });
            } else {
                /* Назначение собственного пользователя прошедшего авторизацию */
                setCurrentProfile(userProfile);
            }
        } else {
            /* Назначение собственного пользователя прошедшего авторизацию */
            setCurrentProfile(userProfile);
        }
    };

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [profileId]);

    return (
        <div className="container flex flex-row flex-wrap gap-3">
            {currentProfile && <UserDetails profile={currentProfile}/>}
        </div>
    );
}