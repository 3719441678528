import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import React, {useState} from "react";
import {confirmDialog} from "primereact/confirmdialog";
import {useMountEffect} from "primereact/hooks";
import ApiResponse from "../../../interfaces/response.interface";
import useUserNotification from "../../../hooks/useUserNotification";
import {errorMessage} from "../../../helpers/axiosError";
import ReloadButton from "../../../components/common/buttons/ReloadButton";
import {ticketColumns} from "./TicketColumns";
import {Column} from "primereact/column";
import EditButton from "../../../components/common/buttons/EditButton";
import TicketPropertiesDialog, {TicketDialogState} from "./TicketPropertiesDialog";
import FilterResetButton from "../../../components/common/buttons/FilterResetButton";
import DeleteButton from "../../../components/common/buttons/DeleteButton";
import CreateButton from "../../../components/common/buttons/CreateButton";
import ColumnToggle, {visibleOnly} from "../../../components/common/dataTable/ColumnToggle";
import {Ticket} from "../../../interfaces/ticket/Ticket";
import {ticketService} from "../../../index";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {TicketStatus} from "../../../interfaces/enum/TicketStatus";

export default function TicketPage() {
    const initTicket: Ticket = {
        id: null, created: undefined, updated: undefined,
        author: null, topic: undefined, priority: undefined, status: TicketStatus.CREATED,
    };

    const [busy, setBusy] =
        useState(false);

    const [tickets, setTickets] =
        useState<Ticket[]>([]);

    const [selected, setSelected] =
        useState<Ticket[] | undefined>(undefined);

    const [dialogState, setDialogState] =
        useState<TicketDialogState>({
            mode: "edit",
            entity: {...initTicket},
            visible: false
        });

    const [visibleFields, setVisibleFields] = useState<string[]>(
        ticketColumns.filter(column => !column.hidden).map(column => column.field)
    );

    const navigator = useNavigate();
    const {showError, showSuccess} = useUserNotification();


    const reload = () => {
        setBusy(true);

        ticketService.findAll()
            .then((response: ApiResponse<Ticket>) => {
                setTickets(response.content);
            })
            .catch(reason => {
                setTickets([]);
                showError({
                    summary: "Загрузка обращений",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
            });
    };

    useMountEffect(() => {
        reload();
    });

    const selectionIsEmpty: () => boolean = () => {
        return !selected || selected.length === 0;
    };

    const actionsTemplate = (rowData: Ticket) => {
        return <div className="flex flex-row gap-1">
            <Button onClick={() => navigator(`/tickets/${rowData.id}`)}
                    icon="pi pi-fw pi-eye"
                    type="button"
                    className="p-button-rounded"
                    tooltip="Перейти на страницу обращения"
            />
            <EditButton onClick={() =>
                setDialogState({
                    mode: "edit",
                    entity: {...rowData},
                    visible: true
                })
            }/>
        </div>
    }

    const deleteConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите удалить выбранные обращения?",
            header: "Удаление обращения",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                if (selected) {
                    setBusy(true);
                    ticketService.deleteAll(selected)
                        .then(() => {
                            showSuccess({
                                summary: "Удаление обращения",
                                detail: "Выбранные обращения успешно удалены."
                            });
                            reload();
                        })
                        .catch(reason => showError({
                            summary: "Удаление обращения",
                            detail: errorMessage(reason)
                        }))
                        .finally(() => setBusy(false));
                }
            }
        });
    };


    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-2 justify-content-between">
            <div className="flex flex-row gap-1">
                <ReloadButton onClick={reload} disabled={busy}/>
                <FilterResetButton onClick={() => {
                }}/>
                <CreateButton onClick={() =>
                    setDialogState({
                        mode: "create",
                        entity: {...initTicket},
                        visible: true
                    })}
                              tooltip="Зарегистрировать новое обращение"
                              disabled={busy}
                              className="ml-1"/>

                <DeleteButton onClick={deleteConfirmation}
                              tooltip="Удалить обращение"
                              disabled={busy || selectionIsEmpty()}/>
            </div>

            <ColumnToggle columns={ticketColumns}
                          visibleFields={visibleFields}
                          onColumnToggle={fields => setVisibleFields(fields)}/>
        </div>
    );


    const tableFooter = `Всего ${tickets.length} обращений`;


    return (
        <div className="container">
            <Card title="Обращения">
                <DataTable value={tickets} dataKey="id"
                           header={tableHeader} footer={tableFooter}
                           loading={busy}
                           paginator rows={10} rowsPerPageOptions={[10, 20, 50]}
                           filterDisplay="menu"
                           selectionMode="multiple" selection={selected!}
                           onSelectionChange={(e) => setSelected(e.value)}
                           size="small">

                    <Column selectionMode="multiple"
                            headerStyle={{width: '3rem'}}/>
                    <Column body={actionsTemplate}/>

                    {visibleOnly(visibleFields, ticketColumns)}

                </DataTable>
            </Card>

            <TicketPropertiesDialog dialogState={dialogState}
                                    onHide={() => setDialogState(prev => ({
                                        ...prev,
                                        ...{visible: false}
                                    }))}
                                    onCancel={() => setDialogState(prev => ({
                                        ...prev,
                                        ...{visible: false}
                                    }))}
                                    onSave={() => {
                                        setDialogState(prev => ({
                                            ...prev,
                                            ...{visible: false}
                                        }));
                                        reload();
                                    }}
            />
        </div>
    );
}