import Organization from "../interfaces/Organization";

export default function OrganizationOutput({organization} : {organization: Organization | undefined | null}) {
    if (organization) {
        if (organization.shortTitle) {
            return <>{organization.shortTitle}</>
        } else if (organization.fullTitle){
            return <>{organization.fullTitle}</>
        } else if (organization.tin) {
            return <>{organization.tin}</>
        }
    }
    return <></>;
}