import {Dialog} from "primereact/dialog";
import {TabPanel, TabView} from "primereact/tabview";
import {InputText} from "primereact/inputtext";
import React, {useRef, useState} from "react";
import useUserNotification from "../../hooks/useUserNotification";
import User from "../../interfaces/User.interface";
import InputPhone from "../../components/common/InputPhone";
import {fullName} from "../../helpers/functions";
import {EntityDialogState, Mode} from "../../components/common/dialog/EntityDialogState";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import CancelButton from "../../components/common/buttons/CancelButton";
import SaveButton from "../../components/common/buttons/SaveButton";
import {userService} from "../../index";
import {errorMessage} from "../../helpers/axiosError";
import {BlockUI} from "primereact/blockui";
import {Calendar} from "primereact/calendar";
import {OverlayPanel} from "primereact/overlaypanel";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {isoToJs, jsToIso} from "../../helpers/dateTime";
import {useAuth} from "../../security/AuthProvider";
import InsuranceProgramDropdown from "../../components/InsuranceProgramDropdown";
import ServiceProgramDropdown from "../../components/ServiceProgramDropdown";
import DispatcherConsoleDropdown from "../../components/DispatcherConsoleDropdown";
import OrganizationDropdown from "../../components/OrganizationDropdown";
import TherapistDropdown from "../../components/TherapistDropdown";
import UserStatusDropdown from "../../components/UserStatusDropdown";
import Notes from "../../components/common/Notes";
import ContactStatusDropdown from "../../components/common/ContactStatusDropdown";
import RoleMultiSelect from "../../components/common/RoleMultiSelect";
import InsurancePolicyLink from "../../components/InsurancePolicyLink";

export interface UserDialogState extends EntityDialogState<User> {
}

export interface UserDialogProps {
    user: User;
    mode: Mode;
    visible: boolean;

    onHide(): void;

    onCancel?(): void;

    onSave?(): void
}

export default function UserPropertiesDialog(props: UserDialogProps) {

    const [busy, setBusy] = useState<boolean>(false);

    const [editedUser, setEditedUser] =
        useState<User>(props.user);

    /*const [mediaAssetTemplate, setMediaAssetTemplate] =
        useState<string | undefined | null>(undefined);*/

    const {showError, showSuccess} = useUserNotification();
    const {isAdmin} = useAuth();

    const passwordResetOverlayPanel = useRef<any>(null);

    const reload = () => setEditedUser(props.user);

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [props.user]);

    const saveUser = () => {
        if (props.mode === "create") {
            setBusy(true);
            userService.create(editedUser)
                .then(() => {
                    showSuccess({
                        summary: "Регистрация пользователя",
                        detail: `Пользователь ${fullName(editedUser.profile)} успешно зарегистрирован в системе.`
                    });
                    if (props.onSave) props.onSave();
                })
                .catch(reason => showError({
                    summary: "Регистрация пользователя",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));

        } else if (props.mode === "edit" && editedUser.id) {
            setBusy(true);
            userService.update(editedUser.id, editedUser)
                .then(() => {
                    showSuccess({
                        summary: "Обновление данных пользователя",
                        detail: `Регистрационные данные пользователя ${fullName(editedUser.profile)} успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Обновление данных пользователя",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    };

    const footer = (
        <div>
            <SaveButton onClick={() => saveUser()} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );

    const title = props.mode === "create" ?
        "Регистрация нового пользователя" :
        "Редактирование карточки пользователя " + fullName(editedUser.profile);

    return (
        <BlockUI blocked={busy}>
            <OverlayPanel ref={passwordResetOverlayPanel}>
                <div className="flex flex-row gap-2 m-0 align-items-center">
                    <label htmlFor="password" className="font-bold">Новый пароль</label>
                    <Password id="password" value={editedUser.password}
                              onChange={(e) => {
                                  setEditedUser(prev => ({
                                      ...prev,
                                      ...{password: e.target.value}
                                  }))
                              }}/>
                    <Button type="button"
                            label="Выполнить"
                            onClick={() => {
                                if (editedUser.id && editedUser.password && editedUser.password.length > 0) {
                                    passwordResetOverlayPanel.current.hide();
                                    userService.resetPassword(editedUser.id, editedUser.password)
                                        .then(() => showSuccess({
                                            summary: "Сброс пароля",
                                            detail: `Пароль пользователя ${fullName(editedUser.profile)} успешно сброшен на новый.`
                                        }))
                                        .catch(reason => showError({
                                            summary: "Сброс пароля",
                                            detail: errorMessage(reason)
                                        }));
                                }
                            }}/>
                </div>
            </OverlayPanel>

            <Dialog header={title}
                    visible={props.visible}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-user-properties-dialog">
                <TabView>
                    <TabPanel header="Страхование">
                        <div className="flex flex-column gap-4">

                            <div className="flex flex-row flex-wrap gap-2">
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="profile.insurer" className="font-bold">Страхователь</label>
                                    <InputText id="profile.insurer" value={editedUser.profile.insurer || ''}
                                               onChange={(e) => {
                                                   setEditedUser(prev => ({
                                                       ...prev,
                                                       profile: {
                                                           ...prev.profile,
                                                           insurer: e.target.value
                                                       }
                                                   }))
                                               }}/>
                                </div>
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="profile.insurancePolicyNumber"
                                           className="font-bold">Номер страхового полиса</label>
                                    <InputText id="profile.insurancePolicyNumber"
                                               value={editedUser.profile.insurancePolicyNumber || ''}
                                               onChange={(e) => {
                                                   setEditedUser(prev => ({
                                                       ...prev,
                                                       profile: {
                                                           ...prev.profile,
                                                           insurancePolicyNumber: e.target.value
                                                       }
                                                   }))
                                               }}/>
                                </div>
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="profile.insuranceAgreementNumber"
                                           className="font-bold">Номер договора со страхователем</label>
                                    <InputText id="profile.insuranceAgreementNumber"
                                               value={editedUser.profile.insuranceAgreementNumber || ''}
                                               onChange={(e) => {
                                                   setEditedUser(prev => ({
                                                       ...prev,
                                                       profile: {
                                                           ...prev.profile,
                                                           insuranceAgreementNumber: e.target.value
                                                       }
                                                   }))
                                               }}/>
                                </div>
                            </div>

                            <div className="flex flex-row flex-wrap gap-2">
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="insuranceProgram"
                                           className="font-bold">Программа страхования</label>
                                    <InsuranceProgramDropdown id="insuranceProgram"
                                                              value={editedUser.profile.insuranceProgram}
                                                              onChange={n => setEditedUser(
                                                                  prev => ({
                                                                      ...prev,
                                                                      profile: {
                                                                          ...prev.profile,
                                                                          insuranceProgram: n
                                                                      }
                                                                  })
                                                              )}/>
                                </div>
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="serviceProgram" className="font-bold">План обслуживания</label>
                                    <ServiceProgramDropdown id="serviceProgram"
                                                            value={editedUser.profile.serviceProgram}
                                                            onChange={n => setEditedUser(
                                                                prev => ({
                                                                    ...prev,
                                                                    profile: {
                                                                        ...prev.profile,
                                                                        serviceProgram: n
                                                                    }
                                                                })
                                                            )}/>
                                </div>
                            </div>

                            <div className="flex flex-row flex-wrap gap-2">
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="insurancePeriodStartDate"
                                           className="font-bold">Дата прикрепления</label>
                                    <Calendar id="insurancePeriodStartDate"
                                              placeholder="дд.мм.гггг"
                                              dateFormat="dd.mm.yy"
                                              value={isoToJs(editedUser.profile.insurancePeriodStartDate)}
                                              onChange={(e) =>
                                                  setEditedUser(prev => ({
                                                      ...prev,
                                                      profile: {
                                                          ...prev.profile,
                                                          insurancePeriodStartDate: jsToIso(e.value)
                                                      }
                                                  }))}/>
                                </div>
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="insurancePeriodEndDate"
                                           className="font-bold">Дата открепления</label>
                                    <Calendar id="insurancePeriodEndDate"
                                              placeholder="дд.мм.гггг"
                                              dateFormat="dd.mm.yy"
                                              value={isoToJs(editedUser.profile.insurancePeriodEndDate)}
                                              onChange={(e) => setEditedUser(prev => ({
                                                  ...prev,
                                                  profile: {
                                                      ...prev.profile,
                                                      insurancePeriodEndDate: jsToIso(e.value)
                                                  }
                                              }))}/>
                                </div>
                            </div>

                            <div className="flex flex-row flex-wrap gap-2">
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="therapist" className="font-bold">Врач-куратор</label>
                                    <TherapistDropdown id="therapist"
                                                       value={editedUser.profile.therapist}
                                                       onChange={n => setEditedUser(
                                                           prev => ({
                                                               ...prev,
                                                               profile: {
                                                                   ...prev.profile,
                                                                   therapist: n
                                                               }
                                                           })
                                                       )}/>
                                </div>
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="dispatcherConsole" className="font-bold">Диспетчерский
                                        пульт</label>
                                    <DispatcherConsoleDropdown id="dispatcherConsole"
                                                               value={editedUser.profile.dispatcherConsole}
                                                               onChange={e => setEditedUser(
                                                                   prev => ({
                                                                       ...prev,
                                                                       profile: {
                                                                           ...prev.profile,
                                                                           dispatcherConsole: e
                                                                       }
                                                                   })
                                                               )}/>
                                </div>
                            </div>

                        </div>
                    </TabPanel>


                    <TabPanel header="Основные">
                        <div className="flex flex-column gap-4">
                            <div className="flex flex-row gap-2 justify-content-between">

                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="username" className="font-bold">Имя пользователя</label>
                                    <div className="flex flex-row flex-nowrap gap-2">
                                        <InputText id="username" value={editedUser.username || ''}
                                                   onChange={(e) => {
                                                       setEditedUser(prev => ({
                                                           ...prev,
                                                           ...{username: e.target.value}
                                                       }))
                                                   }}
                                                   className="flex-1"
                                        />
                                        {props.mode === "edit" &&
                                            <Button id="showResetOverpanelBtn"
                                                    label="Задать пароль"
                                                    onClick={(e) => passwordResetOverlayPanel.current.toggle(e)}
                                                    className="w-fit"
                                            />
                                        }
                                    </div>
                                    <small>Имя используется для авторизации пользователя в системе.</small>
                                </div>

                                {props.mode === "create" &&
                                    <div className="flex flex-column gap-2 m-0 flex-auto">
                                        <label htmlFor="password" className="font-bold">Пароль</label>
                                        <Password id="password" value={editedUser.password}
                                                  onChange={(e) => {
                                                      setEditedUser(prev => ({
                                                          ...prev,
                                                          ...{password: e.target.value}
                                                      }))
                                                  }}
                                        />
                                    </div>
                                }
                            </div>

                            <div className="flex flex-row flex-wrap gap-2 justify-content-between">
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="profile.title" className="font-bold">Обращение</label>
                                    <InputText id="profile.title" value={editedUser.profile.title || ''}
                                               onChange={(e) => {
                                                   setEditedUser(prev => ({
                                                       ...prev,
                                                       profile: {
                                                           ...prev.profile,
                                                           title: e.target.value
                                                       }
                                                   }))
                                               }}/>
                                </div>
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="profile.secondName" className="font-bold">Фамилия</label>
                                    <InputText id="profile.secondName" value={editedUser.profile.secondName || ''}
                                               onChange={(e) => {
                                                   setEditedUser(prev => ({
                                                       ...prev,
                                                       profile: {
                                                           ...prev.profile,
                                                           secondName: e.target.value
                                                       }
                                                   }))
                                               }}/>
                                </div>
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="profile.firstName" className="font-bold">Имя</label>
                                    <InputText id="profile.firstName" value={editedUser.profile.firstName || ''}
                                               onChange={(e) => {
                                                   setEditedUser(prev => ({
                                                       ...prev,
                                                       profile: {
                                                           ...prev.profile,
                                                           firstName: e.target.value
                                                       }
                                                   }))
                                               }}/>
                                </div>
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="profile.thirdName" className="font-bold">Отчество</label>
                                    <InputText id="profile.thirdName" value={editedUser.profile.thirdName || ''}
                                               onChange={(e) => {
                                                   setEditedUser(prev => ({
                                                       ...prev,
                                                       profile: {
                                                           ...prev.profile,
                                                           thirdName: e.target.value
                                                       }
                                                   }))
                                               }}/>
                                </div>
                            </div>

                            <div className="flex flex-column gap-2 m-0 flex-auto">
                                <label htmlFor="dateOfBirth" className="font-bold">Дата рождения</label>
                                <Calendar id="dateOfBirth"
                                          placeholder="дд.мм.гггг"
                                          dateFormat="dd.mm.yy"
                                          value={isoToJs(editedUser.profile.dateOfBirth)}
                                          onChange={(e) => setEditedUser(prev => ({
                                              ...prev,
                                              profile: {
                                                  ...prev.profile,
                                                  dateOfBirth: jsToIso(e.value)
                                              }
                                          }))}/>
                            </div>

                            <div className="flex flex-column gap-2 m-0 flex-auto">
                                <label htmlFor="organization" className="font-bold">Организация</label>
                                <OrganizationDropdown id="organization"
                                                      value={editedUser.profile.organization}
                                                      onChange={n => setEditedUser(
                                                          prev => ({
                                                              ...prev,
                                                              profile: {
                                                                  ...prev.profile,
                                                                  organization: n
                                                              }
                                                          })
                                                      )}/>
                            </div>

                            <div className="flex flex-column gap-2 m-0 flex-auto">
                                <label htmlFor="notes" className="font-bold">Примечания</label>
                                <Notes id="notes"
                                       value={editedUser.notes || ""}
                                       onChange={e =>
                                           setEditedUser(
                                               prev => ({
                                                   ...prev,
                                                   notes: e.target.value
                                               }))}
                                />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel header="Контакты">
                        <div className="flex flex-column gap-4">
                            <div className="flex flex-row flex-wrap gap-2">
                                <div className="flex flex-column gap-2 md:flex-1">
                                    <label htmlFor="email" className="font-bold">Email</label>
                                    <InputText id="email"
                                               value={editedUser.email || ''}
                                               className="w-full"
                                               onChange={(e) => {
                                                   setEditedUser(prev => ({
                                                       ...prev,
                                                       ...{email: e.target.value}
                                                   }))
                                               }}/>
                                    <small>Электронная почта используется для авторизации пользователя в системе и
                                        отправки
                                        уведомлений.</small>
                                </div>
                                <div className="flex flex-column gap-2 md:flex-1">
                                    <label htmlFor="emailStatus" className="font-bold">Статус Email</label>
                                    <ContactStatusDropdown id="emailStatus"
                                                           status={editedUser.emailStatus}
                                                           onStatusSelect={status =>
                                                               setEditedUser(prev => ({
                                                                   ...prev,
                                                                   emailStatus: status
                                                               }))}/>
                                    <small>Если статус отличен от активного, автоматически отправляемые уведомления не
                                        направляются.</small>
                                </div>
                            </div>
                            <div className="flex flex-row flex-wrap gap-2">
                                <div className="flex flex-column gap-2 md:flex-1">
                                    <label htmlFor="mobile" className="font-bold">Мобильный телефон</label>
                                    <InputPhone id="mobile" value={editedUser.mobilePhone?.number || ''}
                                                onChange={(e) =>
                                                    setEditedUser((prev: User) => ({
                                                        ...prev,
                                                        ...{
                                                            mobilePhone: {
                                                                ...prev.mobilePhone,
                                                                ...{
                                                                    number: e.target.value ? e.target.value : undefined,
                                                                    country: "+7"
                                                                }
                                                            }
                                                        }
                                                    }))}/>
                                    <small>Мобильный телефон используется для авторизации пользователя в системе и
                                        отправки уведомлений.</small>
                                </div>
                                <div className="flex flex-column gap-2 md:flex-1">
                                    <label htmlFor="mobilePhoneStatus" className="font-bold">
                                        Статус мобильного телефона
                                    </label>
                                    <ContactStatusDropdown id="mobilePhoneStatus"
                                                           status={editedUser.mobilePhone?.status}
                                                           onStatusSelect={status =>
                                                               setEditedUser(prev => ({
                                                                   ...prev,
                                                                   ...{
                                                                       mobilePhone: {
                                                                           ...prev.mobilePhone,
                                                                           ...{
                                                                               status: status,
                                                                               number: prev.mobilePhone?.number,
                                                                               country: prev.mobilePhone?.country
                                                                           }
                                                                       }
                                                                   }
                                                               }))}/>
                                </div>
                            </div>
                            <div className="flex flex-row flex-wrap gap-2">
                                <div className="flex flex-column gap-2 md:flex-1">
                                    <label htmlFor="phone" className="font-bold">Телефон</label>
                                    <InputPhone id="phone" value={editedUser.phone?.number || ''}
                                                onChange={(e) =>
                                                    setEditedUser((prev: User) => ({
                                                            ...prev,
                                                            ...{
                                                                phone: {
                                                                    ...prev.phone,
                                                                    ...{number: e.target.value ? e.target.value : undefined}
                                                                }
                                                            }
                                                        })
                                                    )}/>
                                </div>
                                <div className="flex flex-column gap-2 md:flex-1">
                                    <label htmlFor="phoneStatus" className="font-bold">
                                        Статус телефона
                                    </label>
                                    <ContactStatusDropdown id="phoneStatus"
                                                           status={editedUser.phone?.status}
                                                           onStatusSelect={status =>
                                                               setEditedUser(prev => ({
                                                                   ...prev,
                                                                   ...{
                                                                       phone: {
                                                                           ...prev.phone,
                                                                           ...{
                                                                               number: prev.phone?.number,
                                                                               status: status,
                                                                           }
                                                                       }
                                                                   }
                                                               }))}/>
                                </div>
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel header="Безопасность">
                        <div className="flex flex-column gap-4">

                            {isAdmin() &&
                                <div className="flex flex-column gap-2 m-0 flex-auto">
                                    <label htmlFor="roles" className="font-bold">Роли</label>
                                    <RoleMultiSelect id="roles"
                                                     value={editedUser.roles}
                                                     onChange={roles =>
                                                         //console.log(e.value)
                                                         setEditedUser(prevUser => ({
                                                             ...prevUser,
                                                             roles: roles
                                                         }))
                                                     }/>
                                </div>
                            }
                            <div className="flex flex-column gap-2 m-0 flex-auto">
                                <label htmlFor="status" className="font-bold">Статус</label>
                                <UserStatusDropdown id="status"
                                                    value={editedUser.status}
                                                    onChange={e => setEditedUser(prev => ({
                                                        ...prev,
                                                        status: e
                                                    }))}/>
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel header={"Документы"}>
                        {/*<div className="flex flex-column gap-4">
                            <div className="flex flex-row flex-wrap gap-2 align-items-end">
                                <div className="flex flex-column gap-2 flex-auto">
                                    <label htmlFor={""}
                                           className={"font-bold"}>Заполненный шаблон документа</label>
                                    <MediaAssetTypeDropdown value={mediaAssetTemplate}
                                                            onChoose={
                                                                (type: string | null) => setMediaAssetTemplate(type)
                                                            }/>
                                </div>
                                <Button type={"button"}
                                        label={"Скачать"}
                                        onClick={(e) => {
                                            if (editedUser.profile.id) {
                                                documentTemplateService.substituteInsurancePolicyDownloadByProfile(editedUser.profile.id).then().catch(reason => {
                                                    console.error(reason);
                                                    showError({
                                                        summary: "Загрузка страхового полиса",
                                                        detail: "Системная ошибка, обратитесь пожалуйста в техническую поддержку. "
                                                            + errorMessage(reason)
                                                    });
                                                });
                                            }
                                        }}
                                        disabled={!editedUser.profile.insurancePolicyNumber}
                                />
                            </div>
                        </div>*/}
                        <div className="flex flex-column gap-4">
                            <div className="flex flex-row flex-wrap gap-2 align-items-end">
                                <div className="flex flex-column gap-2 flex-auto">
                                    <label htmlFor={""}
                                           className={"font-bold"}>Страховой полис</label>
                                    <InsurancePolicyLink profile={editedUser.profile}/>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </Dialog>
        </BlockUI>
    );
};