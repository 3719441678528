import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import React, {useState} from "react";
import useUserNotification from "../hooks/useUserNotification";
import {insuranceProgramService} from "../index";
import ApiResponse from "../interfaces/response.interface";
import InsuranceProgram from "../interfaces/InsuranceProgram.interface";
import {errorMessage} from "../helpers/axiosError";
import {useMountEffect} from "primereact/hooks";

interface InsuranceProgramDropdownProps {
    id?: string | undefined;
    value: number | undefined;

    onChange(id: number | undefined): void;
}

export default function InsuranceProgramDropdown(props: InsuranceProgramDropdownProps) {

    const [busy, setBusy] =
        useState<boolean>(false);

    const [insurancePrograms, setInsurancePrograms] =
        useState<InsuranceProgram[]>([]);

    const {showError} = useUserNotification();

    const reload = () => {
        setBusy(true);

        insuranceProgramService.findAll()
            .then((response: ApiResponse<InsuranceProgram>) => {
                setInsurancePrograms(response.content);
            })
            .catch(reason => {
                setInsurancePrograms([]);
                showError({
                    summary: "Загрузка программ страхования",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
            });
    };

    useMountEffect(() => {
        reload();
    });


    return (
        <Dropdown id={props.id}
                  value={props.value}
                  loading={busy}
                  options={insurancePrograms}
                  optionValue="id"
                  optionLabel="name"
                  showClear
                  filter
                  filterBy="name"
                  onChange={(e: DropdownChangeEvent) =>
                      props.onChange(e.target.value ? parseInt(e.target.value) : undefined)
                  }
                  emptyMessage="Нет зарегистрированных программ страхования"
                  placeholder="Выберите программу страхования"/>
    );
}