import {Column} from "primereact/column";
import React from "react";
import ColumnMeta from "../../../interfaces/ColumnMeta";
import NotificationList from "../../../interfaces/notification/NotificationList";
import {
    dateTimeRangeFilterTemplate,
    numberFilterTemplate,
    textFilterTemplate
} from "../../common/dataTable/filterTemplates";
import {formattedDateTime} from "../../common/dataTable/bodyTemplates";
import NotificationRecipient from "../../../interfaces/notification/NotificationRecipient";
import NotificationRecipientStatusOutput from "./NotificationRecipientStatusOutput";
import NotificationRecipientCommunicationTypeOutput from "./NotificationRecipientCommunicationTypeOutput";
import NotificationRecipientSpecial from "../../../interfaces/notification/NotificationRecipientSpecial";
import NotificationListOutput from "../list/NotificationListOutput";
import {Tag} from "primereact/tag";

export function initialNotificationRecipientColumns() {
    return notificationRecipientColumns
        .filter(column => !column.hidden)
        .map(column => column.field)
}

export const notificationRecipientColumns: ColumnMeta[] = [
    {
        field: 'id',
        header: 'УН',
        hidden: true,
        column: <Column key="id" field="id" header="УН" sortable dataType="numeric"
                        filter filterElement={numberFilterTemplate}
                        style={{width: '3rem'}}
        />
    },
    {
        field: 'created',
        header: 'Создан',
        hidden: true,
        column: <Column key="created" field="created" header="Создан" sortable
                        dataType="text" body={(rowData: NotificationList) => formattedDateTime(rowData.created)}
                        filter filterElement={dateTimeRangeFilterTemplate}
        />
    },
    {
        field: 'updated',
        header: 'Обновлен',
        hidden: true,
        column: <Column key="updated" field="updated" header="Обновлен" sortable
                        dataType="text" body={(rowData: NotificationList) => formattedDateTime(rowData.updated)}
                        filter filterElement={dateTimeRangeFilterTemplate}
        />
    },
    {
        field: 'address',
        header: 'Адрес',
        hidden: false,
        column: <Column key="address" field="address" header="Адрес" sortable
                        dataType="text" body={(rowData: NotificationRecipientSpecial) => <>{rowData.address}</>}
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "communicationType",
        header: "Канал",
        hidden: true,
        column: <Column key="communicationType" field="communicationType" header="Канал"
                        body={(rowData: NotificationRecipient) =>
                            <NotificationRecipientCommunicationTypeOutput status={rowData.communicationType}/>
                        }
        />
    },
    {
        field: 'list',
        header: 'Списки',
        hidden: false,
        column: <Column key="list" field="list" header="Списки" sortable
                        dataType="numeric" body={(rowData: NotificationRecipientSpecial) =>
            <div className="flex flex-row flex-wrap gap-1">{
                rowData.list.map((lid, index) => {
                    return <Tag key={index}><NotificationListOutput listId={lid}/></Tag>;
                })
            }</div>
        }/>
    },
    {
        field: "status",
        header: "Статус",
        hidden: false,
        column: <Column key="status" field="status" header="Статус"
                        body={(rowData: NotificationRecipient) =>
                            <NotificationRecipientStatusOutput status={rowData.status}/>
                        }
        />
    }
];