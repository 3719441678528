export enum MediaAssetTypeEnumeration {
    LOGO = "LOGO",
    AVATAR = "AVATAR",
    NDA = "NDA",                                                // Согласие на обработку персональных данных
    INSURANCE_POLICE = "INSURANCE_POLICE",                      // Страховой полис
    INSURANCE_POLICE_TEMPLATE = "INSURANCE_POLICE_TEMPLATE",    // Шаблон страхового полиса
    INSURANCE_PROGRAM = "INSURANCE_PROGRAM",                    // Программа страхования
    SERVICE_PROGRAM = "SERVICE_PROGRAM",                        // Программа обслуживания
    AGREEMENT = "AGREEMENT",                                    // Договор
    GUARANTEE_NOTES = "GUARANTEE_NOTES",                        // Гарантийное письмо
    UNCLASSIFIED = "UNCLASSIFIED",                              // Неклассифицированные типы документов (загружаются пользователем)
    TEMPORARY = "TEMPORARY"                                     // Временный файл
}