import {API_TICKET_URL} from "../config";
import {BaseDataService} from "./BaseDataService";
import {Ticket} from "../interfaces/ticket/Ticket";
import axios, {AxiosResponse} from "axios";
import TicketCreateRequest from "../interfaces/ticket/TicketCreateRequest";
import {TicketOverview} from "../interfaces/ticket/TicketOverview";
import {TicketStatus} from "../interfaces/enum/TicketStatus";

export default class TicketService extends BaseDataService<Ticket> {

    constructor() {
        super(API_TICKET_URL);
    }

    async registration(request: TicketCreateRequest): Promise<AxiosResponse<Ticket>> {
        if (!request.taxonomy)
            throw new Error("Taxonomy doesn't exist");

        if (!request.text)
            throw new Error("Text doesn't exist");

        const formData: FormData = new FormData();
        formData.append("taxonomy", request.taxonomy.toString());
        if (request.topic) formData.append("topic", request.topic);
        formData.append("text", request.text);
        if (request.files) {
            [...request.files].forEach((file, i) => {
                formData.append(`files`, file, file.name);
            });
        }

        return await axios.post<Ticket>(
            this.url + "/registration", formData);
    }

    async overview(): Promise<AxiosResponse<TicketOverview[]>> {
        return await axios.get<TicketOverview[]>(this.url + "/overview");
    }

    async updateStatus(ticket: Ticket, status: TicketStatus): Promise<AxiosResponse<any>> {
        return await axios.patch(`${this.url}/status/${ticket.id}/${status}`);
    }
};