import TemporalUpdatableEntity from "./TemporalUpdatableEntity";

export interface Settings extends TemporalUpdatableEntity {
    smtpEnabled: boolean;
    registrationEnabled: boolean;
    nda: number | undefined | null;
    emailVerificationCodeNotificationTemplate: number | null;
    passwordChangedNotificationTemplate: number | null;
    passwordRestoreNotificationTemplate: number | null;
    insurancePolicyTemplate: number | null;
    insurancePolicyFilename: string | undefined | null;
    ticketServiceEnabled: boolean;
    ticketServiceDescription: string | undefined | null;
    ticketServiceTopicTaxonomy: number | null;
    ticketCreatedAuthorNotificationTemplate: number | null;
    ticketCreatedTherapistNotificationTemplate: number | null;
    ticketMessageCreatedAuthorNotificationTemplate: number | null;
    ticketMessageCreatedTherapistNotificationTemplate: number | null;
    unattendedTicketUpdatePeriod: number | null;
    userImportDateFormat: string;
    maxFileSize: number | null;
    maxFilesCount: number | null;
    maxFilesSize: number | null;
}

export const initialSettings: Settings = {
    id: null,
    created: undefined,
    updated: undefined,
    smtpEnabled: true,
    registrationEnabled: true,
    nda: undefined,
    emailVerificationCodeNotificationTemplate: null,
    passwordChangedNotificationTemplate: null,
    passwordRestoreNotificationTemplate: null,
    insurancePolicyTemplate: null,
    insurancePolicyFilename: null,
    ticketServiceEnabled: false,
    ticketServiceDescription: undefined,
    ticketServiceTopicTaxonomy: null,
    ticketCreatedAuthorNotificationTemplate: null,
    ticketCreatedTherapistNotificationTemplate: null,
    ticketMessageCreatedAuthorNotificationTemplate: null,
    ticketMessageCreatedTherapistNotificationTemplate: null,
    unattendedTicketUpdatePeriod: 7,
    userImportDateFormat: '',
    maxFileSize: 20971520,
    maxFilesCount: 10,
    maxFilesSize: 134217728
};