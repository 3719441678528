import {API_MAINTENANCE_URL} from "../config";
import axios from "axios";

export default class MaintenanceService {
    url: string = "";

    constructor() {
        this.url = API_MAINTENANCE_URL;
    }

    async processExpiredTokens() {
        const request = this.url + "/process/expiredTokens";
        const response = await axios.get(request);
        return response.data;
    }

    async processTemporaryMediaAsset() {
        const request = this.url + "/process/temporaryMediaAsset";
        const response = await axios.get(request);
        return response.data;
    }

    async processUnattendedTickets() {
        const request = this.url + "/process/unattendedTickets";
        const response = await axios.get(request);
        return response.data;
    }
}

export const maintenanceService: MaintenanceService = new MaintenanceService();