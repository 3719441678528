import {ClassifierTitle} from "../ClassifierTitle";
import {MediaAssetTypeEnumeration} from "../enum/MediaAssetTypeEnumeration";

export const MediaAssetTypeTitles: ClassifierTitle[] = [
    {code: MediaAssetTypeEnumeration.LOGO, title: "Логотип организации"},
    {code: MediaAssetTypeEnumeration.AVATAR, title: "Аватар пользователя"},
    {code: MediaAssetTypeEnumeration.NDA, title: "Согласие на обработку персональных данных"},
    {code: MediaAssetTypeEnumeration.INSURANCE_POLICE, title: "Страховой полис"},
    {code: MediaAssetTypeEnumeration.INSURANCE_POLICE_TEMPLATE, title: "Шаблон страхового полиса"},
    {code: MediaAssetTypeEnumeration.INSURANCE_PROGRAM, title: "Программа страхования"},
    {code: MediaAssetTypeEnumeration.SERVICE_PROGRAM, title: "План обслуживания"},
    {code: MediaAssetTypeEnumeration.AGREEMENT, title: "Договор"},
    {code: MediaAssetTypeEnumeration.GUARANTEE_NOTES, title: "Гарантийное письмо"},
    {code: MediaAssetTypeEnumeration.UNCLASSIFIED, title: "Неклассифицированные типы документов (загружаются пользователем)"},
    {code: MediaAssetTypeEnumeration.TEMPORARY, title: "Временный файл"}
];