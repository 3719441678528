import React from 'react';
import ReactDOM from 'react-dom/client';
import axios, {AxiosError} from "axios";
import {PrimeReactProvider} from "primereact/api";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./security/AuthProvider";
import Maintenance from "./components/Maintenance";
import UserNotificationProvider from './providers/UserNotificationProvider';
import AppSettingsProvider from "./providers/AppSettingsProvider";
import './index.css';
import RoleService from "./service/RoleService";
import UserService from "./service/UserService";
import AuthService from "./service/AuthService";
import DispatcherConsoleService from "./service/DispatcherConsoleService";
import InsuranceProgramService from "./service/InsuranceProgramService";
import UserProfileService from "./service/UserProfileService";
import MediaAssetService from "./service/MediaAssetService";
import OrganizationService from "./service/OrganizationService";
import ServiceProgramService from "./service/ServiceProgramService";
import MediaAssetTypeService from "./service/MediaAssetTypeService";
import SettingsService from "./service/SettingsService";
import DocumentTemplateService from "./service/DocumentTemplateService";
import TherapistService from "./service/TherapistService";
import UserDataExportService from "./service/exchange/excel/UserDataExportService";
import UserDataImportService from "./service/exchange/excel/UserDataImportService";
import LogService from "./service/LogService";
import TicketMessageService from "./service/TicketMessageService";
import TicketService from "./service/TicketService";
import InsurerService from "./service/InsurerService";
import TaxonomyService from "./service/TaxonomyService";
import NotificationListService from "./service/notification/NotificationListService";
import NotificationRecipientSpecialService from "./service/notification/NotificationRecipientSpecialService";
import MailService from "./service/MailService";
import MessageTemplateService from "./service/MessageTemplateService";
import {LS_TOKEN_KEY} from "./config";


export const roleService: RoleService = new RoleService();
export const userService: UserService = new UserService();
export const userProfileService: UserProfileService = new UserProfileService();
export const therapistService: TherapistService = new TherapistService();
export const authService: AuthService = new AuthService();
export const dispatcherConsoleService: DispatcherConsoleService = new DispatcherConsoleService();
export const insuranceProgramService: InsuranceProgramService = new InsuranceProgramService();
export const serviceProgramService: ServiceProgramService = new ServiceProgramService();
export const mediaAssetService: MediaAssetService = new MediaAssetService();
export const mediaAssetTypeService: MediaAssetTypeService = new MediaAssetTypeService();
export const settingsService: SettingsService = new SettingsService();
// export const documentService: DocumentService = new DocumentService();
export const documentTemplateService: DocumentTemplateService = new DocumentTemplateService();
export const organizationService: OrganizationService = new OrganizationService();
export const taxonomyService: TaxonomyService = new TaxonomyService();
export const userDataExchangeService: UserDataExportService = new UserDataExportService();
export const userDataImportService: UserDataImportService = new UserDataImportService();
export const logService: LogService = new LogService();
export const ticketService: TicketService = new TicketService();
export const ticketMessageService: TicketMessageService = new TicketMessageService();
export const insurerService: InsurerService = new InsurerService();
export const notificationListService: NotificationListService = new NotificationListService();
// export const notificationRecipientService: NotificationRecipientService = new NotificationRecipientService();
export const notificationRecipientSpecialService: NotificationRecipientSpecialService = new NotificationRecipientSpecialService();
export const mailService: MailService = new MailService();
export const messageTemplateService: MessageTemplateService = new MessageTemplateService();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

export function setupAxios() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem(LS_TOKEN_KEY);
            //console.log("Axios.interceptors.request.use", token);
            if (token) config.headers['Authorization'] = `Bearer ${token}`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    /*axios.interceptors.response.use((originalResponse) => {

    });*/
}

setupAxios();


const config = {
    ripple: true,
    unstyled: false,
    locale: 'ru'
};

Promise.all([]).then(() => {

    root.render(
        <React.StrictMode>
            <PrimeReactProvider value={config}>
                <BrowserRouter>
                    <UserNotificationProvider>
                        <AuthProvider>
                            <AppSettingsProvider>
                                <App/>
                            </AppSettingsProvider>
                        </AuthProvider>
                    </UserNotificationProvider>
                </BrowserRouter>
            </PrimeReactProvider>
        </React.StrictMode>
    );
})
    .catch((reason: AxiosError) => {
        console.error(reason);

        if (reason.code === 'ERR_NETWORK' || reason.code === 'ERR_BAD_REQUEST')
            root.render(
                <React.StrictMode>
                    <Maintenance/>
                </React.StrictMode>
            );
    });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
