import React, {useState} from "react";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {BlockUI} from "primereact/blockui";
import {Mode} from "node:fs";
import useUserNotification from "../../../../hooks/useUserNotification";
import {errorMessage} from "../../../../helpers/axiosError";
import CancelButton from "../../../../components/common/buttons/CancelButton";
import TaxonomySelect from "../../../../components/common/taxonomy/TaxonomySelect";
import Notes from "../../../../components/common/Notes";
import {EntityDialogState} from "../../../../components/common/dialog/EntityDialogState";
import SaveButton from "../../../../components/common/buttons/SaveButton";
import MessageTemplate from "../../../../interfaces/notification/MessageTemplate";
import {messageTemplateService} from "../../../../index";
import {InputTextarea} from "primereact/inputtextarea";
import MessageTemplateModeDropdown from "../../../../components/common/MessageTemplateModeDropdown";

export interface MessageTemplateDialogState
    extends EntityDialogState<MessageTemplate> {
}

interface MessageTemplateDialogProps {
    messageTemplate: MessageTemplate;
    mode: Mode;
    visible: boolean;

    onHide(): void;

    onCancel?(): void;

    onSave?(): void
}


export default function MessageTemplatePropertiesDialog(props: MessageTemplateDialogProps) {

    const title = props.mode === "create" ?
        "Регистрация нового шаблона сообщения" :
        "Редактирование шаблона сообщения";

    const [busy, setBusy] = useState<boolean>(false);

    const [messageTemplate, setMessageTemplate] =
        useState<MessageTemplate>(props.messageTemplate);

    const {showError, showSuccess} = useUserNotification();


    const reload = () => {
        setMessageTemplate(props.messageTemplate);
    };

    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload();
    }, [props.messageTemplate]);


    const save = () => {
        if (props.mode === "create") {
            setBusy(true);

            messageTemplateService.create(messageTemplate)
                .then(() => {
                    showSuccess({
                        summary: "Регистрация шаблона сообщения",
                        detail: `Шаблон сообщения "${messageTemplate.name}" успешно зарегистрирован в системе.`
                    });
                    if (props.onSave) props.onSave();
                })
                .catch(reason => showError({
                    summary: "Регистрация шаблона сообщения",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));

        } else if (props.mode === "edit" && messageTemplate.id) {
            setBusy(true);
            messageTemplateService.update(messageTemplate.id, messageTemplate)
                .then(() => {
                    showSuccess({
                        summary: "Обновление шаблона сообщения",
                        detail: `Регистрационные данные шаблона сообщения "${messageTemplate.name}" успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Обновление шаблона сообщения",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    };

    const footer = (
        <div>
            <SaveButton onClick={() => save()} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );

    return (
        <BlockUI blocked={busy}>
            <Dialog header={title}
                    visible={props.visible}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-dialog">

                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="name" className="font-bold">Наименование</label>
                        <InputText id="name"
                                   autoFocus
                                   value={messageTemplate.name || ""}
                                   onChange={(e) =>
                                       setMessageTemplate(prev => ({
                                           ...prev,
                                           name: e.target.value
                                       }))}/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="mode" className="font-bold">Режим шаблона</label>
                        <MessageTemplateModeDropdown id="mode"
                                                     mode={messageTemplate.mode}
                                                     onChoose={mode =>
                                                         setMessageTemplate(prev => ({
                                                             ...prev,
                                                             mode: mode
                                                         }))}/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="text" className="font-bold">Текст шаблона</label>
                        <InputTextarea id="text"
                                       value={messageTemplate.text || ""}
                                       rows={10}
                                       autoResize
                                       onChange={(e) =>
                                           setMessageTemplate(prev => ({
                                               ...prev,
                                               text: e.target.value
                                           }))}/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="taxonomy" className="font-bold">Таксономия</label>
                        <TaxonomySelect value={messageTemplate.taxonomy ? messageTemplate.taxonomy : null}
                                        onChange={id =>
                                            setMessageTemplate(prev => ({
                                                ...prev,
                                                taxonomy: id
                                            }))}/>
                        <span className="notes">Необязательно для заполнения. Предназначено для связывания различного рода данных системы.</span>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="notes" className="font-bold">Примечания</label>
                        <Notes id="notes"
                               value={messageTemplate.notes || ""}
                               tabIndex={4}
                               onChange={e =>
                                   setMessageTemplate(
                                       prev => ({
                                           ...prev,
                                           notes: e.target.value
                                       }))}
                        />
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    );
};