import {Card} from "primereact/card";
import {DataTable, DataTableFilterEvent, DataTablePageEvent, DataTableSortEvent} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useRef, useState} from "react";
import {confirmDialog} from "primereact/confirmdialog";
import {useMountEffect, useStorage, useUpdateEffect} from "primereact/hooks";
import ReloadButton from "../../../components/common/buttons/ReloadButton";
import {errorMessage} from "../../../helpers/axiosError";
import useUserNotification from "../../../hooks/useUserNotification";
import FilterResetButton from "../../../components/common/buttons/FilterResetButton";
import ColumnToggle, {visibleOnly} from "../../../components/common/dataTable/ColumnToggle";
import CreateButton from "../../../components/common/buttons/CreateButton";
import DeleteButton from "../../../components/common/buttons/DeleteButton";
import EditButton from "../../../components/common/buttons/EditButton";
import TicketMessagePropertiesDialog, {TicketMessageDialogState} from "./TicketMessagePropertiesDialog";
import TableState from "../../../interfaces/TableState";
import tableStateToPage from "../../../components/common/dataTable/tableStateToPage";
import tableStateToSorting from "../../../components/common/dataTable/tableStateToSorting";
import {pfsFilterToSpring} from "../../../helpers/primereact-turkraft";
import {assetName} from "../../../helpers/assets";
import {Tag} from "primereact/tag";
import {OverlayPanel} from "primereact/overlaypanel";
import TicketMessagesAssets from "./TicketMessagesAssets";
import {Button} from "primereact/button";
import {
    ticketMessagePaginationInitialState,
    ticketMessageRowsPerPage,
    ticketMessageSortingInitialState
} from "./TicketMessageConstants";
import {initialTicketMessageColumns, ticketMessageColumns} from "./TicketMessageColumns";
import {initialTicketMessage, TicketMessage} from "../../../interfaces/ticket/TicketMessage";
import {TicketMessageAssets} from "../../../service/TicketMessageService";
import {ticketMessageService} from "../../../index";
import {ticketMessageFilterInitialState} from "./TicketMessageFilter";

export default function TicketMessagePage() {

    const [busy, setBusy] = useState(false);

    const [tableState, setTableState] =
        useState<TableState>({
            filters: ticketMessageFilterInitialState,
            pagination: ticketMessagePaginationInitialState,
            sorting: ticketMessageSortingInitialState
        });

    const [dialogState, setDialogState] =
        useState<TicketMessageDialogState>({
            mode: "edit",
            entity: {...initialTicketMessage},
            visible: false
        });

    const [ticketMessages, setTicketMessages] =
        useState<TicketMessage[]>([]);

    const [ticketMessagesAssets, setTicketMessagesAssets] =
        useState<TicketMessageAssets[] | undefined>(undefined);

    const [totalRecords, setTotalRecords] =
        useState<number>(0);

    const [selected, setSelected] =
        useState<TicketMessage[]>([]);

    const [visibleFields, setVisibleFields] =
        useStorage<string[]>(initialTicketMessageColumns(), "nsg-pa-dt-ticket-message-cols");

    const [ticketMessageAsset, setTicketMessageAsset]
        = useState<TicketMessage>(initialTicketMessage);

    const assetsOverPanel = useRef<any>(null);


    const {showError, showSuccess} = useUserNotification();

    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload();
    }, [tableState]);

    const hasSelected = () => {
        return selected && selected.length > 0;
    };

    const reload = () => {
        setBusy(true);

        const paging = tableStateToPage(tableState);
        const sorting = tableStateToSorting(tableState);
        const filter = pfsFilterToSpring(tableState.filters);

        ticketMessageService.findAll(filter, paging, sorting)
            .then(response => {
                setTicketMessages(response.content);
                setTotalRecords(response.totalElements);
                ticketMessageService.withMediaAssets(response.content)
                    .then(response => setTicketMessagesAssets(response))
                    .catch(reason => showError({
                        summary: "Загрузка сообщений",
                        detail: errorMessage(reason)
                    }));

            })
            .catch(reason => {
                setTicketMessages([]);
                showError({
                    summary: "Загрузка сообщений",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
                setSelected([]);
            });
    };

    const deleteSelected = () => {
        if (hasSelected()) {
            setBusy(true);
            ticketMessageService.deleteAll(selected)
                .then(() => {
                    showSuccess({
                        summary: "Удаление сообщения",
                        detail: "Выбранные сообщения успешно удалены."
                    });
                    reload();
                })
                .catch(reason => showError({
                    summary: "Удаление сообщения",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    }

    const initFilters = () => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: ticketMessageFilterInitialState}
        }));
    };

    const editButtonTemplate = (ticketMessage: TicketMessage) => {
        return <div className="flex flex-row gap-1">
            <EditButton onClick={() => {
                setDialogState({
                    mode: "edit",
                    entity: {...ticketMessage},
                    visible: true
                });
            }} tooltip="Редактировать карточку сообщения"/>
            <Button type="button"
                    icon="pi pi-paperclip"
                    className="p-button-rounded"
                    tooltip="Файлы"
                    tooltipOptions={{position: 'top'}}
                    onClick={(e) => {
                        setTicketMessageAsset(ticketMessage);
                        assetsOverPanel.current?.toggle(e);
                    }}/>
        </div>
    };

    const deleteConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите удалить выбранные сообщения?",
            header: "Удаление сообщения",
            icon: "pi pi-info-circle",
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                deleteSelected();
            }
        });
    };


    const assetsTemplate = (ticketMessage: TicketMessage) => {
        if (ticketMessagesAssets) {
            let entry = ticketMessagesAssets.find(ip => ip.ticketMessage.id === ticketMessage.id);
            if (entry) {
                return (
                    <div className="flex flex-row gap-1">
                        {entry.assets.map(
                            (asset, index) =>
                                <Tag key={index}
                                    //onClick={() => mediaAssetService.downloadAsset(asset)}
                                    /*onClick={(e) => {
                                        setInsuranceProgramAsset(insuranceProgram);
                                        assetsOverPanel.current?.toggle(e);
                                    }}*/
                                     value={assetName(asset)}
                                     className="p-chip"
                                />
                        )}
                    </div>
                );
            }
        }
    };


    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-1 lg:justify-content-between">
            <div className="flex flex-row gap-1">
                <ReloadButton onClick={reload}/>
                <FilterResetButton onClick={initFilters}/>
                <CreateButton tooltip="Зарегистрировать новое сообщение"
                              onClick={() => {
                                  setDialogState({
                                      mode: "create",
                                      entity: {...initialTicketMessage},
                                      visible: true
                                  });
                              }}
                              className="ml-1"
                />
                <DeleteButton onClick={deleteConfirmation}
                              tooltip="Удалить сообщение"
                              disabled={!hasSelected()}
                />
            </div>

            <ColumnToggle columns={ticketMessageColumns(assetsTemplate)}
                          visibleFields={visibleFields}
                          onColumnToggle={fields => setVisibleFields(fields)}/>
        </div>
    );

    const tableFooter = `Всего ${ticketMessages.length} сообщений`;


    /*
        Обработчики событий
    */

    const onPage = (event: DataTablePageEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{pagination: event}
        }));
    };

    const onSort = (event: DataTableSortEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{sorting: event}
        }));
    };

    const onFilter = (event: DataTableFilterEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: event.filters}
        }));
    };


    return (
        <div className="container-fluid">
            <OverlayPanel ref={assetsOverPanel}>
                <TicketMessagesAssets ticketMessage={ticketMessageAsset}
                                      onBind={() => reload()}
                                      onUnbind={() => reload()}
                                      onClose={e => assetsOverPanel.current.hide()}/>
            </OverlayPanel>

            <Card title="Сообщения">
                <DataTable value={ticketMessages} dataKey="id"
                           header={tableHeader} footer={tableFooter}
                           lazy loading={busy} first={tableState.pagination.first} totalRecords={totalRecords}
                           filterDisplay="menu" onFilter={onFilter} filters={tableState.filters} filterLocale="ru"
                           sortField={tableState.sorting.sortField} sortOrder={tableState.sorting.sortOrder}
                           onSort={onSort}
                           paginator rows={tableState.pagination.rows}
                           rowsPerPageOptions={ticketMessageRowsPerPage}
                           onPage={onPage}
                           selectionMode="multiple" selection={selected!}
                           onSelectionChange={e => setSelected(e.value)}
                           scrollable={true} size="small"
                           stateStorage="local" stateKey="nsg-pa-dt-ticket-message"
                           emptyMessage="Нет данных">

                    <Column selectionMode="multiple"/>
                    <Column body={editButtonTemplate}/>

                    {visibleOnly(visibleFields, ticketMessageColumns(assetsTemplate))}

                </DataTable>
            </Card>


            {/* Диалог регистрации и редактирования программы страхования */}

            <TicketMessagePropertiesDialog ticketMessage={dialogState.entity}
                                           mode={dialogState.mode}
                                           visible={dialogState.visible}
                                           onHide={() => setDialogState(prev => ({
                                               ...prev,
                                               ...{visible: false}
                                           }))}
                                           onCancel={() => setDialogState(prev => ({
                                               ...prev,
                                               ...{visible: false}
                                           }))}
                                           onSave={() => {
                                               setDialogState(prev => ({
                                                   ...prev,
                                                   ...{visible: false}
                                               }));
                                               reload();
                                           }}
            />

        </div>
    );
}