import {useContext} from "react";
import {UserNotificationContext, UserNotificationType} from "../providers/UserNotificationProvider";

export default function useUserNotification(): UserNotificationType {
    const {
        error,
        warn,
        info,
        success,
        showError,
        removeError,
        showWarn,
        removeWarn,
        showInfo,
        removeInfo,
        showSuccess,
        removeSuccess
    } = useContext<UserNotificationType>(UserNotificationContext);
    return {
        error: error,
        warn: warn,
        info: info,
        success: success,
        showError,
        removeError,
        showWarn,
        removeWarn,
        showInfo,
        removeInfo,
        showSuccess,
        removeSuccess
    };
}