import StatusOutput from "../../common/StatusOutput";
import {NotificationListStatus} from "../../../interfaces/notification/NotificationListStatus";
import {notificationListStatusTitles} from "../../../interfaces/dictionaries/NotificationListStatusTitles";

interface NotificationListOutputProps {
    status: NotificationListStatus;
}

export default function NotificationListStatusOutput(props: NotificationListOutputProps) {
    return (
        <StatusOutput code={props.status}
                      classifier={notificationListStatusTitles}/>
    );
};