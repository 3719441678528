import {Avatar} from "primereact/avatar";
import {useRef, useState} from "react";
import {useAuth} from "../security/AuthProvider";
import {OverlayPanel} from "primereact/overlaypanel";
import {Button} from "primereact/button";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {Dialog} from "primereact/dialog";
import Registration from "./Registration";

export default function MainMenuAvatar() {
    const navigate: NavigateFunction = useNavigate();
    const {user, isAdmin, logout} = useAuth();
    const op = useRef<any>(null);
    const [dv, setDialogVisible] = useState<boolean>(false);

    const handleProfile = () => {
        op.current?.hide();
        navigate('/profile');
    };

    const handleLogout = () => {
        //op.current?.hide();
        logout();
    };

    const bindAccount = () => {
        op.current?.hide();
        setDialogVisible(true);
    };

    const onRegistrationAccept = () => {
        setDialogVisible(false);
    }

    const onRegistrationReject = () => {
        setDialogVisible(false);
    }

    return (
        <>
            <div className="username-wrapper">
                <Avatar size="normal"
                        icon="pi pi-user"
                        onClick={(e) => {
                            return op.current?.toggle(e);
                        }}
                />
                <span className="ml-1">{user.username}</span>
            </div>

            <OverlayPanel ref={op} className="user-menu">
                <div className="flex flex-column gap-1 justify-content-start align-content-start">
                    {/*<Button label="Мой профиль" link icon="pi pi-user" onClick={handleProfile}/>*/}
                    {/*<Button label="Новиков Николай" link icon="pi pi-user" onClick={handleProfile}/>
                    <Button label="Новикова Марина" link icon="pi pi-user" onClick={handleProfile}/>
                    <Button label="Новикова Наталья" link icon="pi pi-user" onClick={handleProfile}/>*/}
                    {/*<Button label="Добавить профиль" link icon="pi pi-plus" onClick={bindAccount}/>*/}
                    <Button label="Сменить пароль"
                            link icon="pi pi-asterisk"
                            onClick={() => navigate("password")}/>
                    <Button label="Выйти"
                            link
                            icon="pi pi-sign-out"
                            onClick={handleLogout}/>
                </div>
            </OverlayPanel>

            <Dialog onHide={() => setDialogVisible(false)}
                    visible={dv}>
                <Registration onAccept={onRegistrationAccept}
                              onReject={onRegistrationReject}/>
            </Dialog>
        </>
    );
}