import {MediaAsset} from "../interfaces/MediaAsset";
import MediaAssetType from "../interfaces/MediaAssetType";
import {dictMediaAssetTypes} from "../App";
import {MediaAssetTypeTitles} from "../interfaces/dictionaries/MediaAssetTypeTitles";

export function assetName(asset: MediaAsset) {
    if (asset.title)
        return asset.title;
    if (asset.name)
        return asset.name;
    return "Без имени";
}

export function assetType(type: string): MediaAssetType {
    let mediaAssetType = dictMediaAssetTypes
        .find(t => t.type === type);

    //console.log(dictMediaAssetTypes)

    if (!mediaAssetType)
        throw Error(`Тип медиа-актива ${type} не найден.`)

    return mediaAssetType;
}

export function assetTypeTitle(asset: MediaAsset) {
    const t = MediaAssetTypeTitles.find(mat => mat.code === asset.type);
    return t ? t.title : "Тип не определён";
}

