import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {ticketMessageStatusTitles} from "../../interfaces/dictionaries/TicketMessageStatusTitles";
import {TicketMessageStatus} from "../../interfaces/enum/TicketMessageStatus";

interface TicketMessageStatusDropdownProps {
    id: string;
    status: TicketMessageStatus | undefined;

    onStatusSelect?(status: TicketMessageStatus): void
}

export default function TicketMessageStatusDropdown({onStatusSelect, status}: TicketMessageStatusDropdownProps) {
    return (
        <Dropdown value={status}
                  options={ticketMessageStatusTitles}
                  optionValue="code"
                  optionLabel="title"
                  required={true}
                  onChange={(e: DropdownChangeEvent) => onStatusSelect && onStatusSelect(e.value)}
                  placeholder="Выберите статус сообщения"
                  className="w-full"
        />
    );
};