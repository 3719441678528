import {useAppSettings} from "../providers/AppSettingsProvider";
import {API_MEDIA_ASSETS_URL} from "../config";

export default function RegistrationNdaLink() {
    let {appSettings} = useAppSettings();

    if (appSettings && appSettings.nda) {
        const ndaUrl = `${API_MEDIA_ASSETS_URL}/download/${appSettings.nda}`;
        return <a href={ndaUrl} rel="noreferrer">согласие на обработку персональных данных</a>;
    } else {
        return <>согласие на обработку персональных данных</>;
    }
}