import {Controller, useForm} from "react-hook-form";
import {classNames} from "primereact/utils";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import React, {useState} from "react";
import TicketCreateRequest from "../interfaces/ticket/TicketCreateRequest";
import {Message} from "primereact/message";
import useUserNotification from "../hooks/useUserNotification";
import {BlockUI} from "primereact/blockui";
import {Ticket} from "../interfaces/ticket/Ticket";
import TicketTaxonomySelect from "../components/ticket/TicketTaxonomySelect";
import {useAppSettings} from "../providers/AppSettingsProvider";
import {formatBytes} from "../helpers/content";
import {ticketMessageService, ticketService} from "../index";
import {errorMessage} from "../helpers/axiosError";
import TicketMessageCreateRequest from "../interfaces/ticket/TicketMessageCreateRequest";

export interface UserTicketMessageFormProps {
    ticket: Ticket | undefined;

    doSubmit(): void;
}

interface TicketFormData {
    files: File[] | undefined | null;
    topic: string;
    taxonomy: number | undefined | null;
    text: "";
}

export default function UserTicketMessageForm({ticket, doSubmit}: UserTicketMessageFormProps) {
    const [busy, setBusy] =
        useState<boolean>(false);

    const {showError, showSuccess} = useUserNotification();
    const {appSettings} = useAppSettings();

    const initialRequest: TicketFormData = {
        files: [],
        topic: "",
        taxonomy: null,
        text: ""
    };

    const submitForm = (formData: TicketFormData) => {
        setBusy(true);

        if (!ticket) {
            if (formData.taxonomy) {
                const request: TicketCreateRequest = {
                    taxonomy: formData.taxonomy,
                    topic: formData.topic,
                    text: formData.text,
                    files: formData.files
                };

                // Регистрация нового обращения
                ticketService.registration(request)
                    .then(({data}) => {
                        showSuccess({
                            summary: "Регистрация обращения",
                            detail: `Ваше обращение успешно зарегистрировано под номером ${data.id}.`
                        });
                        doSubmit();

                    })
                    .catch(reason => showError({
                        summary: "Регистрация обращения",
                        detail: errorMessage(reason),
                    }))
                    .finally(() => {
                        setBusy(false);
                        reset();
                    });
            }

        } else if (ticket.id) {
            // Регистрация нового сообщения в Обращении
            const request: TicketMessageCreateRequest = {
                ticketId: ticket.id,
                text: formData.text,
                files: formData.files
            };
            ticketMessageService.registration(request)
                .then(({data}) => {
                    //console.log(data);
                    showSuccess({
                        summary: "Регистрация сообщения",
                        detail: `Ваше сообщение успешно зарегистрировано под номером ${data.id}.`
                    });
                    doSubmit();

                })
                .catch(reason => showError({
                    summary: "Регистрация сообщения",
                    detail: errorMessage(reason),
                }))
                .finally(() => {
                    setBusy(false);
                    reset();
                });
        }
    };

    /*const onUpload = (event: FileUploadUploadEvent) => {
        const asset = event.xhr.response as MediaAsset;
        console.log("onUpload, event:", event);
        console.log("onUpload, asset", asset);
    }*/

    const {
        control,
        formState: {errors},
        handleSubmit,
        reset
    } = useForm({defaultValues: initialRequest});

    const getFormErrorMessage = (name: string) => {
        const key = name as keyof TicketCreateRequest;
        return errors[key] && <Message severity="error" text={errors[key]?.message}/>
    };

    const topicIsBlank = (): boolean => {
        return !ticket || !ticket.topic || ticket.topic.length === 0;
    }

    const isFilesValid = (files: File[] | null | undefined) => {
        if (files) {
            // Валидация общего количества файлов
            if (appSettings.maxFilesCount && files.length > appSettings.maxFilesCount)
                return `Количество загружаемых файлов должно быть меньше, или равно ${appSettings.maxFilesCount}.`;

            let total: number = 0;

            for (const file of [...files]) {
                // Валидация размера файла
                if (appSettings.maxFileSize && file.size > appSettings.maxFileSize)
                    return `Размер файла ${file.name} (${formatBytes(file.size)}) превышает допустимое ограничение ${formatBytes(appSettings.maxFileSize)} (${appSettings.maxFileSize} байт).`;

                total += file.size;

                // Валидация размера всех выбранных файлов
                if (appSettings.maxFilesSize && total > appSettings.maxFilesSize)
                    return `Общий размер файлов ${formatBytes(total)} превышает допустимое ограничение ${formatBytes(appSettings.maxFilesSize)} (${appSettings.maxFilesSize} байт).`;
            }
        }

        return true;
    };

    return (
        <BlockUI blocked={busy}>
            <form onSubmit={handleSubmit(submitForm)}
                  className="flex flex-column gap-3 bg-gray-100 p-5 align-items-start">
                {!topicIsBlank() &&
                    <div className="flex flex-row justify-content-between align-items-center w-full">
                        <h2>Тема: {ticket && ticket.topic}</h2>
                        <div className="text-sm text-500">id:{ticket && ticket.id}</div>
                    </div>
                }
                {/*{!ticket &&
                    <Controller
                        name="topic"
                        control={control}
                        render={
                            ({field, fieldState}) => (
                                <span className="p-float-label w-full">
                                    <InputText id={field.name}
                                               value={field.value}
                                               className={classNames({'p-invalid': fieldState.error})}
                                               style={{width: "100%"}}
                                               tabIndex={1}
                                               autoFocus
                                               onChange={(e) => field.onChange(e.target.value)}
                                    />
                                    <label htmlFor={field.name}>Тема</label>
                                    {getFormErrorMessage(field.name)}
                                </span>
                            )}
                    />
                }*/}
                {!ticket &&
                    <Controller
                        name="taxonomy"
                        control={control}
                        rules={{required: "Выбор темы обращения обязателен"}}
                        render={
                            ({field, fieldState}) => (
                                <div className="w-full flex flex-column gap-1">
                                    <label htmlFor={field.name}>Тема</label>
                                    <TicketTaxonomySelect id={field.name}
                                                          value={field.value}
                                                          onChange={(term) => field.onChange(term)}/>
                                    {getFormErrorMessage(field.name)}
                                </div>
                            )}
                    />
                }
                <Controller
                    name="text"
                    control={control}
                    rules={{required: 'Текст обращения обязателен'}}
                    render={
                        ({field, fieldState}) => (
                            <span className="w-full flex flex-column gap-1">
                                <label htmlFor={field.name}>Сообщение</label>
                                <InputTextarea id={field.name}
                                               value={field.value}
                                               autoResize
                                               rows={10}
                                               maxLength={2000}
                                               className={classNames({'p-invalid': fieldState.error})}
                                               style={{width: "100%"}}
                                               tabIndex={2}
                                               onChange={(e) => field.onChange(e.target.value)}
                                />
                                {getFormErrorMessage(field.name)}
                                <div className="text-xs text-500">{field.value.length}/2000</div>
                            </span>)}
                />

                <div className="flex flex-column gap-1">
                    {/*<input type={"file"} {...register("file")}/>*/}
                    <Controller
                        name={"files"}
                        control={control}
                        rules={{validate: isFilesValid}}
                        render={
                            ({field, fieldState}) => {
                                return (
                                    <span className="w-full flex flex-column gap-1">
                                        <input
                                            id="file"
                                            type="file"
                                            name="file"
                                            multiple
                                            // maxLength={maxFilesSize}
                                            onChange={(e) =>
                                                field.onChange(e.target.files ? e.target.files : undefined)
                                            }
                                        />
                                        {getFormErrorMessage(field.name)}
                                        <div className="note mt-1 flex flex-column">
                                            {appSettings.maxFileSize &&
                                                <div>Максимальный размер одного
                                                    файла &mdash; {formatBytes(appSettings.maxFileSize)}.</div>
                                            }
                                            {appSettings.maxFileSize &&
                                                <div>Максимальный количество
                                                    файлов &mdash; {appSettings.maxFilesCount}.</div>
                                            }
                                            {appSettings.maxFileSize &&
                                                <div>Максимальный размер всех
                                                    файлов &mdash; {formatBytes(appSettings.maxFilesSize)}.</div>
                                            }
                                        </div>
                                    </span>
                                );
                            }}
                    />
                </div>
                <Button label="Отправить"
                        type="submit"
                        tabIndex={4}
                        className="mt-5"
                        icon="pi pi-check"/>
            </form>
        </BlockUI>
    );
}