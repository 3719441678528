import React, {createContext, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {useUpdateEffect} from "primereact/hooks";

export type Message = {
    detail: string | null | undefined;
    summary: string | null | undefined;
};

export type UserNotificationType = {
    error: Message | null;
    warn: Message | null;
    info: Message | null;
    success: Message | null;

    showError(message: Message): void;
    removeError(): void;
    showWarn(message: Message): void;
    removeWarn(): void;
    showInfo(message: Message): void;
    removeInfo(): void;
    showSuccess(message: Message): void;
    removeSuccess(): void;
};

//export type UserNotificationSeverityType = 'success' | 'info' | 'warn' | 'error' | undefined;

export const UserNotificationContext: React.Context<UserNotificationType> = createContext<UserNotificationType>({
    error: null,
    warn: null,
    info: null,
    success: null,
    showError: (message: Message) => {
    },
    removeError: () => {
    },
    showWarn(message: Message) {
    },
    removeWarn() {
    },
    showInfo(message: Message) {
    },
    removeInfo() {
    },
    showSuccess(message: Message) {
    },
    removeSuccess() {
    }
});

export default function UserNotificationProvider({children}: any) {
    const [error, setError] = useState<Message | null>(null);
    const [warn, setWarn] = useState<Message | null>(null);
    const [info, setInfo] = useState<Message | null>(null);
    const [success, setSuccess] = useState<Message | null>(null);
    const toast = useRef<Toast>(null);

    useUpdateEffect(() => {
        if (error) {
            toast.current?.show({
                severity: "error",
                summary: error.summary,
                detail: error.detail,
                life: 8000
            });
            setError(null);
        }
        if (warn) {
            toast.current?.show({
                severity: "warn",
                summary: warn.summary,
                detail: warn.detail,
                life: 5000
            });
            setWarn(null);
        }
        if (info) {
            toast.current?.show({
                severity: "info",
                summary: info.summary,
                detail: info.detail,
                life: 3000
            });
            setInfo(null);
        }
        if (success) {
            toast.current?.show({
                severity: "success",
                summary: success.summary,
                detail: success.detail,
                life: 4000
            });
            setSuccess(null);
        }
    }, [error, warn, info, success]);

    const contextValue: UserNotificationType = {
        error: null,
        warn: null,
        info: null,
        success: null,

        showError: (message: Message) => {
            console.error(message.summary, message.detail);
            setError(message);
        },
        removeError: () => setError(null),
        showWarn: (message: Message) => setWarn(message),
        removeWarn: () => setWarn(null),
        showInfo: (message: Message) => setInfo(message),
        removeInfo: () => setInfo(null),
        showSuccess: (message: Message) => setSuccess(message),
        removeSuccess: () => setSuccess(null),
    };

    return (
        <UserNotificationContext.Provider value={contextValue}>
            <Toast ref={toast}/>
            {children}
        </UserNotificationContext.Provider>
    );
}