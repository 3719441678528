import {InputText} from "primereact/inputtext";
import {useState} from "react";
import {Button} from "primereact/button";
import {BlockUI} from "primereact/blockui";

export default function PasswordReset() {

    const [busy, setBusy] =
        useState<boolean>(false);

    const [email, setEmail] =
        useState<string>("");

    return (
        <BlockUI blocked={busy}>
            <div className="flex flex-column gap-5">
                <div className="flex flex-column gap-2 align-items-stretch mt-5">
                    <span className="p-float-label flex-1">
                        <InputText id="email"
                                   value={email}
                                   required={true}
                                   onChange={(e) => setEmail(e.target.value)}
                                   className="w-full"
                        />
                        <label htmlFor="email">Email *</label>
                    </span>
                    <span className={"note"}>
                        Введите Ваш верифицированный адрес электронной почты.
                    </span>
                </div>
                <div className="w-full">
                    <Button type="submit"
                            label="Продолжить"
                        /*onClick={requestUser}
                        disabled={!isUserAgreed ||
                            emailVerificationStep !== "code_verified" ||
                            !isInsurancePoliceNumberCorrect}*/
                    />
                </div>
            </div>
        </BlockUI>
    );
}