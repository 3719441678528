import ColumnMeta from "../../interfaces/ColumnMeta";
import {Column} from "primereact/column";
import {
    dateTimeRangeFilterTemplate,
    numberFilterTemplate,
    textFilterTemplate
} from "../../components/common/dataTable/filterTemplates";
import {formattedDateTime} from "../../components/common/dataTable/bodyTemplates";
import PhoneOutput from "../../components/common/PhoneOutput";
import React from "react";

export const dispatcherConsoleColumns: ColumnMeta[] = [
    {
        field: 'id',
        header: 'УН',
        hidden: true,
        column: <Column key="id" field="id" header="УН" sortable dataType="numeric"
                        filter filterElement={numberFilterTemplate}
                        style={{width: '3rem'}}
        />
    },
    {
        field: 'created',
        header: 'Создан',
        hidden: true,
        column: <Column key="created" field="created" header="Создан" sortable
                        dataType="text" body={(rowData) => formattedDateTime(rowData.created)}
                        filter filterElement={dateTimeRangeFilterTemplate}
        />
    },
    {
        field: 'updated',
        header: 'Обновлен',
        hidden: true,
        column: <Column key="updated" field="updated" header="Обновлен" sortable
                        dataType="text" body={(rowData) => formattedDateTime(rowData.updated)}
                        filter filterElement={dateTimeRangeFilterTemplate}
        />
    },
    {
        field: 'name',
        header: 'Название',
        hidden: false,
        column: <Column key="name" field="name" header="Название" sortable
                        dataType="text" body={(rowData) => <>{rowData.name}</>}
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: 'phone',
        header: 'Телефон',
        hidden: false,
        column: <Column key="phone" field="phone" header="Телефон" sortable
                        dataType="text" body={(rowData) => <PhoneOutput number={rowData.phone}/>}
                        filter filterElement={textFilterTemplate}
        />
    }

];