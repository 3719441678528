import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {ContactStatus} from "../../interfaces/enum/ContactStatus";
import {contactStatusTitles} from "../../interfaces/dictionaries/ContactStatusTitles";

interface ContactStatusDropdownProps {
    id: string;
    status: ContactStatus | undefined;

    onStatusSelect?(status: ContactStatus): void
}

export default function ContactStatusDropdown(props: ContactStatusDropdownProps) {
    return (
        <Dropdown id={props.id}
                  value={props.status}
                  options={contactStatusTitles}
                  optionValue="code"
                  optionLabel="title"
                  required={true}
                  onChange={(e: DropdownChangeEvent) => props.onStatusSelect && props.onStatusSelect(e.value)}
                  placeholder="Выберите статус контакта"
                  className="w-full"
        />
    );
};