import {DataTableFilterMeta} from "primereact/datatable";
import {FilterMatchMode, FilterOperator} from "primereact/api";

export const ticketMessageFilterInitialState: DataTableFilterMeta = {
    'id': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'created': {
        operator: FilterOperator.AND,
        constraints: [
            {value: null, matchMode: FilterMatchMode.DATE_AFTER},
            {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
        ]
    },
    'updated': {
        operator: FilterOperator.AND,
        constraints: [
            {value: null, matchMode: FilterMatchMode.DATE_AFTER},
            {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
        ]
    },
    'text': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
    },
}