import {Dropdown, DropdownChangeEvent, DropdownProps} from "primereact/dropdown";
import React, {useState} from "react";
import useUserNotification from "../hooks/useUserNotification";
import {dispatcherConsoleService} from "../index";
import ApiResponse from "../interfaces/response.interface";
import {errorMessage} from "../helpers/axiosError";
import {useMountEffect} from "primereact/hooks";
import DispatcherConsole from "../interfaces/DispatcherConsole.interface";
import PhoneOutput from "./common/PhoneOutput";

interface DispatcherConsoleDropdownProps {
    id?: string | undefined;
    value: number | undefined;

    onChange(id: number | undefined): void;
}

export default function DispatcherConsoleDropdown(props: DispatcherConsoleDropdownProps) {

    const [busy, setBusy] =
        useState<boolean>(false);

    const [dispatcherConsoles, setDispatcherConsoles] =
        useState<DispatcherConsole[]>([]);

    const {showError} = useUserNotification();

    const reload = () => {
        setBusy(true);

        dispatcherConsoleService.findAll()
            .then((response: ApiResponse<DispatcherConsole>) => {
                setDispatcherConsoles(response.content);
            })
            .catch(reason => {
                setDispatcherConsoles([]);
                showError({
                    summary: "Загрузка диспетчерских пультов",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
            });
    };

    useMountEffect(() => {
        reload();
    });

    const itemTemplate = (dispatcherConsole: DispatcherConsole) => {
        if (dispatcherConsole) {
            return <>{dispatcherConsole.name}: <PhoneOutput number={dispatcherConsole.phone}/></>;
        }
        return <></>;
    }

    const valueTemplate = (dispatcherConsole: DispatcherConsole, props: DropdownProps) => {
        if (dispatcherConsole) {
            return <>{dispatcherConsole.name}: <PhoneOutput number={dispatcherConsole.phone}/></>;
        }
        return <>{props.placeholder}</>;
    }


    return (
        <Dropdown id={props.id}
                  value={props.value}
                  valueTemplate={valueTemplate}
                  itemTemplate={itemTemplate}
                  loading={busy}
                  options={dispatcherConsoles}
                  optionValue="id"
                  optionLabel="name"
                  showClear
                  // filter
                  // filterBy="name"
                  onChange={(e: DropdownChangeEvent) =>
                      props.onChange(e.target.value ? parseInt(e.target.value) : undefined)
                  }
                  emptyMessage="Нет зарегистрированных диспетчерских пультов"
                  placeholder="Выберите диспетчерский пульт"/>
    );
}