import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import React, {useState} from "react";
import {confirmDialog} from "primereact/confirmdialog";
import {useMountEffect} from "primereact/hooks";
import {dispatcherConsoleService} from "../../index";
import DispatcherConsole from "../../interfaces/DispatcherConsole.interface";
import ApiResponse from "../../interfaces/response.interface";
import useUserNotification from "../../hooks/useUserNotification";
import {errorMessage} from "../../helpers/axiosError";
import ReloadButton from "../../components/common/buttons/ReloadButton";
import {dispatcherConsoleColumns} from "./DispatcherConsoleColumns";
import {Column} from "primereact/column";
import EditButton from "../../components/common/buttons/EditButton";
import DispatcherConsolePropertiesDialog, {DispatcherConsoleDialogState} from "./DispatcherConsolePropertiesDialog";
import FilterResetButton from "../../components/common/buttons/FilterResetButton";
import DeleteButton from "../../components/common/buttons/DeleteButton";
import CreateButton from "../../components/common/buttons/CreateButton";
import ColumnToggle, {visibleOnly} from "../../components/common/dataTable/ColumnToggle";

export default function DispatcherConsolesPage() {
    const initDispatcherConsole: DispatcherConsole = {
        id: null, created: undefined, updated: undefined, name: '', phone: '', notes: undefined
    };

    const [busy, setBusy] =
        useState(false);

    const [dispatcherConsoles, setDispatcherConsoles] =
        useState<DispatcherConsole[]>([]);

    const [selected, setSelected] =
        useState<DispatcherConsole[] | undefined>(undefined);

    const [dialogState, setDialogState] =
        useState<DispatcherConsoleDialogState>({
            mode: "edit",
            entity: {...initDispatcherConsole},
            visible: false
        });

    const [visibleFields, setVisibleFields] = useState<string[]>(
        dispatcherConsoleColumns.filter(column => !column.hidden).map(column => column.field)
    );

    const {showError, showSuccess} = useUserNotification();

    useMountEffect(() => {
        reload();
    });

    const reload = () => {
        setBusy(true);

        dispatcherConsoleService.findAll()
            .then((response: ApiResponse<DispatcherConsole>) => {
                setDispatcherConsoles(response.content);
            })
            .catch(reason => {
                setDispatcherConsoles([]);
                showError({
                    summary: "Загрузка диспетчерских пультов",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
            });
    };

    const selectionIsEmpty: () => boolean = () => {
        return !selected || selected.length === 0;
    };

    const editButtonTemplate = (rowData: DispatcherConsole) => {
        return <EditButton onClick={() =>
            setDialogState({
                mode: "edit",
                entity: {...rowData},
                visible: true
            })
        }/>
    }

    const deleteConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите удалить выбранные диспетчерские пульты?",
            header: "Удаление диспетчерского пульта",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                if (selected) {
                    setBusy(true);
                    dispatcherConsoleService.deleteAll(selected)
                        .then(() => {
                            showSuccess({
                                summary: "Удаление диспетчерского пульта",
                                detail: "Выбранные диспетчерские пульты успешно удалены."
                            });
                            reload();
                        })
                        .catch(reason => showError({
                            summary: "Удаление диспетчерского пульта",
                            detail: errorMessage(reason)
                        }))
                        .finally(() => setBusy(false));
                }
            }
        });
    };


    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-2 justify-content-between">
            <div className="flex flex-row gap-1">
                <ReloadButton onClick={reload} disabled={busy}/>
                <FilterResetButton onClick={() => {
                }}/>
                <CreateButton onClick={() =>
                    setDialogState({
                        mode: "create",
                        entity: {...initDispatcherConsole},
                        visible: true
                    })}
                              tooltip="Зарегистрировать новый диспетчерский пульт"
                              disabled={busy}
                              className="ml-1"/>

                <DeleteButton onClick={deleteConfirmation}
                              tooltip="Удалить диспетчерский пульт"
                              disabled={busy || selectionIsEmpty()}/>
            </div>

            <ColumnToggle columns={dispatcherConsoleColumns}
                          visibleFields={visibleFields}
                          onColumnToggle={fields => setVisibleFields(fields)}/>
        </div>
    );


    const tableFooter = `Всего ${dispatcherConsoles.length} диспетчерских пультов`;


    return (
        <div className="container">
            <Card title="Диспетчерские пульты">
                <DataTable value={dispatcherConsoles} dataKey="id"
                           header={tableHeader} footer={tableFooter}
                           loading={busy}
                           paginator rows={10} rowsPerPageOptions={[10, 20, 50]}
                           filterDisplay="menu"
                           selectionMode="multiple" selection={selected!}
                           onSelectionChange={(e) => setSelected(e.value)}
                           size="small">

                    <Column selectionMode="multiple"
                            headerStyle={{width: '3rem'}}/>
                    <Column body={editButtonTemplate}/>

                    {visibleOnly(visibleFields, dispatcherConsoleColumns)}

                </DataTable>
            </Card>

            <DispatcherConsolePropertiesDialog dialogState={dialogState}
                                               onHide={() => setDialogState(prev => ({
                                                   ...prev,
                                                   ...{visible: false}
                                               }))}
                                               onCancel={() => setDialogState(prev => ({
                                                   ...prev,
                                                   ...{visible: false}
                                               }))}
                                               onSave={() => {
                                                   setDialogState(prev => ({
                                                       ...prev,
                                                       ...{visible: false}
                                                   }));
                                                   reload();
                                               }}
            />
        </div>
    );
}