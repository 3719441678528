import {Button, ButtonProps} from "primereact/button";
import React from "react";

interface Props extends ButtonProps {

}

export default function UploadButton(props: Props) {
    return <Button icon="pi pi-fw pi-upload"
                   type="button"
                   rounded
                   tooltip="Загрузить файл"
                   {...props}
    />
}