import {TicketStatus} from "../../interfaces/enum/TicketStatus";
import StatusOutput from "../common/StatusOutput";
import {ticketStatusTitles} from "../../interfaces/dictionaries/TicketStatusTitles";

interface TicketStatusProps {
    status: TicketStatus;
}

export default function TicketStatusOutput(props: TicketStatusProps) {

    //const {isAdmin, isUserManagement} = useAuth();

    /*const filteredStatusText = (status: TicketStatus) => {
        if (!(isAdmin() || isUserManagement())) {

        }
        return ticketStatusText(props.status);
    }*/

    return (
        <StatusOutput code={props.status}
                      classifier={ticketStatusTitles}/>
    );
};