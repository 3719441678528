import TemporalUpdatableEntity from "./TemporalUpdatableEntity";


export interface MediaAsset extends TemporalUpdatableEntity {
    uuid: string | null;
    name?: string | undefined;
    title?: string | undefined;
    extension?: string | undefined;
    uri?: string | undefined;
    type: string | null;
    contentAuthor?: string | undefined;
    contentMimeType?: string | undefined;
    contentCharset?: string | undefined;
    contentLanguage?: string | undefined;
    contentLength?: number | undefined;
    contentCreated?: string | undefined;
    contentModified?: string | undefined;
    contentPlaintext?: string | undefined;
    contentAbstract?: string | undefined;
    contentProperties?: string | undefined;
}

export const initialMediaAsset: MediaAsset = {
    id: null, uuid: null, created: undefined, updated: undefined, name: '', type: '',
};