import StatusOutput from "../../common/StatusOutput";
import {NotificationRecipientStatus} from "../../../interfaces/notification/NotificationRecipientStatus";
import {notificationRecipientStatusTitles} from "../../../interfaces/dictionaries/NotificationRecipientStatusTitles";

interface NotificationRecipientOutputProps {
    status: NotificationRecipientStatus;
}

export default function NotificationRecipientStatusOutput(props: NotificationRecipientOutputProps) {
    return (
        <StatusOutput code={props.status}
                      classifier={notificationRecipientStatusTitles}/>
    );
};