import {DataTableFilterMeta} from "primereact/datatable";
import {FilterMatchMode, FilterOperator} from "primereact/api";

export const logFilterInitialState: DataTableFilterMeta = {
    'id': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'dateTime': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]
    },
    'type': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'level': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
    },
    'description': {
        operator: FilterOperator.AND,
        constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
    }
};