import TemporalUpdatableEntity from "../TemporalUpdatableEntity";
import {NotificationListStatus} from "./NotificationListStatus";

export default interface NotificationList extends TemporalUpdatableEntity {
    name: string;
    taxonomy: number | null;
    recipients: number[];
    notes: string | undefined;
    status: NotificationListStatus;
}

export const initialNotificationList: NotificationList = {
    id: null,
    name: "",
    taxonomy: null,
    recipients: [],
    notes: undefined,
    status: NotificationListStatus.ACTIVE
}