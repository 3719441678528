import {API_DOCUMENT_TEMPLATES_URL} from "../config";
import axios, {AxiosError, AxiosResponse} from "axios";
import {ApiErrorResponse} from "../interfaces/response.interface";

export default class DocumentTemplateService {

    url: string = API_DOCUMENT_TEMPLATES_URL;


    async substituteInsurancePolicyDownload(documentTitle: string | undefined | null) {
        axios.get(`${this.url}/substitute/insurancePolicy`, {
            responseType: 'blob',

        })
            .then((response: AxiosResponse<Blob>) => {
                return response.data;

            })
            .then((blob: Blob) => {
                const href: string = window.URL.createObjectURL(blob);
                const anchor: HTMLAnchorElement = document.createElement('a');
                anchor.href = href;
                if (documentTitle) anchor.download = documentTitle;

                document.body.appendChild(anchor);       // append the element to the dom
                anchor.click();
                anchor.remove();                         // afterwards, remove the element

            })
            .catch((error: AxiosError<ApiErrorResponse>) => {
                console.error(error);
            });
    }

    async substituteInsurancePolicyDownloadByProfile(
        profileId: number,
        documentTitle: string | undefined | null) {

        axios.get(`${this.url}/substitute/insurancePolicy/${profileId}`, {
            responseType: 'blob',

        })
            .then((response: AxiosResponse<Blob>) => {
                return response.data;

            })
            .then((blob: Blob) => {
                const href: string = window.URL.createObjectURL(blob);
                const anchor: HTMLAnchorElement = document.createElement('a');
                anchor.href = href;
                if (documentTitle) anchor.download = documentTitle;

                document.body.appendChild(anchor);       // append the element to the dom
                anchor.click();
                anchor.remove();                         // afterwards, remove the element

            })
            .catch((error: AxiosError<ApiErrorResponse>) => {
                console.error(error);
            });
    }

    /*    async substituteUserData(request: DocumentTemplateSubstituteRequest) {
            axios.post(this.url + "/substitute", request, {
                responseType: 'blob',

            })
                .then((response: AxiosResponse<Blob>) => {
                    return response.data;

                })
                .then((blob: Blob) => {
                    const href: string = window.URL.createObjectURL(blob);
                    const a: HTMLAnchorElement = document.createElement('a');
                    a.href = href;
                    a.download = request.documentTitle;
                    document.body.appendChild(a);       // append the element to the dom
                    a.click();
                    a.remove();                         // afterwards, remove the element

                })
                .catch((error: AxiosError<ApiErrorResponse>) => {
                    console.error(error);
                });
        }*/

}