import {UserStatus} from "../interfaces/enum/UserStatus";
import {userStatusTitles} from "../interfaces/dictionaries/UserStatusTitles";
import StatusOutput from "./common/StatusOutput";

interface UserStatusOutputProps {
    status: UserStatus;
}

export default function UserStatusOutput(props: UserStatusOutputProps) {
    return (
        <StatusOutput code={props.status}
                      classifier={userStatusTitles}/>
    );
};