import {EntityDialogState, Mode} from "../../components/common/dialog/EntityDialogState";
import React, {useState} from "react";
import useUserNotification from "../../hooks/useUserNotification";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import CancelButton from "../../components/common/buttons/CancelButton";
import SaveButton from "../../components/common/buttons/SaveButton";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {serviceProgramService} from "../../index";
import {errorMessage} from "../../helpers/axiosError";
import {BlockUI} from "primereact/blockui";
import ServiceProgram from "../../interfaces/ServiceProgram";
import TaxonomySelect from "../../components/common/taxonomy/TaxonomySelect";

export interface ServiceProgramDialogState extends EntityDialogState<ServiceProgram> {
}

export interface ServiceProgramDialogProps {
    serviceProgram: ServiceProgram;
    mode: Mode;
    visible: boolean;

    onHide(): void;

    onCancel?(): void;

    onSave?(): void
}

export default function ServiceProgramPropertiesDialog(props: ServiceProgramDialogProps) {

    const title = props.mode === "create" ?
        "Регистрация нового плана обслуживания" :
        "Редактирование карточки плана обслуживания";

    const [busy, setBusy] = useState<boolean>(false);

    const [editedServiceProgram, setEditedServiceProgram] =
        useState<ServiceProgram>(props.serviceProgram);

    const {showError, showSuccess} = useUserNotification();


    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload();
    }, [props.serviceProgram]);

    const reload = () => {
        setEditedServiceProgram(props.serviceProgram);
    };

    const save = () => {
        if (props.mode === "create") {
            setBusy(true);
            serviceProgramService.create(editedServiceProgram)
                .then(() => {
                    showSuccess({
                        summary: "Регистрация плана обслуживания",
                        detail: `План обслуживания "${editedServiceProgram.name}" успешно зарегистрирован в системе.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Регистрация плана обслуживания",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));

        } else if (props.mode === "edit" && editedServiceProgram.id) {
            setBusy(true);
            serviceProgramService.update(editedServiceProgram.id, editedServiceProgram)
                .then(() => {
                    showSuccess({
                        summary: "Обновление плана обслуживания",
                        detail: `Регистрационные данные плана обслуживания "${editedServiceProgram.name}" успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Регистрация плана обслуживания",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    };


    const footer = (
        <div>
            <SaveButton onClick={() => save()} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );

    return (
        <BlockUI blocked={busy}>
            <Dialog header={title}
                    visible={props.visible}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-dialog">

                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2">
                        <label htmlFor="name" className="font-bold">Название</label>
                        <InputText id="name" value={editedServiceProgram.name}
                                   onChange={(e) => {
                                       setEditedServiceProgram(prev => ({
                                           ...prev,
                                           ...{name: e.target.value}
                                       }))
                                   }}/>
                    </div>
                    <div className="flex flex-column gap-4">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="taxonomy" className="font-bold">Таксономия</label>
                            <TaxonomySelect value={editedServiceProgram.taxonomy}
                                            onChange={taxonomy =>
                                                setEditedServiceProgram(prev => ({
                                                    ...prev,
                                                    taxonomy: taxonomy
                                                }))
                                            }/>
                        </div>
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    );
};