import React from "react";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import MediaAssetType from "../../../interfaces/MediaAssetType";
import {dictMediaAssetTypes} from "../../../App";
import {MediaAssetTypeTitles} from "../../../interfaces/dictionaries/MediaAssetTypeTitles";

interface MediaAssetTypeDropdownProps {
    id?: string | undefined;
    value: string | undefined | null;
    filter?: (type: MediaAssetType) => boolean;
    onChoose?: (type: string | null) => void;
}

export type Props = MediaAssetTypeDropdownProps;

export default function MediaAssetTypeDropdown(props: MediaAssetTypeDropdownProps) {

    const assetTypeValueTemplate = (type: MediaAssetType, props: any) => {
        const t = type ?
            MediaAssetTypeTitles.find(t => t.code === type.type) : null;
        return <>{t?.title || props.placeholder}</>
    };

    const assetTypeTemplate = (type: MediaAssetType) => {
        const t = type ?
            MediaAssetTypeTitles.find(t => t.code === type.type) : null;
        return <>{t?.title || type.type}</>
    }

    /*    const items = () => {
            if (props.filter !== undefined) {
                return dictMediaAssetTypes.filter(type => props.filter(type));
            }
            else {
                return dictMediaAssetTypes;
            }
        };*/

    return (
        <Dropdown id={props.id}
                  value={props.value}
                  options={dictMediaAssetTypes}
                  itemTemplate={assetTypeTemplate}
                  valueTemplate={assetTypeValueTemplate}
                  optionValue="type"
                  optionLabel="type"
                  filter
                  showClear
                  onChange={(e: DropdownChangeEvent) => props.onChoose && props.onChoose(e.value)}
                  placeholder="Выберите тип файла"
                  className="w-full"
        />
    );
}