import {Button, ButtonProps} from "primereact/button";

interface ChooseMediaAssetButtonProps extends ButtonProps {
}

type Props = ChooseMediaAssetButtonProps;

export default function ChooseMediaAssetButton(props: Props) {
    return <Button type="button"
                   // label="Выбрать"
                   icon="pi pi-external-link"
                   outlined
                   {...props}
    />
}