import TemporalUpdatableEntity from "./TemporalUpdatableEntity";

export default interface UserProfile extends TemporalUpdatableEntity {
    title?: string | undefined;
    firstName?: string | undefined;
    secondName?: string | undefined;
    thirdName?: string | undefined;
    dateOfBirth?: string | undefined;
    category?: string | undefined;
    organization?: number | undefined;
    insurer?: string | undefined;
    insuranceAgreementNumber?: string | undefined;
    insurancePolicyNumber?: string | undefined;
    insurancePeriodStartDate?: string | undefined;
    insurancePeriodEndDate?: string | undefined;
    insuranceProgram?: number | undefined;
    serviceProgram?: number | undefined;
    dispatcherConsole?: number | undefined;
    therapist?: number | undefined;
    // user?: number | undefined;
}

export const initialUserProfile: UserProfile = {
    id: null,
    // firstName: undefined,
    // secondName: undefined,
    // thirdName: undefined
};
