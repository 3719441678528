import React from "react";
import {MediaAssetTypeTitles} from "../../../interfaces/dictionaries/MediaAssetTypeTitles";

interface MediaAssetTypeOutputProps {
    id?: string | undefined;
    value: string | undefined | null;
}

export type Props = MediaAssetTypeOutputProps;

export default function MediaAssetTypeEnumOutput(props: Props) {

    const mediaAssetType = (type: string | null | undefined) => {
        const t = type ?
            MediaAssetTypeTitles.find(t => t.code === type) : null;
        return <>{t?.title || "Тип не определён"}</>
    };

    return (
        <>{mediaAssetType(props.value)}</>
    );
}