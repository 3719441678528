import React, {useState} from "react";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import {dispatcherConsoleService, insuranceProgramService, therapistService} from "../index";
import UserFullName from "./UserFullName";
import FormattedDateRange from "./FormattedDateRange";
import InsuranceProgram from "../interfaces/InsuranceProgram.interface";
import DispatcherConsole from "../interfaces/DispatcherConsole.interface";
import PhoneOutput from "./common/PhoneOutput";
import Therapist from "../interfaces/Therapist";
import TherapistFullName from "./TherapistFullName";
import {MediaAsset} from "../interfaces/MediaAsset";
import MediaAssetDownloadLink from "./common/mediaAsset/MediaAssetDownloadLink";
import {MediaAssetTypeEnumeration} from "../interfaces/enum/MediaAssetTypeEnumeration";
import {useAppSettings} from "../providers/AppSettingsProvider";
import InsurancePolicyLink from "./InsurancePolicyLink";
import {Card} from "primereact/card";
import UserProfile from "../interfaces/UserProfile.interface";

export interface UserDetailsProps {
    profile: UserProfile;
}

type Props = UserDetailsProps;

export default function UserDetails(props: Props) {
    const {profile} = props;
    const {appSettings} = useAppSettings();
    //const {isInsured, isTherapist, isAdmin} = useAuth();

    const [insuranceProgram, setInsuranceProgram] =
        useState<InsuranceProgram | undefined>(undefined);

    const [assets, setAssets] =
        useState<MediaAsset[] | undefined>(undefined);

    const [therapist, setTherapist] =
        useState<Therapist | undefined>(undefined);

    const [dispatcherConsole, setDispatcherConsole] =
        useState<DispatcherConsole | undefined>(undefined);


    const reload = () => {
        if (profile.insuranceProgram) {
            insuranceProgramService
                .find(profile.insuranceProgram)
                .then((insuranceProgram: InsuranceProgram) => {
                    setInsuranceProgram(insuranceProgram);

                    if (insuranceProgram.id) {
                        insuranceProgramService
                            .findAssets(insuranceProgram.id)
                            .then(assets => setAssets(assets));
                    }
                });
        }
        if (profile.therapist) {
            therapistService.find(profile.therapist).then((therapist: Therapist) => {
                setTherapist(therapist);
            });
        }
        if (profile.dispatcherConsole) {
            dispatcherConsoleService.find(profile.dispatcherConsole).then(dispatcherConsole => {
                setDispatcherConsole(dispatcherConsole);
            });
        }
    };

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [props.profile]);

    const userRoleTitle = () => {
        // if (isInsuredUser(user)) return "Застрахованный";
        // if (isTherapistUser(user)) return "Врач-куратор";
        // if (isAdminUser(user)) return "Администратор";
        return null;
    }

    const renderMediaAssetDownloadLinks = () => {
        return (
            <div className="flex flex-column gap-2">
                {assets?.filter(asset => asset.type === MediaAssetTypeEnumeration.INSURANCE_PROGRAM)
                    .map((asset, index) =>
                        <MediaAssetDownloadLink key={index} asset={asset}/>
                    )
                }
            </div>
        );
    };

    return (
        <Card title={userRoleTitle} className="col-12">
            <div className="card flex flex-column gap-1">
                <div className="field">
                    <div className="label">Ф.И.О.</div>
                    <div className="value">
                        <UserFullName userProfile={profile}/>
                    </div>
                </div>
                <div className="field">
                    <div className="label">Страхователь</div>
                    <div className="value">
                        {profile && profile.insurer && <>{profile.insurer}</>}
                        {!profile?.insurer && "Не назначен"}
                    </div>
                </div>
                {appSettings.insurancePolicyTemplate &&
                    appSettings.insurancePolicyFilename &&
                    profile.insurancePolicyNumber &&

                    <div className="field">
                        <div className="label">Номер страхового полиса</div>
                        <div className="value"><InsurancePolicyLink profile={profile}/></div>
                    </div>
                }
                <div className="field">
                    <div className="label">Период страхования</div>
                    <div className="value">{
                        profile &&
                        profile.insurancePeriodStartDate &&
                        profile.insurancePeriodEndDate &&
                        <FormattedDateRange start={profile.insurancePeriodStartDate}
                                            end={profile.insurancePeriodEndDate}/>
                    }{(!profile?.insurancePeriodStartDate || !profile.insurancePeriodEndDate) &&
                        "Не назначен"
                    }</div>
                </div>
                <div className="field">
                    <div className="label">Программа страхования</div>
                    <div className="value">
                        {renderMediaAssetDownloadLinks()}
                        {!insuranceProgram && "Не назначена"}
                    </div>
                </div>
                <div className="field">
                    <div className="label">Врач-куратор</div>
                    <div className="value">
                        {therapist &&
                            <><TherapistFullName therapist={therapist}/></>
                        }
                        {!therapist && "Не назначен"}
                    </div>
                </div>
                <div className="field">
                    <div className="label">Телефон диспетчерского пульта</div>
                    <div className="value">
                        {dispatcherConsole &&
                            <>
                                <PhoneOutput number={dispatcherConsole.phone} link/><br/>
                                <span className="note">Работает круглосуточно</span>
                            </>
                        }
                        {!dispatcherConsole && "Не назначен"}
                    </div>
                </div>
                <div className="field">
                    <div className="label">Управление медицинского страхования</div>
                    <div className="value">
                        {dispatcherConsole &&
                            <>
                                <PhoneOutput number={"4957870424"} link/><br/>
                                <span className="note">Будние дни, с 9:00 до 18:00</span>
                            </>
                        }
                        {!dispatcherConsole && "Не назначен"}
                    </div>
                </div>
            </div>
        </Card>
    );
};