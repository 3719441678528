import {InputText} from "primereact/inputtext";
import React, {useState} from "react";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {useAuth} from "../security/AuthProvider";
import {Message} from "primereact/message";
import {Controller, useForm} from "react-hook-form";
import {classNames} from "primereact/utils";
import {useMountEffect} from "primereact/hooks";
import {useParams} from "react-router-dom";
import {Dialog} from "primereact/dialog";
import Registration from "../components/Registration";
import {useAppSettings} from "../providers/AppSettingsProvider";
import PasswordReset from "../components/PasswordReset";

interface LoginData {
    login: string;
    password: string;
}

export default function LoginPage() {

    const [registrationDialogVisible, setRegistrationDialogVisible] =
        useState<boolean>(false);

    const [passwordResetDialogVisible, setPasswordResetDialogVisible] =
        useState<boolean>(false);

    const defaultValues: LoginData = {
        login: '',
        password: ''
    };

    const params = useParams();
    const {login} = useAuth();
    const {appSettings} = useAppSettings();
    //const [formData, setFormData] = useState<LoginData>(defaultValues);
    const {
        control,
        formState: {errors},
        handleSubmit,
        reset
    } = useForm({defaultValues});


    useMountEffect(() => {
        if (params && params.action && params.action === "clean") {
            localStorage.clear();
            window.location.replace('/login');
        }
    });

    const onSubmit = (data: LoginData) => {
        login(data.login, data.password);
        reset();
    }

    const getFormErrorMessage = (name: string) => {
        const key = name as keyof LoginData;
        return errors[key] && <Message severity="error" text={errors[key]?.message}/>
    };

    const registrationLinkHandler = () => {
        setRegistrationDialogVisible(true);
    };

    const onRegistrationDialogHide = () => {
        setRegistrationDialogVisible(false);
    }

    const onRegistrationAccept = () => {
        setRegistrationDialogVisible(false);
    }

    const onRegistrationReject = () => {
        setRegistrationDialogVisible(false);
    }


    return (
        <>
            <div className="container login-wrapper m-auto">
                <div className="card">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex justify-content-center flex-column gap-3">
                            {/*{error &&
                            <div className="field"><Message severity={"error"} text={error}/></div>
                        }*/}
                            <div className="field flex flex-column gap-1">
                            <span className="p-float-label">
                                <Controller name="login"
                                            control={control}
                                            rules={{required: 'Номер страхового полиса.'}}
                                            render={({field, fieldState}) => (
                                                <>
                                                    <InputText id={field.name} {...field}
                                                               autoFocus
                                                               className={classNames({'p-invalid': fieldState.invalid})}
                                                    />
                                                    <label htmlFor={field.name}>Email / Номер полиса</label>
                                                </>
                                            )}/>
                            </span>
                                {getFormErrorMessage('login')}
                                {/*<small>Введите номер страхового полиса.</small>*/}
                            </div>
                            <div className="field flex flex-column gap-1">
                            <span className="p-float-label">
                                <Controller name="password"
                                            control={control}
                                            rules={{required: 'Введите пароль.'}}
                                            render={({field, fieldState}) => (
                                                <>
                                                    <Password id={field.name} {...field}
                                                              feedback={false}
                                                              toggleMask
                                                              className={classNames({'p-invalid': fieldState.invalid})}
                                                        /*header={passwordHeader}
                                                        footer={passwordFooter}*/
                                                    />
                                                    <label htmlFor={field.name}>Пароль</label>
                                                </>
                                            )}/>
                            </span>
                                {getFormErrorMessage('password')}
                            </div>
                            <div className="field flex flex-row flex-wrap md:justify-content-between gap-3">
                                <Button type="submit"
                                        label="Войти"
                                        className="flex-grow-1"
                                />
                                {appSettings.registrationEnabled &&
                                    <>
                                        <Button type="button"
                                                label="Регистрация"
                                                link
                                                onClick={registrationLinkHandler}
                                                className="flex-grow-1"/>
                                        {/*<Button type="button"
                                                label="Забыли пароль?"
                                                link
                                                onClick={() => setPasswordResetDialogVisible(true)}
                                                className={"flex-grow-1"}/>*/}
                                    </>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <Dialog header="Регистрация пользователя"
                    visible={registrationDialogVisible}
                    onHide={onRegistrationDialogHide}
                    className="app-registration-dialog">
                <Registration onAccept={onRegistrationAccept}
                              onReject={onRegistrationReject}/>
            </Dialog>

            <Dialog header="Восстановление пароля"
                    visible={passwordResetDialogVisible}
                    onHide={() => setPasswordResetDialogVisible(false)}
                    className="app-registration-dialog">
                <PasswordReset/>
            </Dialog>
        </>
    );
};