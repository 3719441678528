import {documentTemplateService} from "../index";
import React from "react";
import {Link} from "react-router-dom";
import {errorMessage} from "../helpers/axiosError";
import useUserNotification from "../hooks/useUserNotification";
import {useAppSettings} from "../providers/AppSettingsProvider";
import UserProfile from "../interfaces/UserProfile.interface";
import {useAuth} from "../security/AuthProvider";

interface InsurancePolicyLinkProps {
    profile?: UserProfile | undefined | null;
}

export type Props = InsurancePolicyLinkProps;

export default function InsurancePolicyLink(props: Props) {

    const {showError} = useUserNotification();
    const {appSettings} = useAppSettings();
    const {isAdmin, isUserManagement} = useAuth();

    const downloadHandler = () => {
        let promise;

        if (!(isAdmin() || isUserManagement())) {
            promise = documentTemplateService
                .substituteInsurancePolicyDownload(appSettings.insurancePolicyFilename)
                .then();

        } else if (props.profile && props.profile.id) {
            promise = documentTemplateService
                .substituteInsurancePolicyDownloadByProfile(props.profile.id, appSettings.insurancePolicyFilename)
                .then();
        }

        if (promise) promise.catch(reason => {
            console.error(reason);
            showError({
                summary: "Загрузка страхового полиса",
                detail: "Системная ошибка, обратитесь пожалуйста в техническую поддержку. "
                    + errorMessage(reason)
            });
        });
    };

    if (props.profile && props.profile.insurancePolicyNumber && appSettings.insurancePolicyFilename) {
        return (
            <Link to={""}
                  onClick={downloadHandler}
                  title=""><i className="pi pi-paperclip"></i> {props.profile.insurancePolicyNumber}
            </Link>
        );
    }

    return null;
}