import {useUpdateEffect} from "primereact/hooks";
import {useState} from "react";
import UserProfile from "../../interfaces/UserProfile.interface";
import {userProfileService} from "../../index";
import UserFullName from "../UserFullName";

interface UserProfileFullNameOutputProps {
    profileId: number | undefined | null;
}

export default function UserProfileFullNameOutput({profileId}: UserProfileFullNameOutputProps) {

    const [profile, setProfile] =
        useState<UserProfile | undefined>(undefined);

    useUpdateEffect(() => {
        if (profileId) {
            userProfileService.find(profileId)
                .then(response => setProfile(response))
                .catch(reason => console.error(reason));
        }
    }, [profileId]);

    if (profile) {
        return <UserFullName userProfile={profile}/>;
    }
    return null;
};