import TableState from "../../../interfaces/TableState";
import {Sorting, SortOrder} from "../../../service/BaseDataService";

export default function tableStateToSorting(tableState: TableState): Sorting | undefined {
    if (tableState.sorting.sortField) {
        let order: SortOrder | undefined = undefined;
        if (tableState.sorting.sortOrder) {
            switch (tableState.sorting.sortOrder) {
                case 1:
                    order = "asc";
                    break;
                case -1:
                    order = "desc";
                    break;
                default:
                    throw new Error("Порядок сортировки не определёен.");
            }
        }
        return {
            field: tableState.sorting.sortField,
            order: order
        }
    }
    return undefined;
}