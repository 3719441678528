import {Card} from "primereact/card";
import React, {useState} from "react";
import {useAuth} from "../security/AuthProvider";
import {findRoleType, RoleType} from "../config";
import {useMountEffect} from "primereact/hooks";
import {dashboardService} from "../service/DashboardService";

export default function DebugInfo() {
    const [processId, setProcessId] =
        useState<number | undefined>(undefined);

    const {user, userProfile, isAdmin} = useAuth();


    const reload = () => {
        if (isAdmin()) {
            dashboardService.processId().then(pid => setProcessId(pid));
        }
    }

    useMountEffect(() => reload());


    return (
        <div className="grid">
            <Card title="Пользователь" className="col-12 md:col-4">
                <ul>
                    <li>username: {user.username}</li>
                    <li>firstName: {userProfile.firstName}</li>
                    <li>secondName: {userProfile.secondName}</li>
                    <li>thirdName: {userProfile.thirdName}</li>
                </ul>
            </Card>

            <Card title="Роли пользователя" className="col-12 md:col-4">
                <ul>
                    {user.roles && user.roles.map((roleId, index) => {
                        let roleType: RoleType | undefined = findRoleType(roleId);
                        return <li key={index}>({roleType?.code}) {roleType?.name}</li>;
                    })}
                </ul>
            </Card>

            <Card title="Система" className="col-12 md:col-4">
                <ul>
                    <li>Версия {process.env.REACT_APP_APP_VERSION}</li>
                    <li>Номер процесса: {processId}</li>
                </ul>
            </Card>
        </div>
    );
};