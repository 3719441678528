import {API_SETTINGS_URL} from "../config";
import axios, {AxiosResponse} from "axios";
import {Settings} from "../interfaces/Settings.interface";


export default class SettingsService {

    url: string = API_SETTINGS_URL;

    async find(): Promise<Settings> {
        const response: AxiosResponse<Settings> = await axios.get<Settings>(this.url);
        return response.data;
    }

    async update(settings: Settings): Promise<AxiosResponse<any>> {
        return await axios.post(API_SETTINGS_URL, settings);
    }
}