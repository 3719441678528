import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";

import {TicketStatus} from "../../interfaces/enum/TicketStatus";
import {ticketStatusTitles} from "../../interfaces/dictionaries/TicketStatusTitles";

interface TicketStatusDropdownProps {
    id: string;
    status: TicketStatus | undefined;

    onStatusSelect?(status: TicketStatus): void
}

export default function TicketStatusDropdown(props: TicketStatusDropdownProps) {
    return (
        <Dropdown id={props.id}
                  value={props.status}
                  options={ticketStatusTitles}
                  optionValue="code"
                  optionLabel="title"
                  required={true}
                  onChange={(e: DropdownChangeEvent) => props.onStatusSelect && props.onStatusSelect(e.value)}
                  placeholder="Выберите статус обращения"
                  className="w-full"
        />
    );
};