import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import React from "react";
import {
    NotificationRecipientCommunicationType
} from "../../../interfaces/notification/NotificationRecipientCommunicationType";
import {
    notificationRecipientCommunicationTypeTitles
} from "../../../interfaces/dictionaries/NotificationRecipientCommunicationTypeTitles";

interface NotificationRecipientCommunicationTypeDropdownProps {
    id?: string | undefined;
    value: NotificationRecipientCommunicationType | undefined;

    onChange(type: NotificationRecipientCommunicationType): void;
}

export default function NotificationRecipientCommunicationTypeDropdown(props: NotificationRecipientCommunicationTypeDropdownProps) {
    return <Dropdown id={props.id}
                     value={props.value}
                     options={notificationRecipientCommunicationTypeTitles}
                     optionValue="code"
                     optionLabel="title"
                     onChange={(e: DropdownChangeEvent) => props.onChange(e.value)}
                     emptyMessage="Нет зарегистрированных коммуникационных каналов."
                     placeholder="Выберите коммуникационный канал"/>;
}