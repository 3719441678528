import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {userService} from "../index";
import {errorMessage} from "../helpers/axiosError";
import React, {useState} from "react";
import {useAuth} from "../security/AuthProvider";
import useUserNotification from "../hooks/useUserNotification";

export default function PasswordPage() {

    const {user} = useAuth();
    const {showSuccess, showError} = useUserNotification();

    const [busy, setBusy] =
        useState<boolean>(false);

    const [currentPassword, setCurrentPassword] =
        useState<string>("");

    const [newPassword, setNewPassword] =
        useState<string>("");

    const [newPasswordConfirmation, setNewPasswordConfirmation] =
        useState<string>("");

    const changePassword = () => {
        if (user.id &&
            currentPassword && currentPassword.length > 0 &&
            newPassword && newPassword.length > 0 &&
            newPasswordConfirmation && newPasswordConfirmation.length > 0) {

            if (newPassword !== newPasswordConfirmation) {
                showError({
                    summary: "Изменение пароля",
                    detail: "Новый пароль и контрольное значение не совпадают. Попробуйте ещё раз."
                });
                return;
            }

            setBusy(true);

            userService.changePassword(user.id, currentPassword, newPassword)
                .then(() => {
                    showSuccess({
                        summary: "Изменение пароля",
                        detail: `Пароль успешно изменён на новый.`
                    });
                })
                .catch(reason => showError({
                    summary: "Изменение пароля",
                    detail: errorMessage(reason)
                }))
                .finally(() => {
                    setCurrentPassword("");
                    setNewPassword("");
                    setNewPasswordConfirmation("");
                    setBusy(false);
                });
        }
    };

    return (
        <div className="flex flex-column gap-4 m-auto py-3">
            <div className="flex flex-column gap-2">
                <label htmlFor="currentPassword" className="font-bold">Текущий пароль</label>
                <Password id="currentPassword"
                          value={currentPassword}
                          disabled={busy}
                          onChange={(e) => setCurrentPassword(e.target.value)}/>
            </div>

            <div className="flex flex-column gap-2">
                <label htmlFor="newPassword" className="font-bold">Новый пароль</label>
                <Password id="newPassword"
                          value={newPassword}
                          disabled={busy}
                          onChange={(e) => setNewPassword(e.target.value)}/>
            </div>

            <div className="flex flex-column gap-2">
                <label htmlFor="newPasswordConfirmation" className="font-bold">Повторите новый пароль</label>
                <Password id="newPasswordConfirmation"
                          value={newPasswordConfirmation}
                          disabled={busy}
                          onChange={(e) => setNewPasswordConfirmation(e.target.value)}/>
            </div>

            <Button type="button"
                    label="Сменить"
                    disabled={busy}
                    onClick={changePassword}/>
        </div>
    );
}