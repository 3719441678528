import TemporalUpdatableEntity from "../TemporalUpdatableEntity";

export default interface MessageTemplate extends TemporalUpdatableEntity {
    name: string;
    text: string;
    mode?: MessageTemplateMode | undefined | null;
    taxonomy: number | null;
    notes: string | undefined;
}

export enum MessageTemplateMode {
    HTML = "HTML",
    XML = "XML",
    TEXT = "TEXT",
    JAVASCRIPT = "JAVASCRIPT",
    CSS = "CSS",
    RAW = "RAW"
}

export const initialMessageTemplate: MessageTemplate = {
    id: null,
    name: "",
    text: "",
    mode: MessageTemplateMode.HTML,
    taxonomy: null,
    notes: undefined,
}