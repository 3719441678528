import {DateTime} from "luxon";

export const dfs = (date: string | null): string | null => {
    return date ? df(new Date(date)) : null;
}

export const df = (date: Date | null): string | null => {
    return date == null ? null : new Date(date).toLocaleDateString("ru-ru");
}

export const dtf = (date: Date | null): string | null => {
    return date == null ? null : new Date(date).toLocaleTimeString("ru-ru", {
        year: "numeric", month: "numeric", day: "numeric",
        hour: "2-digit", minute: "2-digit", second: "2-digit"
    });
}

export function ldtf(dateTimeString: string | null | undefined) {
    return dateTimeString ? DateTime
        .fromISO(dateTimeString)
        .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : null;
}

export function ldf(dateTimeString: string | null | undefined) {
    return dateTimeString ? DateTime
        .fromISO(dateTimeString)
        .toLocaleString(DateTime.DATE_SHORT) : null;
}

/**
 * Функция преобразует стандартную Javascript дату к строковому ISO формату,
 * пригодному для передачи на бэкенд. Метод toISOString() js даты возвращает меньшее значение
 * за счет преобразования часового пояса к UTC.
 * Метод удобен для использования в календарях.
 * @param date дата стандартного типа JavaScript.
 */
export function jsToIso(date: Date | undefined | null): string | undefined {
    let iso = undefined;
    if (date) iso = DateTime.fromJSDate(date).toISO({format: "extended", includeOffset: false});
    return iso ? iso : undefined;
}

export function isoToJs(date: string | undefined | null): Date | null {
    return date ? DateTime.fromISO(date).toJSDate() : null;
}