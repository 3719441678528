import {Button, ButtonProps} from "primereact/button";
import React from "react";

interface Props extends ButtonProps {

}

export default function DeleteButton(props: Props) {
    return <Button icon="pi pi-fw pi-minus"
                   type="button"
                   tooltip="Удалить выбранные строки"
                   tooltipOptions={{position: 'top'}}
                   {...props}
    />
}