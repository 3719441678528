import {
    anonymousUser,
    ROLE_ADMINISTRATOR,
    ROLE_ANONYMOUS,
    ROLE_INSURED,
    ROLE_REGISTERED_USER,
    ROLE_THERAPIST,
    ROLE_USER_MANAGEMENT,
    RoleType
} from "../config";
import User from "../interfaces/User.interface";

export function userHasRole(user: User | null | undefined, role: RoleType): boolean {
    if (user === null || user === undefined) return false;
    return user.roles.some(roleId => roleId === role.code);
}

export function isAnonymousUser(user: User | null | undefined): boolean {
    return user === null || user === undefined || user === anonymousUser || userHasRole(user, ROLE_ANONYMOUS);
};

export function isRegisteredUser(user: User | null | undefined): boolean {
    return userHasRole(user, ROLE_REGISTERED_USER);
}

export function isTherapistUser(user: User | null | undefined): boolean {
    return userHasRole(user, ROLE_THERAPIST);
};

export function isUserManagementUser(user: User | null | undefined): boolean {
    return userHasRole(user, ROLE_USER_MANAGEMENT);
};

export function isAdminUser(user: User | null | undefined): boolean {
    return userHasRole(user, ROLE_ADMINISTRATOR);
};

export function isInsuredUser(user: User | null | undefined): boolean {
    return userHasRole(user, ROLE_INSURED);
}