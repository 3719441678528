import {createContext, useContext, useState} from "react";
import {useMountEffect} from "primereact/hooks";
import {errorMessage} from "../helpers/axiosError";
import {initialSettings, Settings} from "../interfaces/Settings.interface";
import {ApiErrorResponse} from "../interfaces/response.interface";
import {AxiosError} from "axios";
import {settingsService} from "../index";
import useUserNotification from "../hooks/useUserNotification";
import {useAuth} from "../security/AuthProvider";

export type AppSettingsType = {
    appSettings: Settings;
    setAppSettings(settings: Settings): void;
    reload(): void;
    save(): void;
}

export const AppSettingsContext: React.Context<AppSettingsType> = createContext<AppSettingsType>({
    appSettings: initialSettings,
    setAppSettings(settings: Settings) {
    },
    reload() {
    },
    save() {
    },
});

export default function AppSettingsProvider({children}: any) {

    const [appSettingsState, setAppSettingsState] =
        useState<Settings>(initialSettings);

    const {isAdmin} = useAuth();
    const {showSuccess, showError} = useUserNotification();

    const reload = () => {
        settingsService.find()
            .then(appSettings => setAppSettingsState(appSettings))
            .catch((reason: AxiosError<ApiErrorResponse>) =>
                showError({
                    summary: "Загрузка настроек",
                    detail: errorMessage(reason)
                }));
    };

    useMountEffect(() => {
        reload();
    });

    const saveAppSettings = () => {
        if (isAdmin()) {
            settingsService.update(appSettingsState)
                .then(response => {
                    setAppSettingsState(response.data);
                    showSuccess({
                        summary: "Сохранение настроек",
                        detail: "Настройки системы успешно сохранены."
                    });
                })
                .catch((reason: AxiosError<ApiErrorResponse>) =>
                    showError({
                        summary: "Сохранение настроек",
                        detail: errorMessage(reason)
                    }));
        } else {
            showError({
                summary: "Сохранение настроек",
                detail: "Пользователь не обладает правами доступа сохранения настроек."
            });
        }
    }

    const contextValue: AppSettingsType = {
        appSettings: appSettingsState,
        setAppSettings: (appSettings: Settings) => setAppSettingsState(appSettings),
        save: () => saveAppSettings(),
        reload: () => reload()
    };

    return (
        <AppSettingsContext.Provider value={contextValue}>
            {children}
        </AppSettingsContext.Provider>
    );
}

export const useAppSettings = () => {
    return useContext(AppSettingsContext);
}