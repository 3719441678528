import {API_URL} from "../config";
import axios, {AxiosResponse} from "axios";
import JwtAuthenticationResponse from "../interfaces/auth/JwtAuthenticationResponse";
import {SuccessfulResponse} from "../interfaces/response/SuccessfulResponse";
import User from "../interfaces/User.interface";

export class RegistrationService {

    url = API_URL + "registration"

    /**
     * Возвращает проверочный verificationHashCode хэш код.
     * @param email адрес электронной почты
     */
    async emailVerificationStart(email: string): Promise<string> {

        const response: AxiosResponse<string> = await axios.get<string>(
            `${this.url}/email/${email}/code/request`);
        return response.data;
    }

    /**
     * Возвращает хэш код пользователя userRegistrationHashCode.
     * @param verificationHashCode ранее полученный хэш код запроса пароля.
     * @param verificationCode проверочный код контакта, отправленный на конечное устройство пользователя.
     */
    async emailVerificationCodeCheck(
        verificationHashCode: string,
        verificationCode: string): Promise<string> {

        const url = encodeURI(`${this.url}/email/${verificationHashCode}/${verificationCode}/code/verify`);
        const response: AxiosResponse<string> = await axios.get<string>(url);
        return response.data;
    }

    async requestUser(
        userRegistrationHashCode: string,
        insurancePolicyNumber: string): Promise<User> {

        const url = encodeURI(`${this.url}/request/user/${userRegistrationHashCode}/${insurancePolicyNumber}`);
        const response: AxiosResponse<User> = await axios.get<User>(url);
        return response.data;
    }

    async acceptUserRegistration(
        verificationHashCode: string,
        userRegistrationHashCode: string,
        password: string): Promise<JwtAuthenticationResponse> {

        const url = encodeURI(`${this.url}/accept/${verificationHashCode}/${userRegistrationHashCode}/${encodeURI(password)}`);
        const response: AxiosResponse<JwtAuthenticationResponse> = await axios.get<JwtAuthenticationResponse>(url);
        return response.data;
    }

    async rejectUserRegistration(
        verificationHashCode: string,
        userRegistrationHashCode: string): Promise<SuccessfulResponse> {

        const url = encodeURI(`${this.url}/reject/${verificationHashCode}/${userRegistrationHashCode}`);
        const response: AxiosResponse<SuccessfulResponse> = await axios.get<SuccessfulResponse>(url);
        return response.data;
    }
}