import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import React from "react";
import {OrganizationStatus} from "../interfaces/enum/OrganizationStatus";
import {organizationStatusTitles} from "../interfaces/dictionaries/OrganizationStatusTitles";

interface OrganizationDropdownProps {
    id?: string | undefined;
    value: OrganizationStatus | undefined;

    onChange(status: OrganizationStatus): void;
}

export default function OrganizationStatusDropdown(props: OrganizationDropdownProps) {
    return <Dropdown id={props.id}
                     value={props.value}
                     options={organizationStatusTitles}
                     optionValue="code"
                     optionLabel="title"
                     onChange={(e: DropdownChangeEvent) => props.onChange(e.value)}
                     emptyMessage="Нет зарегистрированных статусов организации"
                     placeholder="Выберите статус организации"/>;
}