import {Button, ButtonProps} from "primereact/button";
import React from "react";

interface Props extends ButtonProps {

}

export default function DownloadButton(props: Props) {
    return <Button icon="pi pi-fw pi-download"
                   type="button"
                   tooltip="Скачать файл"
                   tooltipOptions={{position: 'top'}}
                   {...props}
    />
}