import React, {useState} from "react";
import {Card} from "primereact/card";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import {Fieldset} from "primereact/fieldset";
import {MediaAsset} from "../../../interfaces/MediaAsset";
import SaveButton from "../../../components/common/buttons/SaveButton";
import {mailService} from "../../../index";
import useUserNotification from "../../../hooks/useUserNotification";
import {errorMessage} from "../../../helpers/axiosError";
import {BlockUI} from "primereact/blockui";
import {Checkbox} from "primereact/checkbox";
import {useAppSettings} from "../../../providers/AppSettingsProvider";
import {InputText} from "primereact/inputtext";
import TaxonomySelect from "../../../components/common/taxonomy/TaxonomySelect";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import MessageTemplateDropdown from "../../../components/common/MessageTemplateDropdown";
import MediaAssetChooser from "../../../components/common/mediaAsset/MediaAssetChooser";
import {TabPanel, TabView} from "primereact/tabview";
import {InputNumber} from "primereact/inputnumber";
import {dashboardService} from "../../../service/DashboardService";
import {maintenanceService} from "../../../service/MaintenanceService";

export default function SettingsPage() {

    const [busy, setBusy] =
        useState(false);

    const [to, setTo] =
        useState<string>("");

    const [text, setText] =
        useState<string>("");

    const [processId, setProcessId] =
        useState<number | undefined>(undefined);

    const {showError, showSuccess} = useUserNotification();
    const {appSettings, setAppSettings, save} = useAppSettings();

    const reload = () => {
        dashboardService.processId().then(pid => setProcessId(pid));
    }


    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [appSettings]);


    const sendTestMailAction = () => {
        mailService.send(to, text)
            .then(() => {
                showSuccess({
                    summary: "Отправка сообщения",
                    detail: `Сообщение '${text}' адресату ${to} успешно отправлено.`
                });
            })
            .catch(reason => {
                showError({
                    summary: "Отправка сообщения",
                    detail: errorMessage(reason)
                })
            });
    };

    return (
        <div className="container">
            <BlockUI blocked={busy}>
                <Card title="Настройки">

                    <TabView>
                        <TabPanel header="Регистрация пользователей">
                            <Fieldset legend="Регистрация пользователей">
                                <div className="flex flex-column gap-4">
                                    <div className="flex flex-column gap-2">
                                        <div>
                                            <Checkbox id="registrationEnabled"
                                                      checked={appSettings?.registrationEnabled || false}
                                                      onChange={e =>
                                                          setAppSettings({
                                                              ...appSettings,
                                                              registrationEnabled: e.checked ? e.checked : false
                                                          })}
                                            />
                                            <label htmlFor="registrationEnabled"
                                                   className="ml-2 font-bold">Сервис включен</label>
                                        </div>
                                        <span className="note">
                                            В выключенном состоянии ссылка регистрации новых пользователей не отображается.
                                            Попытка регистрации любым способом в обход ограничения завершается ошибкой.
                                        </span>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="nda">
                                            Файл политики в отношении обработки персональных данных
                                        </label>
                                        <MediaAssetChooser id="nda"
                                                           assetId={appSettings.nda} // type={assetType("NDA")}
                                                           onSelect={(asset: MediaAsset) => {
                                                               setAppSettings({
                                                                   ...appSettings,
                                                                   ...{nda: asset.id}
                                                               });
                                                           }}
                                                           onUnbind={() => {
                                                               setAppSettings({
                                                                   ...appSettings,
                                                                   nda: null
                                                               });
                                                           }}/>
                                        <div className="note">
                                            Ссылка на скачивание этого файла отображается на
                                            экране регистрации пользователя.
                                            Если файл не выбран, то отображается только текст
                                            "согласие на обработку персональных данных" без ссылки на загрузку файла.
                                        </div>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="emailVerificationCodeNotificationTemplate">
                                            Шаблон уведомления с кодом подтверждения адреса электронной почты
                                        </label>
                                        <MessageTemplateDropdown
                                            id="emailVerificationCodeNotificationTemplate"
                                            messageTemplateId={appSettings.emailVerificationCodeNotificationTemplate}
                                            onChoose={messageTemplateId =>
                                                setAppSettings({
                                                    ...appSettings,
                                                    emailVerificationCodeNotificationTemplate: messageTemplateId
                                                })}/>
                                        <span className="note">
                                            Если шаблон не назначен, уведомления этого типа отправляться не будут.
                                        </span>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="passwordChangedNotificationTemplate">
                                            Шаблон уведомления об изменении пароля
                                        </label>
                                        <MessageTemplateDropdown
                                            id="passwordChangedNotificationTemplate"
                                            messageTemplateId={appSettings.passwordChangedNotificationTemplate}
                                            onChoose={messageTemplateId =>
                                                setAppSettings({
                                                    ...appSettings,
                                                    passwordChangedNotificationTemplate: messageTemplateId
                                                })}/>
                                        <span className="note">
                                            Если шаблон не назначен, уведомления этого типа отправляться не будут.
                                        </span>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="passwordRestoreNotificationTemplate">
                                            Шаблон уведомления со ссылкой восстановления пароля
                                        </label>
                                        <MessageTemplateDropdown
                                            id="passwordRestoreNotificationTemplate"
                                            messageTemplateId={appSettings.passwordRestoreNotificationTemplate}
                                            onChoose={messageTemplateId =>
                                                setAppSettings({
                                                    ...appSettings,
                                                    passwordRestoreNotificationTemplate: messageTemplateId
                                                })}/>
                                        <span className="note">
                                            Если шаблон не назначен, уведомления этого типа отправляться не будут.
                                        </span>
                                    </div>
                                </div>
                            </Fieldset>
                        </TabPanel>

                        <TabPanel header={"Почтовая служба"}>
                            <Fieldset legend="Почтовая служба">
                                <div className="flex flex-column gap-4">
                                    <div className="flex flex-column gap-2">
                                        <div>
                                            <Checkbox id="smtpEnabled"
                                                      checked={appSettings?.smtpEnabled || false}
                                                      onChange={e =>
                                                          setAppSettings({
                                                              ...appSettings,
                                                              smtpEnabled: e.checked ? e.checked : false
                                                          })}
                                            />
                                            <label htmlFor="smtpEnabled"
                                                   className="ml-2">Отправка почты</label>
                                        </div>
                                        <span className="note">Если включено, работает отправка почты от портала в адрес получателей.</span>
                                    </div>

                                    <div className="flex flex-column gap-4">
                                        <h3>Отправка тестового сообщения</h3>
                                        <span className="p-float-label">
                                            <InputText id="to"
                                                       value={to}
                                                       disabled={!appSettings.smtpEnabled}
                                                       className="w-full"
                                                       onChange={e => setTo(e.target.value)}/>
                                            <label htmlFor="to">Кому</label>
                                        </span>
                                        <span className="p-float-label">
                                            <InputTextarea id="text"
                                                           value={text}
                                                           disabled={!appSettings.smtpEnabled}
                                                           className="w-full"
                                                           onChange={e => setText(e.target.value)}/>
                                            <label htmlFor="text">Текст сообщения</label>
                                        </span>
                                        <Button onClick={sendTestMailAction}
                                                label="Отправить"
                                                type="button"
                                                disabled={!appSettings.smtpEnabled}
                                                size="small"
                                                tooltip="Отправить"
                                                className="w-min"
                                                tooltipOptions={{position: 'top'}}/>
                                    </div>
                                </div>
                            </Fieldset>
                        </TabPanel>

                        <TabPanel header={"Шаблоны деловых документов"}>
                            <Fieldset legend="Шаблоны деловых документов">
                                <div className="flex flex-column gap-4">
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="insurancePolicyTemplate">
                                            Шаблон страхового полиса
                                        </label>
                                        <MediaAssetChooser id="insurancePolicyTemplate"
                                                           assetId={appSettings.insurancePolicyTemplate}
                                                           onSelect={(asset: MediaAsset) => {
                                                               setAppSettings({
                                                                   ...appSettings,
                                                                   ...{insurancePolicyTemplate: asset.id}
                                                               });
                                                           }}
                                                           onUnbind={() => {
                                                               setAppSettings({
                                                                   ...appSettings,
                                                                   insurancePolicyTemplate: null
                                                               });
                                                           }}/>
                                        <div className="note">
                                            Шаблон используется при формировании электронного документа полиса
                                            страхования с данными застрахованного.
                                            В момент скачивания документа производится автоматическая подстановка
                                            фактических значений застрахованного вместо строк шаблона.
                                            Результат отправляется пользователю для скачивания.
                                            Если шаблон не задан, ссылка на скачивание страхового полиса не
                                            отображается.
                                        </div>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="insurancePolicyFilename">
                                            Имя файла страхового полиса
                                        </label>
                                        <InputText id="insurancePolicyFilename"
                                                   value={appSettings.insurancePolicyFilename || ""}
                                                   onChange={(e) => {
                                                       setAppSettings({
                                                           ...appSettings,
                                                           insurancePolicyFilename: e.target.value
                                                       });
                                                   }}/>
                                        <div className="note">
                                            Имя результирующего файла страхового полиса,
                                            который отправляется на устройство пользователя.
                                            Если имя файла не задано, ссылка на скачивание страхового полиса не
                                            отображается.
                                        </div>
                                    </div>
                                </div>
                            </Fieldset>
                        </TabPanel>

                        <TabPanel header={"Обращения"}>
                            <Fieldset legend="Обращения">
                                <div className="flex flex-column gap-4">
                                    <div className="flex flex-column gap-2">
                                        <div>
                                            <Checkbox id="ticketServiceEnabled"
                                                      checked={appSettings?.ticketServiceEnabled || false}
                                                      onChange={e =>
                                                          setAppSettings({
                                                              ...appSettings,
                                                              ticketServiceEnabled: e.checked ? e.checked : false
                                                          })}
                                            />
                                            <label htmlFor="ticketServiceEnabled"
                                                   className="ml-2 font-bold">Сервис включен</label>
                                        </div>
                                        <span className="note">В выключенном состоянии пункт главного меню "Обращения" недоступен для пользователей всех категорий.
                                            При переходе на страницу обращений отображается предупреждение. Настройка не влияет на доступность подсистемы в разделе администрирования.</span>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="ticketServiceDescription"
                                                   className="font-bold">Описание сервиса</label>
                                            <InputTextarea id="ticketServiceDescription"
                                                           value={appSettings.ticketServiceDescription || ""}
                                                           autoResize
                                                           className="w-full"
                                                           onChange={e =>
                                                               setAppSettings({
                                                                   ...appSettings,
                                                                   ticketServiceDescription: e.target.value
                                                               })}
                                            />
                                        </div>
                                        <span className="note">
                                            Описание сервиса демонстрируется пользователям в
                                            форме создания нового обращения.
                                            При пустом значении поля блок описания пользователям не отображается.
                                        </span>
                                    </div>
                                    <div>
                                        <p>Адресаты уведомлений:</p>
                                        <ul>
                                            <li>Автор обращения;</li>
                                            <li>Автор сообщения;</li>
                                            <li>Врач-куратор;</li>
                                            <li>Список рассылки связанный с темой обращения.</li>
                                        </ul>
                                        <p>Условия для отправки уведомления:</p>
                                        <ul>
                                            <li>Статус пользователя: Активен;</li>
                                            <li>Статус электронной почты пользователя: Активен;</li>
                                            <li>Задан шаблон уведомлений;</li>
                                        </ul>
                                        <p>При несоблюдении одного из перечисленных условий уведомления
                                            не отправляется. В итоговом списке адресатов дублирующиеся адреса
                                            исключаются.</p>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="ticketServiceTopicTaxonomy"
                                               className="font-bold">Темы обращений</label>
                                        <TaxonomySelect id="ticketServiceTopicTaxonomy"
                                                        value={appSettings.ticketServiceTopicTaxonomy}
                                                        onChange={id =>
                                                            setAppSettings({
                                                                ...appSettings,
                                                                ticketServiceTopicTaxonomy: id
                                                            })}
                                        />
                                        <span className="note">
                                            Корневой термин таксономии из загружается перечень тем обращений.
                                            Перечень тем отображается пользователю системы на странице создания нового обращения.
                                        </span>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="ticketCreatedAuthorNotificationTemplate"
                                               className="font-bold">
                                            Шаблон уведомления автора о новом обращении
                                        </label>
                                        <MessageTemplateDropdown
                                            id="ticketCreatedAuthorNotificationTemplate"
                                            messageTemplateId={appSettings.ticketCreatedAuthorNotificationTemplate}
                                            onChoose={messageTemplateId =>
                                                setAppSettings({
                                                    ...appSettings,
                                                    ticketCreatedAuthorNotificationTemplate: messageTemplateId
                                                })}/>
                                        <span className="note">
                                            Если шаблон не назначен, уведомления этого типа отправляться не будут.
                                        </span>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="ticketCreatedTherapistNotificationTemplate"
                                               className="font-bold">
                                            Шаблон уведомления терапевта и листа рассылки о новом обращении
                                        </label>
                                        <MessageTemplateDropdown
                                            id="ticketCreatedTherapistNotificationTemplate"
                                            messageTemplateId={appSettings.ticketCreatedTherapistNotificationTemplate}
                                            onChoose={messageTemplateId =>
                                                setAppSettings({
                                                    ...appSettings,
                                                    ticketCreatedTherapistNotificationTemplate: messageTemplateId
                                                })}/>
                                        <span className="note">
                                            Если шаблон не назначен, уведомления этого типа отправляться не будут.
                                        </span>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="ticketMessageCreatedAuthorNotificationTemplate"
                                               className="font-bold">
                                            Шаблон уведомления автора о новом сообщении в обращении
                                        </label>
                                        <MessageTemplateDropdown
                                            id="ticketMessageCreatedAuthorNotificationTemplate"
                                            messageTemplateId={appSettings.ticketMessageCreatedAuthorNotificationTemplate}
                                            onChoose={messageTemplateId =>
                                                setAppSettings({
                                                    ...appSettings,
                                                    ticketMessageCreatedAuthorNotificationTemplate: messageTemplateId
                                                })}/>
                                        <span className="note">
                                            Если шаблон не назначен, уведомления этого типа отправляться не будут.
                                        </span>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="ticketMessageCreatedTherapistNotificationTemplate"
                                               className="font-bold">
                                            Шаблон уведомления терапевта о новом сообщении в обращении
                                        </label>
                                        <MessageTemplateDropdown
                                            id="ticketMessageCreatedTherapistNotificationTemplate"
                                            messageTemplateId={appSettings.ticketMessageCreatedTherapistNotificationTemplate}
                                            onChoose={messageTemplateId =>
                                                setAppSettings({
                                                    ...appSettings,
                                                    ticketMessageCreatedTherapistNotificationTemplate: messageTemplateId
                                                })}/>
                                        <span className="note">
                                            Если шаблон не назначен, уведомления этого типа отправляться не будут.
                                        </span>
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="unattendedTicketUpdatePeriod"
                                               className="font-bold">
                                            Срок Обращения
                                        </label>
                                        <InputNumber id="unattendedTicketUpdatePeriod"
                                                     value={appSettings.unattendedTicketUpdatePeriod}
                                                     onChange={(e) => {
                                                         setAppSettings({
                                                             ...appSettings,
                                                             unattendedTicketUpdatePeriod: e.value
                                                         });
                                                     }}/>
                                        <div className="note">
                                            Период времени, через который будет произведён перевод
                                            Обращения пользователя в статус Завершено,
                                            если пользователь не направлял новых Сообщений.
                                        </div>
                                    </div>
                                </div>
                            </Fieldset>
                        </TabPanel>

                        <TabPanel header={"Импорт пользователей"}>
                            <Fieldset legend="Импорт пользователей">
                                <div className="flex flex-column gap-2">
                                    <span className="p-float-label">
                                        <InputText id="userImportDateFormat"
                                                   value={appSettings?.userImportDateFormat || ""}
                                                   onChange={e =>
                                                       setAppSettings({
                                                           ...appSettings,
                                                           userImportDateFormat: e.target.value
                                                       })}
                                                   className="w-full"
                                        />
                                        <label htmlFor="ticketServiceEnabled">Формат даты</label>
                                    </span>
                                    <span className="note">
                                        Формат используется при преобразовании строки в дату. <a
                                        href="https://docs.oracle.com/javase/8/docs/api/java/time/format/DateTimeFormatter.html"
                                        target="_blank" rel="noreferrer">Параметры шаблона.</a>
                                    </span>
                                </div>
                            </Fieldset>
                        </TabPanel>

                        <TabPanel header={"Файлы"}>
                            <Fieldset legend="Загрузка">
                                <div className="flex flex-column gap-3">
                                    <div className="flex flex-column gap-1">
                                        <label htmlFor="maxFileSize">Максимальный размер загружаемого пользователем
                                            файла, байт</label>
                                        <InputNumber id="maxFileSize"
                                                     value={appSettings?.maxFileSize}
                                                     min={0}
                                                     onChange={e =>
                                                         setAppSettings({
                                                             ...appSettings,
                                                             maxFileSize: e.value
                                                         })}
                                                     className="w-full"
                                        />
                                    </div>
                                    <div className="flex flex-column gap-1">
                                        <label htmlFor="maxFilesCount">Максимальное количество файлов, загружаемых за
                                            один сеанс</label>
                                        <InputNumber id="maxFilesCount"
                                                     value={appSettings?.maxFilesCount}
                                                     min={0}
                                                     onChange={e =>
                                                         setAppSettings({
                                                             ...appSettings,
                                                             maxFilesCount: e.value
                                                         })}
                                                     className="w-full"
                                        />
                                    </div>
                                    <div className="flex flex-column gap-1">
                                        <label htmlFor="maxFilesSize">Максимальный размер всех файлов, загружаемых за
                                            один сеанс, байт</label>
                                        <InputNumber id="maxFilesSize"
                                                     value={appSettings?.maxFilesSize}
                                                     min={0}
                                                     onChange={e =>
                                                         setAppSettings({
                                                             ...appSettings,
                                                             maxFilesSize: e.value
                                                         })}
                                                     className="w-full"
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                        </TabPanel>


                        <TabPanel header={"Обслуживание"}>
                            <Fieldset legend="Обслуживание">
                                <div className="flex flex-column gap-3">
                                    <div className="flex flex-column gap-1 align-items-start">
                                        <Button type="button"
                                                label="Очистка просроченных токенов"
                                                onClick={() => {
                                                    maintenanceService.processExpiredTokens()
                                                }}
                                                className="w-auto"/>
                                        <Button type="button"
                                                label="Удаление временных медиа-активов"
                                                onClick={() => {
                                                    maintenanceService.processTemporaryMediaAsset()
                                                }}
                                                className="w-auto"/>
                                        <Button type="button"
                                                label="Актуализация статуса Обращений"
                                                onClick={() => {
                                                    maintenanceService.processUnattendedTickets()
                                                }}
                                                className="w-auto"/>
                                    </div>
                                </div>
                            </Fieldset>
                        </TabPanel>


                        {/*<TabPanel header={"Застрахованным"}>
                            <Fieldset legend="Застрахованным">
                                <div className="flex flex-column gap-2">
                                    <span className="p-float-label">
                                        <InputText id="userImportDateFormat"
                                                   value={appSettings?.templateOutputDateFormat || ""}
                                                   onChange={e =>
                                                       setAppSettings({
                                                           ...appSettings,
                                                           templateOutputDateFormat: e.target.value
                                                       })}
                                                   className="w-full"
                                        />
                                        <label htmlFor="ticketServiceEnabled"
                                               className="ml-2">Формат даты</label>
                                    </span>
                                    <span className="note">
                                        Формат используется для форматирования вывода дат в документах формируемых на основе шаблонов. <a
                                        href="https://docs.oracle.com/javase/8/docs/api/java/time/format/DateTimeFormatter.html"
                                        target="_blank" rel="noreferrer">Параметры шаблона.</a>
                                    </span>
                                </div>
                            </Fieldset>
                        </TabPanel>*/}
                    </TabView>

                    <SaveButton onClick={save} disabled={busy}/>
                </Card>
                <Card title="Приложение" className="mt-6">
                    <ul>
                        <li>Номер процесса: {processId}</li>
                        <li>Версия приложения: {process.env.REACT_APP_APP_VERSION}</li>
                    </ul>
                </Card>
            </BlockUI>
        </div>
    );
}