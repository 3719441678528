import {MessageTemplateMode} from "../../interfaces/notification/MessageTemplate";
import {Dropdown, DropdownChangeEvent, DropdownProps} from "primereact/dropdown";

interface MessageTemplateModeDropdownProps extends DropdownProps {
    mode: MessageTemplateMode | undefined | null;
    onChoose?: (mode: MessageTemplateMode | null) => void;
}

export type Props = MessageTemplateModeDropdownProps;

export default function MessageTemplateModeDropdown(props: MessageTemplateModeDropdownProps) {

    const messageTemplateModes: string[] = Object.keys(MessageTemplateMode);

    return (
        <Dropdown value={props.mode}
                  options={messageTemplateModes}
                  showClear
                  onChange={(e: DropdownChangeEvent) => props.onChoose && props.onChoose(e.value)}
                  placeholder="Выберите режим шаблона сообщений"
                  className="w-full"
                  {...props}
        />
    );
}