import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import React from "react";
import ColumnMeta from "../../../interfaces/ColumnMeta";

interface ColumnToggleProps {
    columns: ColumnMeta[];
    visibleFields: string[];

    onColumnToggle(visibleFields: string[]): void;
}

export default function ColumnToggle(props: ColumnToggleProps) {
    const onColumnToggle = (event: MultiSelectChangeEvent) => {
        let selectedColFields: string[] = event.value;
        let orderedColFields: string[] = props.columns
            .filter(col => selectedColFields.some(sField => sField === col.field))
            .map(col => col.field);

        props.onColumnToggle(orderedColFields);
    };

    return <MultiSelect value={props.visibleFields}
                        options={props.columns} optionLabel="header" optionValue="field"
                        onChange={onColumnToggle}
                        display="chip"
                        className="w-20rem"
    />
}

export function visibleOnly(fields: string[], columns: ColumnMeta[]) {
    return columns
        .filter(c => fields.some(f => f === c.field))
        .map(col => col.column);
}