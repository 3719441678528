import {useAuth} from "./AuthProvider";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import Unauthorized from "./Unauthorized";
import {anonymousUser, RoleType} from "../config";

export interface AuthorizationProps {
    allowedRoles: RoleType[];
}

const Authorization = (props: AuthorizationProps) => {
    const {user} = useAuth();
    const location = useLocation();

    if (user && user !== anonymousUser) {
        const isAllowed: boolean = user.roles.some(userRoleId =>
            props.allowedRoles.find(allowed => allowed.code === userRoleId));

        return isAllowed ? <Outlet/> : <Unauthorized/>;
    }

    return <Navigate to="/login"
                     state={{path: location.pathname}}
                     replace/>;
};


export default Authorization;