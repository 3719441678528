import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import React, {useState} from "react";
import {EntityDialogState} from "../../../components/common/dialog/EntityDialogState";
import Taxonomy from "../../../interfaces/Taxonomy";
import useUserNotification from "../../../hooks/useUserNotification";
import {useUpdateEffect} from "primereact/hooks";
import {Paging} from "../../../service/BaseDataService";
import {MAX_INT} from "../../../config";
import {taxonomyService} from "../../../index";
import ApiResponse from "../../../interfaces/response.interface";
import {TreeNode} from "primereact/treenode";
import toTreeNodes from "../../../helpers/toTreeNodes";
import {errorMessage} from "../../../helpers/axiosError";
import SaveButton from "../../../components/common/buttons/SaveButton";
import CancelButton from "../../../components/common/buttons/CancelButton";
import {BlockUI} from "primereact/blockui";
import TaxonomySelect from "../../../components/common/taxonomy/TaxonomySelect";


export interface TaxonomyDialogState extends EntityDialogState<Taxonomy> {
}

interface Props {
    dialogState: TaxonomyDialogState;

    onHide(): void;

    onCancel(): void;

    onSave(): void;
}

export default function TaxonomyPropertiesDialog(props: Props) {
    const title = props.dialogState.mode === "create" ?
        "Регистрация нового термина" :
        "Редактирование термина " + props.dialogState.entity.name;

    const [busy, setBusy] =
        useState<boolean>(false);

    const [editedTaxonomy, setEditedTaxonomy] =
        useState<Taxonomy>(props.dialogState.entity);

    // const [treeNodes, setTreeNodes] =
    //     useState<TreeNode[]>([]);

    const {showError, showSuccess} = useUserNotification();

    useUpdateEffect(() => {
        setEditedTaxonomy(props.dialogState.entity);
        reload();
    }, [props.dialogState.entity]);

    const reload = () => {
        setBusy(true);

        const paging: Paging = {
            page: 0,
            rows: MAX_INT
        };

        taxonomyService.findAll(undefined, paging)
            .then((response: ApiResponse<Taxonomy>) => {
                const treeNodes: TreeNode[] = [];
                toTreeNodes(response.content, treeNodes, null)
                // setTreeNodes(treeNodes);
            })
            .catch(reason => {
                // setTreeNodes([]);
                showError({
                    summary: "Загрузка таксономии",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
            });
    };

    const update = () => {
        setBusy(true);

        if (props.dialogState.mode === "create") {
            taxonomyService.create(editedTaxonomy)
                .then(() => {
                    showSuccess({
                        summary: title,
                        detail: `Термин ${editedTaxonomy.name} успешно зарегистрирован в системе.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => {
                    showError({
                        summary: title,
                        detail: errorMessage(reason)
                    });
                })
                .finally(() => {
                    setBusy(false);
                });

        } else if (editedTaxonomy.id) {
            taxonomyService.update(editedTaxonomy.id, editedTaxonomy)
                .then(() => {
                    showSuccess({
                        summary: title,
                        detail: `Регистрационные данные термина '${editedTaxonomy.name}' успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => {
                    showError({
                        summary: title,
                        detail: errorMessage(reason)
                    });
                })
                .finally(() => {
                    setBusy(false);
                })
        }
    };

    const footer = (
        <div>
            <SaveButton onClick={update} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );


    return (
        <BlockUI blocked={busy}>
            <Dialog header={title}
                    visible={props.dialogState.visible}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-dialog">
                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="title" className="font-bold">Название</label>
                        <InputText id="title" value={editedTaxonomy.name ? editedTaxonomy.name : ""}
                                   required={true}
                                   onChange={(e) => {
                                       setEditedTaxonomy(prev => ({
                                           ...prev,
                                           name: e.target.value
                                       }))
                                   }}/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="type" className="font-bold">Родительский элемент</label>
                        <TaxonomySelect value={editedTaxonomy.parent ? editedTaxonomy.parent : null}
                                        onChange={(id) => setEditedTaxonomy(prev => ({
                                            ...prev,
                                            parent: id
                                        }))}/>
                        <small>Если оставить поле пустым, то термин будет корневым.</small>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="type" className="font-bold">Тип</label>
                        <InputText id="type"
                                   value={editedTaxonomy.type ? editedTaxonomy.type : ""}
                                   required={false}
                                   onChange={(e) => {
                                       setEditedTaxonomy(prev => ({
                                           ...prev,
                                           type: e.target.value
                                       }))
                                   }}/>
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    );
}