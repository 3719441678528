import {InputText} from "primereact/inputtext";
import {ColumnFilterElementTemplateOptions} from "primereact/column";
import {InputNumber, InputNumberChangeEvent} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import React, {ChangeEvent} from "react";
import {FormEvent} from "primereact/ts-helpers";
import {Button} from "primereact/button";
import {DateTime} from "luxon";

export const textFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return <InputText value={options.value ? options.value : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          options.filterCallback(e.target.value, options.index)
                      }}
    />;
}

export const numberFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return <InputNumber value={options.value ? options.value : ''}
                        onChange={(e: InputNumberChangeEvent) => {
                            //console.log("InputNumber.onChange:", e.value, options.index);
                            options.filterApplyCallback(e.value, options.index);
                        }}
    />;
}

export const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return <Calendar value={options.value}
                     onChange={(e) => {
                         // e.value type Date
                         options.filterCallback(e.value, options.index);
                     }}
                     required
                     dateFormat="dd.mm.yy"
                     placeholder="dd.mm.yy"
        // Не работает, если дата = null
        //mask="99.99.9999"
                     inline
    />
}

const onFilterDateRangeChange = (options: ColumnFilterElementTemplateOptions,
                                 event: FormEvent<Date, React.SyntheticEvent<Element, Event>>) => {
    //console.log(options);
    options.filterCallback(event.value, options.index);
    // options.filterCallback(event.value, 0); // Дата и время начала
    // options.filterCallback(event.value, 1); // Дата и время окончания
}

export const dateTimeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return <Calendar value={options.value}
                     onChange={(e) => options.filterCallback(e.value, options.index)}
                     dateFormat="dd/mm/yy" showTime showSeconds hourFormat="24"
        // Не работает, если дата = null
        // mask="99/99/9999 99:99:99"
                     placeholder="дд/мм/гггг чч:мм:сс"
                     inline
    />
}

export const dateTimeRangeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    const today: DateTime = DateTime.now();
    const dateTime: DateTime = options.value ?
        DateTime.fromJSDate(options.value) :
        today;

    const quickBtn = (label: string, title: string, quickDateTime: DateTime) => {
        return <Button label={label} title={title} size={"small"}
                       onClick={() => options.filterCallback(quickDateTime.toJSDate(), options.index)}/>;
    }

    const todayBtn = quickBtn("С", "Сегодня", today);
    const minusMonthBtn = quickBtn("-1М", "На месяц раньше", dateTime.minus({month: 1}));
    const minusDayBtn = quickBtn("-1Д", "На день раньше", dateTime.minus({day: 1}));
    const minusHour = quickBtn("-1Ч", "На час раньше", dateTime.minus({hour: 1}));

    return (
        <div className="flex flex-column gap-2">
            <div className="flex flex-column flex-nowrap gap-1">
                {options.index === 0 &&
                    <>
                        <div className="flex flex-row gap-1">
                            {todayBtn}
                            {quickBtn("НД", "Начало дня", dateTime.startOf("day"))}
                            {quickBtn("НЧ", "Начало часа", dateTime.startOf("hour"))}
                        </div>
                        <div className="flex flex-row gap-1">
                            {minusMonthBtn}
                            {minusDayBtn}
                            {minusHour}
                        </div>
                    </>
                }
                {options.index === 1 &&
                    <>
                        <div className="flex flex-row gap-1">
                            {todayBtn}
                            {quickBtn("КД", "Конец дня", dateTime.endOf("day"))}
                            {quickBtn("КЧ", "Конец часа", dateTime.endOf("hour"))}
                        </div>
                        <div className="flex flex-row gap-1">
                            {minusMonthBtn}
                            {minusDayBtn}
                            {minusHour}
                        </div>
                    </>
                }
            </div>
            <Calendar value={options.value}
                      onChange={(event: FormEvent<Date, React.SyntheticEvent<Element, Event>>) => onFilterDateRangeChange(options, event)}
                      dateFormat="dd/mm/yy" showTime showSeconds hourFormat="24"
                      placeholder="дд/мм/гггг чч:мм:сс"
                      mask="99/99/9999 99:99:99"
                      hideOnDateTimeSelect
                      showOnFocus={false}
                      showIcon={true}
            />
        </div>
    );
}