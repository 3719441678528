import StatusOutput from "./common/StatusOutput";
import {organizationStatusTitles} from "../interfaces/dictionaries/OrganizationStatusTitles";
import {OrganizationStatus} from "../interfaces/enum/OrganizationStatus";

interface OrganizationStatusOutputProps {
    status: OrganizationStatus;
}

export default function OrganizationStatusOutput(props: OrganizationStatusOutputProps) {
    return (
        <StatusOutput code={props.status}
                      classifier={organizationStatusTitles}/>
    );
};