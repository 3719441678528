import React, {useState} from "react";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {BlockUI} from "primereact/blockui";
import Organization from "../../../interfaces/Organization";
import {organizationService} from "../../../index";
import useUserNotification from "../../../hooks/useUserNotification";
import {errorMessage} from "../../../helpers/axiosError";
import SaveButton from "../../../components/common/buttons/SaveButton";
import CancelButton from "../../../components/common/buttons/CancelButton";
import {EntityDialogState} from "../../../components/common/dialog/EntityDialogState";
import {Mode} from "node:fs";
import OrganizationStatusDropdown from "../../../components/OrganizationStatusDropdown";
import {InputTextarea} from "primereact/inputtextarea";
import TaxonomySelect from "../../../components/common/taxonomy/TaxonomySelect";

export interface OrganizationDialogState extends EntityDialogState<Organization> {
}

export interface OrganizationDialogProps {
    organization: Organization;
    mode: Mode;
    visible: boolean;

    onHide(): void;

    onCancel?(): void;

    onSave?(): void
}

export default function OrganizationPropertiesDialog(props: OrganizationDialogProps) {

    const title = props.mode === "create" ?
        "Регистрация новой организации" :
        "Редактирование карточки организации";

    const [busy, setBusy] = useState<boolean>(false);

    const [organization, setOrganization] =
        useState<Organization>(props.organization);

    const {showError, showSuccess} = useUserNotification();


    const reload = () => {
        setOrganization(props.organization);
    };

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [props.organization]);


    const save = () => {
        if (props.mode === "create") {
            setBusy(true);
            organizationService.create(organization)
                .then(() => {
                    showSuccess({
                        summary: "Регистрация организации",
                        detail: `Организация "${organization.shortTitle}" успешно зарегистрирована в системе.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Регистрация организации",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));

        } else if (props.mode === "edit" && organization.id) {
            setBusy(true);
            organizationService.update(organization.id, organization)
                .then(() => {
                    showSuccess({
                        summary: "Обновление организации",
                        detail: `Регистрационные данные организации "${organization.shortTitle}" успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Регистрация организации",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    };


    const footer = (
        <div>
            <SaveButton onClick={() => save()} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );

    return (
        <BlockUI blocked={busy}>
            <Dialog header={title}
                    visible={props.visible}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-dialog">

                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2">
                        <label htmlFor="shortTitle" className="font-bold">Краткое наименование</label>
                        <InputText id="shortTitle"
                                   value={organization.shortTitle || ""}
                                   onChange={(e) => {
                                       setOrganization(prev => ({
                                           ...prev,
                                           shortTitle: e.target.value
                                       }))
                                   }}/>
                    </div>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="fullTitle" className="font-bold">Полное наименование</label>
                        <InputText id="fullTitle"
                                   value={organization.fullTitle || ""}
                                   onChange={(e) => {
                                       setOrganization(prev => ({
                                           ...prev,
                                           fullTitle: e.target.value
                                       }))
                                   }}/>
                    </div>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="tin" className="font-bold">ИНН</label>
                        <InputText id="tin"
                                   value={organization.tin || ""}
                                   onChange={(e) => {
                                       setOrganization(prev => ({
                                           ...prev,
                                           tin: e.target.value
                                       }))
                                   }}/>
                    </div>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="ceoName" className="font-bold">Руководитель</label>
                        <InputText id="ceoName"
                                   value={organization.ceoName || ""}
                                   onChange={(e) => {
                                       setOrganization(prev => ({
                                           ...prev,
                                           ceoName: e.target.value
                                       }))
                                   }}/>
                    </div>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="description" className="font-bold">Описание</label>
                        <InputTextarea id="description"
                                       value={organization.description || ""}
                                       autoResize
                                       onChange={(e) => {
                                           setOrganization(prev => ({
                                               ...prev,
                                               description: e.target.value
                                           }))
                                       }}/>
                    </div>
                    {/*
                    todo На основании чего действует руководитель, адрес местанахождения, почтовый, признак совпадения этих адресов
                    */}
                    <div className="flex flex-column gap-2">
                        <label htmlFor="website" className="font-bold">Web-site</label>
                        <InputText id="website"
                                   value={organization.website || ""}
                                   onChange={(e) => {
                                       setOrganization(prev => ({
                                           ...prev,
                                           website: e.target.value
                                       }))
                                   }}/>
                    </div>
                    <div className="flex flex-column gap-2">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="taxonomy" className="font-bold">Таксономия</label>
                            <TaxonomySelect value={organization.taxonomy}
                                            onChange={taxonomy =>
                                                setOrganization(prev => ({
                                                    ...prev,
                                                    taxonomy: taxonomy
                                                }))
                                            }/>
                        </div>
                    </div>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="tin" className="font-bold">Статус</label>
                        <OrganizationStatusDropdown id="status"
                                                    value={organization.status}
                                                    onChange={s => setOrganization(prev => ({
                                                        ...prev,
                                                        status: s
                                                    }))}/>
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    );
};