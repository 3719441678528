import {API_ROLES_URL} from "../config";
import {BaseDataService} from "./BaseDataService";
import Role from "../interfaces/Role.interface";


export default class RoleService extends BaseDataService<Role> {

    constructor() {
        super(API_ROLES_URL);
    }

    /*cached(): Role[] {
        return dictRoles;
    }*/

    /*cachedRole(predicate: (value: Role) => boolean): Role | undefined {
        let roles = this.cached();
        //if (!roles) throw new Error("Справочник ролей не загружен.");
        const role: Role | undefined = roles.find(role => predicate(role));
        //if (!role) throw new Error(`Роль не существует.`);
        return role;
    }*/

    /*cachedRoleById(id: number): Role | undefined {
        return this.cachedRole(role => role.id === id);
    }*/

    /**
     * Возвращает роль по имени её константы.
     * @param name имя константы.
     * @throws Error если роль не найдена, или справочник не был загружен при инициализации приложения.
     */
    /*cachedRoleByName(name: string): Role | undefined {
        return this.cachedRole(role => role.name === name);
    }*/

    /*cachedRoleName(id: number): string {
        return this.cachedRoleById(id).name;
    }*/

    /*cachedRoleTitle(id: number): string {
        let roleTitle = ROLE_TITLES.find(roleTitle => roleTitle.id === id);
        if (!roleTitle) throw Error(`Описание роли ${id} не существует.`);
        return roleTitle.title;
    }*/

    /*    async findByName(name: string): Promise<Role> {
            const response = await axios.get<Role>(`${API_ROLES_URL}?filter=name:'${name}'`);
            return response.data;
        }*/
}