import ChooseMediaAssetButton from "../buttons/ChooseMediaAssetButton";
import {InputText} from "primereact/inputtext";
import React, {useState} from "react";
import {MediaAsset} from "../../../interfaces/MediaAsset";
import MediaAssetChooserDialog, {MediaAssetChooserDialogState} from "./MediaAssetChooserDialog";
import MediaAssetType from "../../../interfaces/MediaAssetType";
import {Button} from "primereact/button";
import {mediaAssetService} from "../../../index";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";

interface MediaAssetChooserProps {
    id?: string | undefined;
    assetId: number | undefined | null;
    type?: MediaAssetType | undefined;

    onSelect(asset: MediaAsset): void;

    onUnbind?(): void;
}

export type Props = MediaAssetChooserProps;

export default function MediaAssetChooser(props: MediaAssetChooserProps) {

    const [busy, setBusy] =
        useState<boolean>(false);

    const [mediaAsset, setMediaAsset] =
        useState<MediaAsset | undefined>(undefined);

    const [dialogState, setDialogState] =
        useState<MediaAssetChooserDialogState>({
            visible: false
        });

    const onClick = () => {
        setDialogState({
            visible: true
        });
    };

    const reload = () => {
        if (props.assetId) {
            mediaAssetService.find(props.assetId)
                .then(response => {
                    setMediaAsset(response);
                })
                .catch(reason => {
                    setMediaAsset(undefined);
                })
                .finally(() => setBusy(false));
        } else {
            setMediaAsset(undefined);
        }
    };

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [props.assetId]);


    const onSelect = (asset: MediaAsset) => {
        setDialogState(prev => ({
            ...{prev},
            ...{visible: false}
        }));
        props.onSelect(asset);
    };

    return (
        <div id={props.id}
             className="flex flex-row gap-1 w-full">

            <InputText
                value={mediaAsset?.title || mediaAsset?.name || mediaAsset?.uuid || ""}
                onClick={onClick}
                readOnly={true}
                placeholder="Выберите файл"
                disabled={busy}
            />

            <Button type="button"
                    onClick={() => {
                        if (props.onUnbind) props.onUnbind()
                    }}
                    icon="pi pi-times"
                    tooltip="Отвязать файл. Файл при этом не удаляется, устраняется только связь."
                    tooltipOptions={{position: 'top'}}
                    outlined
                    disabled={!props.assetId || busy}
                    visible={!!props.onUnbind}
                    size="small"
            />

            <ChooseMediaAssetButton id="chooser"
                                    onClick={onClick}/>

            <MediaAssetChooserDialog dialogState={dialogState}
                                     notSelectable={mediaAsset ? [mediaAsset] : undefined}
                                     onSelect={onSelect}
                                     onHide={() => setDialogState(prev => ({
                                         ...prev,
                                         ...{visible: false}
                                     }))}
                                     onCancel={() => setDialogState(prev => ({
                                         ...prev,
                                         ...{visible: false}
                                     }))}
                                     onSave={() => {
                                         setDialogState(prev => ({
                                             ...prev,
                                             ...{visible: false}
                                         }));
                                         reload();
                                     }}
            />
        </div>
    );
}