import {Column} from "primereact/column";
import React from "react";
import ColumnMeta from "../../../interfaces/ColumnMeta";
import {textFilterTemplate} from "../../../components/common/dataTable/filterTemplates";
import Organization from "../../../interfaces/Organization";
import OrganizationStatusOutput from "../../../components/OrganizationStatusOutput";
import {
    columnCreated,
    columnId,
    columnTaxonomy,
    columnUpdated
} from "../../../components/common/dataTable/columns/BaseColumns";

export function initialOrganizationColumns() {
    return organizationColumns
        .filter(column => !column.hidden)
        .map(column => column.field)
}

export const organizationColumns: ColumnMeta[] = [
    {
        field: 'id',
        header: 'УН',
        hidden: true,
        column: columnId
    },
    {
        field: 'created',
        header: 'Создан',
        hidden: true,
        column: columnCreated
    },
    {
        field: 'updated',
        header: 'Обновлен',
        hidden: true,
        column: columnUpdated
    },
    {
        field: 'tin',
        header: 'ИНН',
        hidden: false,
        column: <Column key="tin" field="tin" header="ИНН" sortable
                        dataType="text" body={(rowData: Organization) => <>{rowData.tin}</>}
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: 'shortTitle',
        header: 'Краткое наименование',
        hidden: false,
        column: <Column key="shortTitle" field="shortTitle" header="Краткое наименование" sortable
                        dataType="text" body={(rowData: Organization) => <>{rowData.shortTitle}</>}
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: 'fullTitle',
        header: 'Полное наименование',
        hidden: false,
        column: <Column key="fullTitle" field="fullTitle" header="Полное наименование" sortable
                        dataType="text" body={(rowData: Organization) => <>{rowData.fullTitle}</>}
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: 'taxonomy',
        header: 'Таксономия',
        hidden: false,
        column: columnTaxonomy
    },
    {
        field: "status",
        header: "Статус",
        hidden: false,
        column: <Column key="status" field="status" header="Статус"
                        body={(rowData: Organization) =>
                            <OrganizationStatusOutput status={rowData.status}/>
                        }
        />
    }
];