import StatusOutput from "../common/StatusOutput";
import {TicketMessageStatus} from "../../interfaces/enum/TicketMessageStatus";
import {ticketMessageStatusTitles} from "../../interfaces/dictionaries/TicketMessageStatusTitles";

interface TicketMessageStatusOutputProps {
    status: TicketMessageStatus;
}

export default function TicketMessageStatusOutput(props: TicketMessageStatusOutputProps) {

    //const {isAdmin, isUserManagement} = useAuth();

    /*const filteredStatusText = (status: TicketStatus) => {
        if (!(isAdmin() || isUserManagement())) {

        }
        return ticketStatusText(props.status);
    }*/

    if (props.status === TicketMessageStatus.VISIBLE)
        return null;

    return (
        <StatusOutput code={props.status}
                      classifier={ticketMessageStatusTitles}/>
    );
};