import {API_DASHBOARD_URL} from "../config";
import axios from "axios";

export default class DashboardService {
    url: string = "";

    constructor() {
        this.url = API_DASHBOARD_URL;
    }

    async processId(){
        const request = this.url + "/processId";
        const response = await axios.get(request);
        return response.data;
    }
}

export const dashboardService: DashboardService = new DashboardService();